import './App.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu'
import Select from 'react-select';
import { AiFillGithub,  AiFillLinkedin, AiOutlineInfoCircle, AiOutlineCloseCircle} from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi';
import { CircleFlag } from 'react-circle-flags'
import { countryNames, countryLocations } from './country.js'
import { RiBarChartHorizontalFill } from 'react-icons/ri';
import StateFlag from './StateFlag.js'
import { Form, Button } from 'react-bootstrap';

function Watchlist(props) {
  var locations = {...props.data} ?? []
  const [location, setLocation] = useState({});

  const getWatchLocations = () => {
    var watchLocations = []
  
    if (localStorage.getItem("watchLocations") !== null) {
      var localWL = JSON.parse(localStorage.getItem("watchLocations"))
  
      if (Array.isArray(localWL)) {
          watchLocations = localWL
      } else {
          localStorage.setItem("watchLocations", "[]")
      }
    } else {
        localStorage.setItem("watchLocations", "[]")
    }
    return watchLocations
  }

  const getSortedLocations = (watchLocations) => {
    return watchLocations.map((name) => {
        return locations[name.toLowerCase()]
    }).filter((e) => e !== null)
  }

  var watchLocations = getWatchLocations()

  var sortedLocations = getSortedLocations(watchLocations)

  //console.log(watchLocations, locations, sortedLocations)

  const [wLocations, setWLocations] = useState(sortedLocations);

  const flag = (location) => {
    if ( countryNames[location?.location ?? ""] !== undefined) {
        return <div className="stateFlag" style={{height: "2em", width: "2.5em", float: "left", paddingRight: "10px"}}>{<img className="imageCircle" src={"/flags/" + countryNames[location?.location ?? ""]?.toLowerCase() + ".svg"} />}</div>
      } else if (location?.location == "Northern Ireland"){
        return <span className="statsFlag"><img className="imageCropped" src="/n_ireland_cropped.png" /></span>
      } else {
          return <div style={{height: "2em", width: "2.5em", float: "left", paddingRight: "10px"}}></div>
      }
  }

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
    {
      return 'iOS';

    }
    else if( userAgent.match( /Android/i ) )
    {

      return 'Android';
    }
    else
    {
      return 'unknown'; 
    }
  }

  const addToList = () => {
    var watchLocations = getWatchLocations()

    if (location?.location && !watchLocations.find((name) => name == location?.location)) {
        watchLocations.push(location?.location)
    }
    localStorage.setItem("watchLocations", JSON.stringify(watchLocations))
    setWLocations(getSortedLocations(watchLocations))
  }

  const removeFromList = (locationName) => {
    var watchLocations = getWatchLocations().filter((name) => name != locationName)
    localStorage.setItem("watchLocations", JSON.stringify(watchLocations))
    setWLocations(getSortedLocations(watchLocations))
  }

  const changeCountry = (option) => {
    if (locations[option.value]) {
      setLocation(locations[option.value])
    } else {
      setLocation(locations["world"])
    }
  }

  var options = [{value: "", label: "World"}];
  Object.keys(locations).sort().map(function(name){
    var locationName = locations[name].location
    if (locationName === "World") return false;
    options.push({value: name, label: locationName})
    return true;
  })

  var selectedOption = options.find((option) => {
    return option.label === location?.location
  })

  return (
    <div className="statsContainer">
      <div className="closeStats" onClick={()=>{props.closeWatchlist()}}><AiOutlineCloseCircle /></div>
      <h3 className="statsHeader">
        Watchlist
      </h3>
      <Select
            value={selectedOption}
            onChange={changeCountry}
            options={options}
            isSearchable={getMobileOperatingSystem() == "unknown"}
        />
    
    <Button style={{marginTop: "20px"}} onClick={()=>{addToList()}}>Add to Watchlist</Button>

        {
        wLocations?.map((location, i) => {
          return location&&<div key={i} style={{ marginTop: 20, position: "relative"}}>
              {flag(location)}<span className="textLeft">{i + 1}. <a href={"/" + location?.location?.toLowerCase()}>{location.location}</a></span><span className="textRight">{location.percentage} <Button variant="danger" onClick={()=>{removeFromList(location.location)}}>-</Button></span><div style={{clear: "both"}}></div>
          
          </div>
        })
      }
    </div>
  );
}

export default Watchlist;
