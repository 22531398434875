import './App.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu'
import Select from 'react-select';
import { AiFillGithub,  AiFillLinkedin, AiOutlineInfoCircle, AiOutlineCloseCircle} from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi';
import { CircleFlag } from 'react-circle-flags'
import { countryNames, countryLocations } from './country.js'
import { RiBarChartHorizontalFill } from 'react-icons/ri';
import StateFlag from './StateFlag.js'

function Stats(props) {
  var locations = {...props.data}
  var title = props.title ?? "Country Ranking"
  var showStateFlag = props.showFlag ?? true
  var prependUrl = props.prependUrl ?? ""

  delete locations.world
  delete locations["united kingdom"]

  var sortedLocations = Object.values(locations)
  sortedLocations = sortedLocations?.filter(loc => !!loc.people_fully_vaccinated_per_hundred);
  sortedLocations = sortedLocations?.sort((a, b) => (parseFloat(a.people_fully_vaccinated_per_hundred) < parseFloat(b.people_fully_vaccinated_per_hundred) ?  1 : -1))

  const flag = (location) => {
    if (showStateFlag) {
      if ( countryNames[location?.location ?? ""] !== undefined) {
        return <div className="stateFlag" style={{height: "2em", float: "left", paddingRight: "10px"}}>{<img className="imageCircle" src={"/flags/" + countryNames[location?.location ?? ""]?.toLowerCase() + ".svg"} />}</div>
      } else if (location?.location == "Northern Ireland"){
        return <span className="statsFlag"><img className="imageCropped" src="/n_ireland_cropped.png" /></span>
      } else {
        return <div style={{height: "2em", width: "2.5em", float: "left", paddingRight: "10px"}}></div>
     }
    } else {
      //return <StateFlag height="2em" isCanada={location.country == "Canada"} name={location.location}/>
    }
  }

  return (
    <div className="statsContainer">
      <div className="closeStats" onClick={()=>{props.closeStats()}}><AiOutlineCloseCircle /></div>
      <h3 className="statsHeader">
        {title}
      </h3>

      {
        sortedLocations?.map((location, i) => {
          return <div key={i} style={{ marginTop: 20, position: "relative"}}>{flag(location)}<span className="textLeft">{i + 1}. <a href={prependUrl + "/" + location?.location.toLowerCase()}>{location.location}</a></span><span className="textRight">{location.percentage}</span><div style={{clear: "both"}}></div></div>
        })
      }
    </div>
  );
}

export default Stats;
