import './App.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu'
import Select from 'react-select';
import { AiFillGithub,  AiFillLinkedin, AiOutlineInfoCircle, AiOutlineShareAlt} from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi';
import { CircleFlag } from 'react-circle-flags'
import { countryNames, countryLocations } from './country.js'
import { BsListOl } from 'react-icons/bs';
import { Form, Button } from 'react-bootstrap';
import Switch from "react-switch";
import Stats from './Stats.js'
import Watchlist from './Watchlist.js'
import StateFlag from './StateFlag.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import useInterval from 'react-useinterval';

function App() {

  var statType = {
    Country: "Country",
    Province: "Province",
    State: "State"
  }

  var initFullyVacc = localStorage.getItem("fullyVaccinated")
  if (initFullyVacc) initFullyVacc = JSON.parse(initFullyVacc)
  if (typeof initFullyVacc != "boolean") initFullyVacc = true

  var initRealTime = localStorage.getItem("realTime")
  if (initRealTime) initRealTime = JSON.parse(initRealTime)
  if (typeof initRealTime != "boolean") initRealTime = true

  const [location, setLocation] = useState({});
  const [locations, setLocations] = useState({})
  const [isShowingStats, setIsShowingStats] = useState(false);
  const [statsContainer, setStatsContainer] = useState({})
  const [states, setStates] = useState({})
  const [selectedState, setSelectedState] = useState({})
  const [realTimeChecked, setRealTimeChecked] = useState(initRealTime)
  const [fullyVaccinated, setFullyVaccinated] = useState(initFullyVacc)
  const [realTimeNumber, setRealTimeNumber] = useState(null)
  const [isShowingWatchlist, setIsShowingWatchlist] = useState(false);
  const [watchlistContainer, setWatchlistContainer] = useState({})

  var url = "/vaccine"
  var usUrl = "/us_vaccine"
  var canadaUrl = "/canada_vaccine"

  var selectedCountry = location?.location
  var isUS = selectedCountry == "United States" || location?.country == "United States"
  var isCanada = selectedCountry == "Canada" || location?.country == "Canada"

  var countryCode = countryNames[location?.location ?? ""]?.toLowerCase()

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    url = "http://localhost:3001/vaccine"
    usUrl = "http://localhost:3001/us_vaccine"
    canadaUrl = "http://localhost:3001/canada_vaccine"
  }

  const sendCountryEvent = (country) => {
    window.gtag('event', 'click', {'event_category' : 'country', 'event_action' : 'select', 'event_label' : country});
  }

  const getData = () => {
    var retrievedData = false;
    axios.get(url).then(res => {
      if (res.status === 200 && res.data && !retrievedData) {
        retrievedData = true
        var loc = {}
        for (var i = 0; i < res.data.length; i++) {
          var country = res.data[i] ?? {}
          if (country.iso_code == "OWID_UMC" || country.iso_code == "OWID_LMC" || country.iso_code == "OWID_LIC" || country.iso_code == "OWID_HIC") continue;
          country.percentage = parseFloat(Math.min(country.people_fully_vaccinated_per_hundred, 100)).toFixed(2) + "%"
          loc[country.location.toLowerCase()] = country
        }
        

        var pathNameArray = window.location.pathname.split('/')

        var pathName = decodeURI(pathNameArray[1]).toLowerCase()
        var secondPathName = decodeURI(pathNameArray[2]).toLowerCase()

        setLocations(loc)

        if (pathName == "watchlist") {
          openWatchlist();
          setLocation(loc["world"])
        } else if (pathName == "stats") {
          if (secondPathName == "country") {
            openStats(statType.Country, true)
            setLocation(loc["world"])
          } else if (secondPathName == "province") {
            openStats(statType.Province, true)
            setLocation(loc["canada"])
          } else if (secondPathName == "state") {
            openStats(statType.State, true)
            setLocation(loc["united states"])
          }
        } else if (loc[pathName]) {
          setLocation(loc[pathName])
          sendCountryEvent(pathName)
          window.document.title = "How Many Vaccinated - " + loc[pathName].location
        } else {
          setLocation(loc["world"])
          sendCountryEvent("world")
          window.document.title = "How Many Vaccinated - World"
        }
      }
    })
  }

  useEffect(() => {
    getData()
    setInterval(function() {
      getData()
    }, 3600000)

    window.onpopstate = (event) => {
        window.location.reload();
    }
  }, [])

  const getRealTime = (isFullyVaccinated) => {
    if (realTimeChecked && location?.daily_vaccinations) {
      var perSecond = location?.daily_vaccinations/(24*60*60)
      var dateDifference = (new Date() - Date.parse(location?.date))/1000
      var population = location?.people_vaccinated / (parseFloat(location?.people_vaccinated_per_hundred))

      var vacc = !!location?.people_fully_vaccinated ? location?.people_fully_vaccinated : location?.people_vaccinated
      var realTimeNumber = parseFloat(isFullyVaccinated ?? fullyVaccinated ? vacc : location?.people_vaccinated) + Math.floor(perSecond * dateDifference * 0.3)
      setRealTimeNumber({people_fully_vaccinated: realTimeNumber, percentage: parseFloat(Math.min(realTimeNumber / population, 100)).toFixed(2) + "%"})
    } else {
      setRealTimeNumber(null)
    }
  }


  useInterval(()=> {
      getRealTime()
    }, 3000)

  useEffect(() => {
    if (location?.location == "United States" || location?.location == "Canada") {
      var isUS = location?.location == "United States"
      var stateUrl = canadaUrl
      if (isUS) {
        stateUrl = usUrl
      }

      axios.get(stateUrl).then(res => {
        if (res.data) {
          var pre_states = res.data
          var statesObject = {}
          for (var i = 0; i < pre_states.length; i++) {
            pre_states[i].date = locations[location?.location.toLowerCase()].date
            pre_states[i].country = location?.location
            statesObject[pre_states[i].location.toLowerCase()] = pre_states[i]
          }
          setStates(statesObject)
        }
      })
    }
    getRealTime()
  }, [location])

  useEffect(()=> {
    var pathNameArray = window.location.pathname.split('/')
    var pathName = decodeURI(pathNameArray[1]).toLowerCase()
    var secondPathName = decodeURI(pathNameArray[2])?.toLowerCase()
    if (pathName != "stats" && secondPathName && states[secondPathName]) {
      changeState({value: secondPathName, label: states[secondPathName].location})
    }
  }, [states])

  useEffect(() => {
    getRealTime()
  }, [realTimeChecked])

  const changeCountry = (option, pushState = true) => {
    setSelectedState({})
    if (pushState) {
      console.log("Push state", option.value)
      window.history.pushState(null, '', "/" + option.value);
    } 
    if (locations[option.value]) {
      setLocation(locations[option.value])
      sendCountryEvent(option.value)
      window.document.title = "How Many Vaccinated - " + locations[option.value].location
    } else {
      setLocation(locations["world"])
      sendCountryEvent("world")
      window.document.title = "How Many Vaccinated - World"
    }
  }

  const changeState = (option, pushState = true) => {
    if (states[option.value]) {
      setLocation(states[option.value])
      setFullyVaccinated(true)
      setSelectedState(states[option.value])
      window.document.title = "How Many Vaccinated - " + option.label
      if (pushState) window.history.pushState(null, '', "/" + states[option.value].country.toLowerCase() + "/" + option.value); 
    } else {
      var country = "United States"
      if (isCanada) {
        country = "Canada"
      }
      setSelectedState({})
      setLocation(locations[country.toLowerCase()])
      window.document.title = "How Many Vaccinated - " + country
      if (pushState) window.history.pushState(null, '', "/" + country.toLowerCase()); 
    }
  }

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
    {
      return 'iOS';

    }
    else if( userAgent.match( /Android/i ) )
    {

      return 'Android';
    }
    else
    {
      return 'unknown'; 
    }
  }


  var options = [{value: "", label: "World"}];
  Object.keys(locations).sort().map(function(name){
    var locationName = locations[name].location
    if (locationName === "World") return false;
    options.push({value: name, label: locationName})
    return true;
  })


  var selectedOption = options.find((option) => {
    return option.label === selectedCountry
  })


  var selectedStateOption;
  var stateOptions = [{value: "", label: location?.country ?? location?.location}]
  Object.keys(states).sort().map(function(name){
    var locationName = states[name].location
    stateOptions.push({value: name, label: locationName})
    return true;
  })

  var pathNameArray = window.location.pathname.split('/')
  var stateName = selectedState?.location?.toLowerCase() ?? decodeURI(pathNameArray[2])?.toLowerCase()
  selectedStateOption = stateOptions.find((option) => {
    return option.value === stateName
  })

  if (selectedStateOption === undefined) {
    selectedStateOption = ''
  }

  if (isUS) {
    selectedCountry = "United States"
    countryCode = "us"
    selectedOption = options.find((option) => {
      return option.label === "United States"
    })
  } else if (isCanada) {
    selectedCountry = "Canada"
    countryCode = "ca"
    selectedOption = options.find((option) => {
      return option.label === "Canada"
    })
  }


  const closeStats = () => {
    if (selectedStateOption) {
      changeState(selectedStateOption)
    } else if (selectedOption) {
      changeCountry(selectedOption)
    } 
    setStatsContainer({})
    setIsShowingStats(false)
  }

  const closeWatchlist = () => {
    console.log("Close", location)
    if (selectedStateOption) {
      changeState(selectedStateOption)
    } else if (selectedOption) {
      changeCountry(selectedOption)
    } 

    setWatchlistContainer({})
    setIsShowingWatchlist(false)
  }

  const handleChange = (checked) => {
    setRealTimeChecked(checked)
    localStorage.setItem("realTime", JSON.stringify(checked))
  }

  const handleFullyVaccinatedChange = (checked) => {
    setFullyVaccinated(checked)
    getRealTime(checked)
    localStorage.setItem("fullyVaccinated", JSON.stringify(checked))
  }

  const openStats = (type = statType.Country, pushState = true) => {
    var localStatsContainer;
    if (type == statType.Country) {
      if(pushState)window.history.pushState(null, '', "/stats/country")
      localStatsContainer = {
        type: type
      }
    } else if (type == statType.Province) {
      if(pushState)window.history.pushState(null, '', "/stats/province")
      localStatsContainer = {
        type: type,
        showFlag: false,
        title: "Province Ranking",
        prependUrl: "/canada"
      }
    } else if (type == statType.State) {
      if(pushState)window.history.pushState(null, '', "/stats/state")
      localStatsContainer = {
        type: type,
        showFlag: false,
        title: "State/Territory Ranking",
        prependUrl: "/united states"
      }
    }

    setStatsContainer(localStatsContainer)
    setIsShowingStats(true)
  }

  const openWatchlist = () => {
    window.history.pushState(null, '', "/watchlist")
    setIsShowingWatchlist(true)
  }

  var tempLocation = Object.assign({}, location)

  if (!fullyVaccinated || !tempLocation.people_fully_vaccinated) {
    tempLocation.people_fully_vaccinated = tempLocation.people_vaccinated
    tempLocation.people_fully_vaccinated_per_hundred = tempLocation.people_vaccinated_per_hundred
    tempLocation.percentage = parseFloat(Math.min(tempLocation.people_fully_vaccinated_per_hundred, 100)).toFixed(2) + "%"
  }

  var realLocation = realTimeNumber ?? tempLocation
  var isShowingHeaders = !(isShowingStats || isShowingWatchlist)  

  return (
    <div className="App" id="App">

      {isShowingHeaders&&<Menu  pageWrapId={ "container" }  outerContainerId={"App"} disableAutoFocus customBurgerIcon={ <FiHelpCircle /> }>
        <b>
        I created this site to provide a minimalistic way to track how many people have been vaccinated for COVID-19.<br/><br/> The blue bar at the bottom will fill the screen when the entire population has been vaccinated. The data will automatically refresh every hour. 
        </b>
        { 
          location?.location == "United States" && <span>Make sure to continue to wear a <a href="https://amzn.to/2LeWQJT" target="_blank">mask</a> even after you get your vaccine</span>
        }

        <div className="infoFooter">
          *Real-time is an estimated number based on daily vaccination rates <br/>
          *Data comes from <a href="https://ourworldindata.org/" target="_blank">Our World in Data</a> and various news sources. <br/><a href="https://ourworldindata.org/covid-vaccinations" target="_blank">Click here for a more detailed view</a> <br/><br/>
          <b>Update May 5, 2021: Data now shows number of fully vaccinated people instead of number of vaccinated people</b>
        </div>


        <div className="sidebarFooter">
          <AiFillGithub onClick={()=>{window.open("https://github.com/slin1202")}}/> <AiFillLinkedin onClick={()=>{window.open("https://www.linkedin.com/in/sean-lin-56b136a1/")}}/>
          <div className="infoFooter">Made with <span style={{color: "#e25555"}}>&hearts;</span> in Ontario, Canada</div>
          <a href="https://www.buymeacoffee.com/slin1202" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" alt="Buy Me A Coffee" className="coffeeImage" /></a>
        </div>


        <div className="hidden">
            <div tabindex="0"><a href="/austria"> austria </a><a href="/bahrain"> bahrain </a><a href="/bulgaria"> bulgaria </a><a href="/canada"> canada </a><a href="/chile"> chile </a><a href="/china"> china </a><a href="/costa rica"> costa rica </a><a href="/croatia"> croatia </a><a href="/denmark"> denmark </a><a href="/england"> england </a><a href="/estonia"> estonia </a><a href="/germany"> germany </a><a href="/greece"> greece </a><a href="/hungary"> hungary </a><a href="/israel"> israel </a><a href="/italy"> italy </a><a href="/kuwait"> kuwait </a><a href="/latvia"> latvia </a><a href="/lithuania"> lithuania </a><a href="/luxembourg"> luxembourg </a><a href="/mexico"> mexico </a><a href="/northern ireland"> northern ireland </a><a href="/oman"> oman </a><a href="/poland"> poland </a><a href="/portugal"> portugal </a><a href="/romania"> romania </a><a href="/russia"> russia </a><a href="/scotland"> scotland </a><a href="/united kingdom"> united kingdom </a><a href="/united states"> united states </a><a href="/wales"> wales </a><a href="/world"> world </a></div>
        </div>
        <div className="footer"></div>
      </Menu>}

      <div className="statsTopbar"> 

      {isShowingHeaders&&<div className="shareButton" onClick={()=>{openWatchlist()}}> 
          <Button variant="link">Watchlist</Button>
        </div>}
        {isShowingHeaders&&<div className="shareButton" onClick={()=>{openStats()}}> 
          <Button variant="link">Country Ranks</Button>
        </div>}

        {isShowingHeaders&&isCanada&&<div className="shareButton stateButton" onClick={()=>{openStats(statType.Province)}}> 
          <Button variant="link">Province Ranks</Button>
        </div>}

        {isShowingHeaders&&isUS&&<div className="shareButton stateButton" onClick={()=>{openStats(statType.State)}}> 
          <Button variant="link">State Ranks</Button>
        </div>}

      </div>

      {isShowingHeaders&&<div className="background" id="container">
        <div className="progressBar" style={{"height": realLocation?.percentage}}></div>
          {countryCode&&selectedCountry != "Northern Ireland"&&!selectedStateOption&&<div className="flag">
          <div className="stateFlag" style={{height: "5.3em"}}>{<img className="imageCircle" src={"/flags/" + countryCode + ".svg"} />}</div>
        </div>}
        {
          location?.location == "World" && <img className="globe" src="/globe.png" />
        }
        {
          selectedCountry == "Northern Ireland" && <img className="globe" src="/n_ireland_cropped.png" />
        }
        {
          selectedStateOption&&<StateFlag isCanada={isCanada} name={selectedStateOption.label} />
        }
         <h2 className="Header-text">{location?.location}: {parseFloat(realLocation?.people_fully_vaccinated ?? 0)?.toLocaleString('en')}<br/>{realLocation?.percentage ?? "0%"}</h2> 
         
         <div className="countrySelect">
         {<label className="realTimeLabel">
        <span className={!(!selectedStateOption&&location?.people_fully_vaccinated) ? "disabled" : "enabled"}>{!(!selectedStateOption&&location?.people_fully_vaccinated) ? "Fully vaccinated unavailable" : !fullyVaccinated ? "Fully vaccinated disabled" : "Fully vaccinated enabled"}</span>
        <Switch  
            checked={fullyVaccinated}
            onChange={handleFullyVaccinatedChange}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            disabled={!(!selectedStateOption&&location?.people_fully_vaccinated)}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"/>
      </label>}
            {<label className="realTimeLabel">
        <span className={!(!selectedStateOption&&location?.daily_vaccinations) ? "disabled" : "enabled"}>{!(!selectedStateOption&&location?.daily_vaccinations) ? "Real-time unavailable" : !realTimeChecked ? "Real-time disabled" : "Real-time enabled"}</span>
        <Switch  
            checked={realTimeChecked}
            onChange={handleChange}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            disabled={!(!selectedStateOption&&location?.daily_vaccinations)}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"/>
      </label>}

            {(isUS || isCanada)&&<Select
              value={selectedStateOption}
              onChange={changeState}
              options={stateOptions}
              menuPlacement="top"
              placeholder={ isUS ? "Select State" : "Select Province" }
              isSearchable={getMobileOperatingSystem() == "unknown"}
            />}
            {(isUS || isCanada)&&<br/>}
            <Select
              value={selectedOption}
              onChange={changeCountry}
              options={options}
              menuPlacement="top"
              isSearchable={getMobileOperatingSystem() == "unknown"}
            />
            <p className="lastUpdated">Last updated: {(new Date(Date.parse(location?.date))).toDateString()}</p>
         </div>
      </div>}
      {
        isShowingWatchlist&&<div className="background statsBackground" id="container"><Watchlist {...watchlistContainer} closeWatchlist={() => { closeWatchlist()}}  data={ locations }/> </div>
      }
      {
        isShowingStats&&<div className="background statsBackground" id="container"><Stats {...statsContainer} closeStats={() => { closeStats()}}  data={statsContainer.type != statType.Country ? states: locations}/> </div>
      }
    </div>
  );
}

export default App;
