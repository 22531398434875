import { provinces, states } from './provinces.js'

function StateFlag(props) {
  var isCanada = props.isCanada
  var name = props.name

  var countryCode = isCanada ? "ca" : "us"
  var code;
  if (isCanada) {
    code = provinces[name]?.toLowerCase() ?? ""
  } else {
    code = states[name]?.toLowerCase() ?? ""
  }

  var url = "/svg/" + countryCode +  "/" + code + ".svg"

  if (name == "Northern Mariana Islands") {
    url = "/svg/" + code + ".svg"
  }
  return (
    <div className="stateFlag" style={{height: props.height ?? "5.3em"}}>{code&&<img className="imageCircle" src={url} />}</div>
  );
}

export default StateFlag;
