export const countryNames = {'Afghanistan': 'AF','Albania': 'AL','Algeria': 'DZ','American Samoa': 'AS','Andorra': 'AD','Angola': 'AO','Anguilla': 'AI','Antarctica': 'AQ',
'Antigua and Barbuda': 'AG','Argentina': 'AR','Armenia': 'AM','Aruba': 'AW','Australia': 'AU','Austria': 'AT','Azerbaijan': 'AZ','Bahamas': 'BS',
'Bahrain': 'BH','Bangladesh': 'BD','Barbados': 'BB','Belarus': 'BY','Belgium': 'BE','Belize': 'BZ','Benin': 'BJ','Bermuda': 'BM','Bhutan': 'BT',
'Bolivia, Plurinational State of': 'BO','Bonaire, Sint Eustatius and Saba': 'BQ','Bosnia and Herzegovina': 'BA','Botswana': 'BW','Bouvet Island': 'BV',
'Brazil': 'BR','British Indian Ocean Territory': 'IO','Brunei Darussalam': 'BN','Bulgaria': 'BG','Burkina Faso': 'BF','Burundi': 'BI','Cambodia': 'KH',
'Cameroon': 'CM','Canada': 'CA','Cape Verde': 'CV','Cayman Islands': 'KY','Central African Republic': 'CF','Chad': 'TD','Chile': 'CL','China': 'CN',
'Christmas Island': 'CX','Cocos (Keeling) Islands': 'CC','Colombia': 'CO','Comoros': 'KM','Congo': 'CG','Congo, the Democratic Republic of the': 'CD',
'Cook Islands': 'CK','Costa Rica': 'CR','Croatia': 'HR','Cuba': 'CU','Curaçao': 'CW','Cyprus': 'CY','Czechia': 'CZ',"Côte d'Ivoire": 'CI',
'Denmark': 'DK','Djibouti': 'DJ','Dominica': 'DM','Dominican Republic': 'DO','Ecuador': 'EC','Egypt': 'EG','El Salvador': 'SV','Equatorial Guinea': 'GQ',
'Eritrea': 'ER','Estonia': 'EE','Ethiopia': 'ET','Falkland Islands (Malvinas)': 'FK','Faroe Islands': 'FO','Fiji': 'FJ','Finland': 'FI','France': 'FR',
'French Guiana': 'GF','French Polynesia': 'PF','French Southern Territories': 'TF','Gabon': 'GA','Gambia': 'GM','Georgia': 'GE','Germany': 'DE',
'Ghana': 'GH','Gibraltar': 'GI','Greece': 'GR','Greenland': 'GL','Grenada': 'GD','Guadeloupe': 'GP','Guam': 'GU','Guatemala': 'GT','Guernsey': 'GG',
'Guinea': 'GN','Guinea-Bissau': 'GW','Guyana': 'GY','Haiti': 'HT','Heard Island and McDonald Islands': 'HM','Holy See (Vatican City State)': 'VA',
'Honduras': 'HN','Hong Kong': 'HK','Hungary': 'HU','Iceland': 'IS','India': 'IN','Indonesia': 'ID','Iran, Islamic Republic of': 'IR','Iraq': 'IQ',
'Ireland': 'IE','Isle of Man': 'IM','Israel': 'IL','Italy': 'IT','Jamaica': 'JM','Japan': 'JP','Jersey': 'JE','Jordan': 'JO','Kazakhstan': 'KZ',
'Kenya': 'KE','Kiribati': 'KI',"Korea, Democratic People's Republic of": 'KP','Korea, Republic of': 'KR','Kuwait': 'KW','Kyrgyzstan': 'KG',
"Lao People's Democratic Republic": 'LA','Latvia': 'LV','Lebanon': 'LB','Lesotho': 'LS','Liberia': 'LR','Libya': 'LY','Liechtenstein': 'LI',
'Lithuania': 'LT','Luxembourg': 'LU','Macao': 'MO','Macedonia, the former Yugoslav Republic of': 'MK','Madagascar': 'MG','Malawi': 'MW',
'Malaysia': 'MY','Maldives': 'MV','Mali': 'ML','Malta': 'MT','Marshall Islands': 'MH','Martinique': 'MQ','Mauritania': 'MR','Mauritius': 'MU',
'Mayotte': 'YT','Mexico': 'MX','Micronesia, Federated States of': 'FM','Moldova, Republic of': 'MD','Monaco': 'MC','Mongolia': 'MN','Montenegro': 'ME',
'Montserrat': 'MS','Morocco': 'MA','Mozambique': 'MZ','Myanmar': 'MM','Namibia': 'NA','Nauru': 'NR','Nepal': 'NP','Netherlands': 'NL','New Caledonia': 'NC',
'New Zealand': 'NZ','Nicaragua': 'NI','Niger': 'NE','Nigeria': 'NG','Niue': 'NU','Norfolk Island': 'NF','Northern Mariana Islands': 'MP','Norway': 'NO',
'Oman': 'OM','Pakistan': 'PK','Palau': 'PW','Palestine, State of': 'PS','Panama': 'PA','Papua New Guinea': 'PG','Paraguay': 'PY','Peru': 'PE',
'Philippines': 'PH','Pitcairn': 'PN','Poland': 'PL','Portugal': 'PT','Puerto Rico': 'PR','Qatar': 'QA','Romania': 'RO','Russia': 'RU',
'Rwanda': 'RW','Réunion': 'RE','Saint Barthélemy': 'BL','Saint Helena, Ascension and Tristan da Cunha': 'SH','Saint Kitts and Nevis': 'KN','Saint Lucia': 'LC',
'Saint Martin (French part)': 'MF','Saint Pierre and Miquelon': 'PM','Saint Vincent and the Grenadines': 'VC','Samoa': 'WS','San Marino': 'SM',
'Sao Tome and Principe': 'ST','Saudi Arabia': 'SA','Senegal': 'SN','Serbia': 'RS','Seychelles': 'SC','Sierra Leone': 'SL','Singapore': 'SG',
'Sint Maarten (Dutch part)': 'SX','Slovakia': 'SK','Slovenia': 'SI','Solomon Islands': 'SB','Somalia': 'SO','South Africa': 'ZA',
'South Georgia and the South Sandwich Islands': 'GS','South Sudan': 'SS','Spain': 'ES','Sri Lanka': 'LK','Sudan': 'SD','Suriname': 'SR',
'Svalbard and Jan Mayen': 'SJ','Swaziland': 'SZ','Sweden': 'SE','Switzerland': 'CH','Syrian Arab Republic': 'SY','Taiwan': 'TW',
'Tajikistan': 'TJ','Tanzania, United Republic of': 'TZ','Thailand': 'TH','Timor-Leste': 'TL','Togo': 'TG','Tokelau': 'TK','Tonga': 'TO','Trinidad and Tobago': 'TT',
'Tunisia': 'TN','Turkey': 'TR','Turkmenistan': 'TM','Turks and Caicos Islands': 'TC','Tuvalu': 'TV','Uganda': 'UG','Ukraine': 'UA','United Arab Emirates': 'AE',
'United Kingdom': 'GB','United States': 'US','United States Minor Outlying Islands': 'UM','Uruguay': 'UY','Uzbekistan': 'UZ','Vanuatu': 'VU',
'Venezuela, Bolivarian Republic of': 'VE','Vietnam': 'VN','Virgin Islands, British': 'VG','Virgin Islands, U.S.': 'VI','Wallis and Futuna': 'WF',
'Western Sahara': 'EH','Yemen': 'YE','Zambia': 'ZM','Zimbabwe': 'ZW','Åland Islands': 'AX', "Wales": "gb-wls", "England": "gb-eng", "Scotland": "gb-sct"}

export const countryLocations = [
  {
    "iso_code": "AFG",
    "continent": "Asia",
    "location": "Afghanistan",
    "total_cases": 51526,
    "new_cases": 121,
    "new_cases_smoothed": 124.429,
    "total_deaths": 2191,
    "new_deaths": 10,
    "new_deaths_smoothed": 9.286,
    "total_cases_per_million": 1323.612,
    "new_cases_per_million": 3.108,
    "new_cases_smoothed_per_million": 3.196,
    "total_deaths_per_million": 56.283,
    "new_deaths_per_million": 0.257,
    "new_deaths_smoothed_per_million": 0.239,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 8.33,
    "population": 38928341,
    "population_density": 54.422,
    "median_age": 18.6,
    "aged_65_older": 2.581,
    "aged_70_older": 1.337,
    "gdp_per_capita": 1803.987,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 597.029,
    "diabetes_prevalence": 9.59,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 37.746,
    "hospital_beds_per_thousand": 0.5,
    "life_expectancy": 64.83,
    "human_development_index": 0.498
  },
  {
    "iso_code": "ALB",
    "continent": "Europe",
    "location": "Albania",
    "total_cases": 58316,
    "new_cases": 589,
    "new_cases_smoothed": 498.429,
    "total_deaths": 1181,
    "new_deaths": 7,
    "new_deaths_smoothed": 8,
    "total_cases_per_million": 20264.091,
    "new_cases_per_million": 204.67,
    "new_cases_smoothed_per_million": 173.198,
    "total_deaths_per_million": 410.383,
    "new_deaths_per_million": 2.432,
    "new_deaths_smoothed_per_million": 2.78,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 65.74,
    "population": 2877800,
    "population_density": 104.871,
    "median_age": 38,
    "aged_65_older": 13.188,
    "aged_70_older": 8.643,
    "gdp_per_capita": 11803.431,
    "extreme_poverty": 1.1,
    "cardiovasc_death_rate": 304.195,
    "diabetes_prevalence": 10.08,
    "female_smokers": 7.1,
    "male_smokers": 51.2,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.89,
    "life_expectancy": 78.57,
    "human_development_index": 0.785
  },
  {
    "iso_code": "DZA",
    "continent": "Africa",
    "location": "Algeria",
    "total_cases": 99610,
    "new_cases": 299,
    "new_cases_smoothed": 371.857,
    "total_deaths": 2756,
    "new_deaths": 5,
    "new_deaths_smoothed": 7.286,
    "total_cases_per_million": 2271.554,
    "new_cases_per_million": 6.819,
    "new_cases_smoothed_per_million": 8.48,
    "total_deaths_per_million": 62.849,
    "new_deaths_per_million": 0.114,
    "new_deaths_smoothed_per_million": 0.166,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 72.22,
    "population": 43851043,
    "population_density": 17.348,
    "median_age": 29.1,
    "aged_65_older": 6.211,
    "aged_70_older": 3.857,
    "gdp_per_capita": 13913.839,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 278.364,
    "diabetes_prevalence": 6.73,
    "female_smokers": 0.7,
    "male_smokers": 30.4,
    "handwashing_facilities": 83.741,
    "hospital_beds_per_thousand": 1.9,
    "life_expectancy": 76.88,
    "human_development_index": 0.754
  },
  {
    "iso_code": "AND",
    "continent": "Europe",
    "location": "Andorra",
    "total_cases": 8049,
    "new_cases": 66,
    "new_cases_smoothed": 50,
    "total_deaths": 84,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 104173.947,
    "new_cases_per_million": 854.203,
    "new_cases_smoothed_per_million": 647.124,
    "total_deaths_per_million": 1087.168,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 1.849,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 59.26,
    "population": 77265,
    "population_density": 163.755,
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": "",
    "extreme_poverty": "",
    "cardiovasc_death_rate": 109.135,
    "diabetes_prevalence": 7.97,
    "female_smokers": 29,
    "male_smokers": 37.8,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": "",
    "life_expectancy": 83.73,
    "human_development_index": 0.858
  },
  {
    "iso_code": "AGO",
    "continent": "Africa",
    "location": "Angola",
    "total_cases": 17553,
    "new_cases": 120,
    "new_cases_smoothed": 74.857,
    "total_deaths": 405,
    "new_deaths": 0,
    "new_deaths_smoothed": 1.714,
    "total_cases_per_million": 534.073,
    "new_cases_per_million": 3.651,
    "new_cases_smoothed_per_million": 2.278,
    "total_deaths_per_million": 12.323,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.052,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 65.74,
    "population": 32866268,
    "population_density": 23.89,
    "median_age": 16.8,
    "aged_65_older": 2.405,
    "aged_70_older": 1.362,
    "gdp_per_capita": 5819.495,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 276.045,
    "diabetes_prevalence": 3.94,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 26.664,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 61.15,
    "human_development_index": 0.581
  },
  {
    "iso_code": "ATG",
    "continent": "North America",
    "location": "Antigua and Barbuda",
    "total_cases": 159,
    "new_cases": 1,
    "new_cases_smoothed": 0.714,
    "total_deaths": 5,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 1623.642,
    "new_cases_per_million": 10.212,
    "new_cases_smoothed_per_million": 7.294,
    "total_deaths_per_million": 51.058,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 97928,
    "population_density": 231.845,
    "median_age": 32.1,
    "aged_65_older": 6.933,
    "aged_70_older": 4.631,
    "gdp_per_capita": 21490.943,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 191.511,
    "diabetes_prevalence": 13.17,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.8,
    "life_expectancy": 77.02,
    "human_development_index": 0.78
  },
  {
    "iso_code": "ARG",
    "continent": "South America",
    "location": "Argentina",
    "total_cases": 1625514,
    "new_cases": 11586,
    "new_cases_smoothed": 8807,
    "total_deaths": 43245,
    "new_deaths": 82,
    "new_deaths_smoothed": 133,
    "total_cases_per_million": 35966.059,
    "new_cases_per_million": 256.351,
    "new_cases_smoothed_per_million": 194.863,
    "total_deaths_per_million": 956.837,
    "new_deaths_per_million": 1.814,
    "new_deaths_smoothed_per_million": 2.943,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 15388,
    "total_tests": 3925664,
    "total_tests_per_thousand": 86.859,
    "new_tests_per_thousand": 0.34,
    "new_tests_smoothed": 18375,
    "new_tests_smoothed_per_thousand": 0.407,
    "positive_rate": 0.32,
    "tests_per_case": 3.1,
    "tests_units": "people tested",
    "total_vaccinations": 700,
    "total_vaccinations_per_hundred": 0,
    "stringency_index": 79.17,
    "population": 45195777,
    "population_density": 16.177,
    "median_age": 31.9,
    "aged_65_older": 11.198,
    "aged_70_older": 7.441,
    "gdp_per_capita": 18933.907,
    "extreme_poverty": 0.6,
    "cardiovasc_death_rate": 191.032,
    "diabetes_prevalence": 5.5,
    "female_smokers": 16.2,
    "male_smokers": 27.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 5,
    "life_expectancy": 76.67,
    "human_development_index": 0.825
  },
  {
    "iso_code": "ARM",
    "continent": "Asia",
    "location": "Armenia",
    "total_cases": 159409,
    "new_cases": 531,
    "new_cases_smoothed": 466.714,
    "total_deaths": 2823,
    "new_deaths": 16,
    "new_deaths_smoothed": 15.571,
    "total_cases_per_million": 53795.617,
    "new_cases_per_million": 179.196,
    "new_cases_smoothed_per_million": 157.502,
    "total_deaths_per_million": 952.675,
    "new_deaths_per_million": 5.4,
    "new_deaths_smoothed_per_million": 5.255,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 2963234,
    "population_density": 102.931,
    "median_age": 35.7,
    "aged_65_older": 11.232,
    "aged_70_older": 7.571,
    "gdp_per_capita": 8787.58,
    "extreme_poverty": 1.8,
    "cardiovasc_death_rate": 341.01,
    "diabetes_prevalence": 7.11,
    "female_smokers": 1.5,
    "male_smokers": 52.1,
    "handwashing_facilities": 94.043,
    "hospital_beds_per_thousand": 4.2,
    "life_expectancy": 75.09,
    "human_development_index": 0.755
  },
  {
    "iso_code": "AUS",
    "continent": "Oceania",
    "location": "Australia",
    "total_cases": 28425,
    "new_cases": 20,
    "new_cases_smoothed": 21.571,
    "total_deaths": 909,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 1114.711,
    "new_cases_per_million": 0.784,
    "new_cases_smoothed_per_million": 0.846,
    "total_deaths_per_million": 35.647,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.006,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 56285,
    "total_tests": 11260636,
    "total_tests_per_thousand": 441.596,
    "new_tests_per_thousand": 2.207,
    "new_tests_smoothed": 53634,
    "new_tests_smoothed_per_thousand": 2.103,
    "positive_rate": 0,
    "tests_per_case": 2486.4,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 64.35,
    "population": 25499881,
    "population_density": 3.202,
    "median_age": 37.9,
    "aged_65_older": 15.504,
    "aged_70_older": 10.129,
    "gdp_per_capita": 44648.71,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 107.791,
    "diabetes_prevalence": 5.07,
    "female_smokers": 13,
    "male_smokers": 16.5,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.84,
    "life_expectancy": 83.44,
    "human_development_index": 0.939
  },
  {
    "iso_code": "AUT",
    "continent": "Europe",
    "location": "Austria",
    "total_cases": 360815,
    "new_cases": 2913,
    "new_cases_smoothed": 1944.429,
    "total_deaths": 6222,
    "new_deaths": 73,
    "new_deaths_smoothed": 68.143,
    "total_cases_per_million": 40062.067,
    "new_cases_per_million": 323.437,
    "new_cases_smoothed_per_million": 215.894,
    "total_deaths_per_million": 690.842,
    "new_deaths_per_million": 8.105,
    "new_deaths_smoothed_per_million": 7.566,
    "reproduction_rate": "",
    "icu_patients": 414,
    "icu_patients_per_million": 45.967,
    "hosp_patients": 2043,
    "hosp_patients_per_million": 226.839,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 16085,
    "total_tests": 3810131,
    "total_tests_per_thousand": 423.047,
    "new_tests_per_thousand": 1.786,
    "new_tests_smoothed": 18285,
    "new_tests_smoothed_per_thousand": 2.03,
    "positive_rate": 0.106,
    "tests_per_case": 9.4,
    "tests_units": "tests performed",
    "total_vaccinations": 6000,
    "total_vaccinations_per_hundred": 0.07,
    "stringency_index": 71.3,
    "population": 9006400,
    "population_density": 106.749,
    "median_age": 44.4,
    "aged_65_older": 19.202,
    "aged_70_older": 13.748,
    "gdp_per_capita": 45436.686,
    "extreme_poverty": 0.7,
    "cardiovasc_death_rate": 145.183,
    "diabetes_prevalence": 6.35,
    "female_smokers": 28.4,
    "male_smokers": 30.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 7.37,
    "life_expectancy": 81.54,
    "human_development_index": 0.908
  },
  {
    "iso_code": "AZE",
    "continent": "Asia",
    "location": "Azerbaijan",
    "total_cases": 218700,
    "new_cases": 1064,
    "new_cases_smoothed": 1234.143,
    "total_deaths": 2641,
    "new_deaths": 32,
    "new_deaths_smoothed": 37.286,
    "total_cases_per_million": 21569.802,
    "new_cases_per_million": 104.94,
    "new_cases_smoothed_per_million": 121.72,
    "total_deaths_per_million": 260.475,
    "new_deaths_per_million": 3.156,
    "new_deaths_smoothed_per_million": 3.677,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 84.26,
    "population": 10139175,
    "population_density": 119.309,
    "median_age": 32.4,
    "aged_65_older": 6.018,
    "aged_70_older": 3.871,
    "gdp_per_capita": 15847.419,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 559.812,
    "diabetes_prevalence": 7.11,
    "female_smokers": 0.3,
    "male_smokers": 42.5,
    "handwashing_facilities": 83.241,
    "hospital_beds_per_thousand": 4.7,
    "life_expectancy": 73,
    "human_development_index": 0.757
  },
  {
    "iso_code": "BHS",
    "continent": "North America",
    "location": "Bahamas",
    "total_cases": 7871,
    "new_cases": 14,
    "new_cases_smoothed": 11.857,
    "total_deaths": 170,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 20015.359,
    "new_cases_per_million": 35.601,
    "new_cases_smoothed_per_million": 30.152,
    "total_deaths_per_million": 432.297,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.363,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 393248,
    "population_density": 39.497,
    "median_age": 34.3,
    "aged_65_older": 8.996,
    "aged_70_older": 5.2,
    "gdp_per_capita": 27717.847,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 235.954,
    "diabetes_prevalence": 13.17,
    "female_smokers": 3.1,
    "male_smokers": 20.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.9,
    "life_expectancy": 73.92,
    "human_development_index": 0.807
  },
  {
    "iso_code": "BHR",
    "continent": "Asia",
    "location": "Bahrain",
    "total_cases": 92675,
    "new_cases": 250,
    "new_cases_smoothed": 229.286,
    "total_deaths": 352,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 54463.99,
    "new_cases_per_million": 146.922,
    "new_cases_smoothed_per_million": 134.748,
    "total_deaths_per_million": 206.866,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.168,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 9988,
    "total_tests": 2355313,
    "total_tests_per_thousand": 1384.189,
    "new_tests_per_thousand": 5.87,
    "new_tests_smoothed": 9129,
    "new_tests_smoothed_per_thousand": 5.365,
    "positive_rate": 0.025,
    "tests_per_case": 39.8,
    "tests_units": "units unclear",
    "total_vaccinations": 59351,
    "total_vaccinations_per_hundred": 3.49,
    "stringency_index": "",
    "population": 1701583,
    "population_density": 1935.907,
    "median_age": 32.4,
    "aged_65_older": 2.372,
    "aged_70_older": 1.387,
    "gdp_per_capita": 43290.705,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 151.689,
    "diabetes_prevalence": 16.52,
    "female_smokers": 5.8,
    "male_smokers": 37.6,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2,
    "life_expectancy": 77.29,
    "human_development_index": 0.846
  },
  {
    "iso_code": "BGD",
    "continent": "Asia",
    "location": "Bangladesh",
    "total_cases": 513510,
    "new_cases": 1014,
    "new_cases_smoothed": 1058.286,
    "total_deaths": 7559,
    "new_deaths": 28,
    "new_deaths_smoothed": 25.857,
    "total_cases_per_million": 3118.052,
    "new_cases_per_million": 6.157,
    "new_cases_smoothed_per_million": 6.426,
    "total_deaths_per_million": 45.899,
    "new_deaths_per_million": 0.17,
    "new_deaths_smoothed_per_million": 0.157,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 13254,
    "total_tests": 3224409,
    "total_tests_per_thousand": 19.579,
    "new_tests_per_thousand": 0.08,
    "new_tests_smoothed": 13135,
    "new_tests_smoothed_per_thousand": 0.08,
    "positive_rate": 0.081,
    "tests_per_case": 12.4,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 164689383,
    "population_density": 1265.036,
    "median_age": 27.5,
    "aged_65_older": 5.098,
    "aged_70_older": 3.262,
    "gdp_per_capita": 3523.984,
    "extreme_poverty": 14.8,
    "cardiovasc_death_rate": 298.003,
    "diabetes_prevalence": 8.38,
    "female_smokers": 1,
    "male_smokers": 44.7,
    "handwashing_facilities": 34.808,
    "hospital_beds_per_thousand": 0.8,
    "life_expectancy": 72.59,
    "human_development_index": 0.608
  },
  {
    "iso_code": "BRB",
    "continent": "North America",
    "location": "Barbados",
    "total_cases": 383,
    "new_cases": 11,
    "new_cases_smoothed": 5.429,
    "total_deaths": 7,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 1332.772,
    "new_cases_per_million": 38.278,
    "new_cases_smoothed_per_million": 18.89,
    "total_deaths_per_million": 24.359,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 40.74,
    "population": 287371,
    "population_density": 664.463,
    "median_age": 39.8,
    "aged_65_older": 14.952,
    "aged_70_older": 9.473,
    "gdp_per_capita": 16978.068,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 170.05,
    "diabetes_prevalence": 13.57,
    "female_smokers": 1.9,
    "male_smokers": 14.5,
    "handwashing_facilities": 88.469,
    "hospital_beds_per_thousand": 5.8,
    "life_expectancy": 79.19,
    "human_development_index": 0.8
  },
  {
    "iso_code": "BLR",
    "continent": "Europe",
    "location": "Belarus",
    "total_cases": 194284,
    "new_cases": 1923,
    "new_cases_smoothed": 1881.571,
    "total_deaths": 1424,
    "new_deaths": 10,
    "new_deaths_smoothed": 9.429,
    "total_cases_per_million": 20560.631,
    "new_cases_per_million": 203.507,
    "new_cases_smoothed_per_million": 199.122,
    "total_deaths_per_million": 150.699,
    "new_deaths_per_million": 1.058,
    "new_deaths_smoothed_per_million": 0.998,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 27.78,
    "population": 9449321,
    "population_density": 46.858,
    "median_age": 40.3,
    "aged_65_older": 14.799,
    "aged_70_older": 9.788,
    "gdp_per_capita": 17167.967,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 443.129,
    "diabetes_prevalence": 5.18,
    "female_smokers": 10.5,
    "male_smokers": 46.1,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 11,
    "life_expectancy": 74.79,
    "human_development_index": 0.808
  },
  {
    "iso_code": "BEL",
    "continent": "Europe",
    "location": "Belgium",
    "total_cases": 646496,
    "new_cases": 2254,
    "new_cases_smoothed": 1656,
    "total_deaths": 19528,
    "new_deaths": 87,
    "new_deaths_smoothed": 70,
    "total_cases_per_million": 55782.349,
    "new_cases_per_million": 194.484,
    "new_cases_smoothed_per_million": 142.887,
    "total_deaths_per_million": 1684.957,
    "new_deaths_per_million": 7.507,
    "new_deaths_smoothed_per_million": 6.04,
    "reproduction_rate": "",
    "icu_patients": 492,
    "icu_patients_per_million": 42.452,
    "hosp_patients": 2361,
    "hosp_patients_per_million": 203.717,
    "weekly_icu_admissions": 3647.2,
    "weekly_icu_admissions_per_million": 314.695,
    "weekly_hosp_admissions": 1135.134,
    "weekly_hosp_admissions_per_million": 97.944,
    "new_tests": 32073,
    "total_tests": 6932076,
    "total_tests_per_thousand": 598.128,
    "new_tests_per_thousand": 2.767,
    "new_tests_smoothed": 26213,
    "new_tests_smoothed_per_thousand": 2.262,
    "positive_rate": 0.073,
    "tests_per_case": 13.7,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 11589616,
    "population_density": 375.564,
    "median_age": 41.8,
    "aged_65_older": 18.571,
    "aged_70_older": 12.849,
    "gdp_per_capita": 42658.576,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 114.898,
    "diabetes_prevalence": 4.29,
    "female_smokers": 25.1,
    "male_smokers": 31.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 5.64,
    "life_expectancy": 81.63,
    "human_development_index": 0.916
  },
  {
    "iso_code": "BLZ",
    "continent": "North America",
    "location": "Belize",
    "total_cases": 10776,
    "new_cases": 52,
    "new_cases_smoothed": 40.857,
    "total_deaths": 248,
    "new_deaths": 6,
    "new_deaths_smoothed": 2.857,
    "total_cases_per_million": 27101.184,
    "new_cases_per_million": 130.778,
    "new_cases_smoothed_per_million": 102.754,
    "total_deaths_per_million": 623.71,
    "new_deaths_per_million": 15.09,
    "new_deaths_smoothed_per_million": 7.186,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 72.22,
    "population": 397621,
    "population_density": 16.426,
    "median_age": 25,
    "aged_65_older": 3.853,
    "aged_70_older": 2.279,
    "gdp_per_capita": 7824.362,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 176.957,
    "diabetes_prevalence": 17.11,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 90.083,
    "hospital_beds_per_thousand": 1.3,
    "life_expectancy": 74.62,
    "human_development_index": 0.708
  },
  {
    "iso_code": "BEN",
    "continent": "Africa",
    "location": "Benin",
    "total_cases": 3251,
    "new_cases": 0,
    "new_cases_smoothed": 6.571,
    "total_deaths": 44,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 268.164,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0.542,
    "total_deaths_per_million": 3.629,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 35.19,
    "population": 12123198,
    "population_density": 99.11,
    "median_age": 18.8,
    "aged_65_older": 3.244,
    "aged_70_older": 1.942,
    "gdp_per_capita": 2064.236,
    "extreme_poverty": 49.6,
    "cardiovasc_death_rate": 235.848,
    "diabetes_prevalence": 0.99,
    "female_smokers": 0.6,
    "male_smokers": 12.3,
    "handwashing_facilities": 11.035,
    "hospital_beds_per_thousand": 0.5,
    "life_expectancy": 61.77,
    "human_development_index": 0.515
  },
  {
    "iso_code": "BTN",
    "continent": "Asia",
    "location": "Bhutan",
    "total_cases": 670,
    "new_cases": 15,
    "new_cases_smoothed": 21.571,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 868.312,
    "new_cases_per_million": 19.44,
    "new_cases_smoothed_per_million": 27.956,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 81.94,
    "population": 771612,
    "population_density": 21.188,
    "median_age": 28.6,
    "aged_65_older": 4.885,
    "aged_70_older": 2.977,
    "gdp_per_capita": 8708.597,
    "extreme_poverty": 1.5,
    "cardiovasc_death_rate": 217.066,
    "diabetes_prevalence": 9.75,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 79.807,
    "hospital_beds_per_thousand": 1.7,
    "life_expectancy": 71.78,
    "human_development_index": 0.612
  },
  {
    "iso_code": "BOL",
    "continent": "South America",
    "location": "Bolivia",
    "total_cases": 160124,
    "new_cases": 1752,
    "new_cases_smoothed": 1000.429,
    "total_deaths": 9165,
    "new_deaths": 16,
    "new_deaths_smoothed": 12.714,
    "total_cases_per_million": 13717.434,
    "new_cases_per_million": 150.09,
    "new_cases_smoothed_per_million": 85.704,
    "total_deaths_per_million": 785.143,
    "new_deaths_per_million": 1.371,
    "new_deaths_smoothed_per_million": 1.089,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 3561,
    "total_tests": 410477,
    "total_tests_per_thousand": 35.165,
    "new_tests_per_thousand": 0.305,
    "new_tests_smoothed": 2301,
    "new_tests_smoothed_per_thousand": 0.197,
    "positive_rate": 0.392,
    "tests_per_case": 2.6,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 47.22,
    "population": 11673029,
    "population_density": 10.202,
    "median_age": 25.4,
    "aged_65_older": 6.704,
    "aged_70_older": 4.393,
    "gdp_per_capita": 6885.829,
    "extreme_poverty": 7.1,
    "cardiovasc_death_rate": 204.299,
    "diabetes_prevalence": 6.89,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 25.383,
    "hospital_beds_per_thousand": 1.1,
    "life_expectancy": 71.51,
    "human_development_index": 0.693
  },
  {
    "iso_code": "BIH",
    "continent": "Europe",
    "location": "Bosnia and Herzegovina",
    "total_cases": 110985,
    "new_cases": 0,
    "new_cases_smoothed": 383.857,
    "total_deaths": 4050,
    "new_deaths": 0,
    "new_deaths_smoothed": 24.571,
    "total_cases_per_million": 33828.485,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 117.001,
    "total_deaths_per_million": 1234.449,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 7.489,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 3280815,
    "population_density": 68.496,
    "median_age": 42.5,
    "aged_65_older": 16.569,
    "aged_70_older": 10.711,
    "gdp_per_capita": 11713.895,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 329.635,
    "diabetes_prevalence": 10.08,
    "female_smokers": 30.2,
    "male_smokers": 47.7,
    "handwashing_facilities": 97.164,
    "hospital_beds_per_thousand": 3.5,
    "life_expectancy": 77.4,
    "human_development_index": 0.768
  },
  {
    "iso_code": "BWA",
    "continent": "Africa",
    "location": "Botswana",
    "total_cases": 14805,
    "new_cases": 105,
    "new_cases_smoothed": 111.429,
    "total_deaths": 42,
    "new_deaths": 2,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 6295.647,
    "new_cases_per_million": 44.65,
    "new_cases_smoothed_per_million": 47.384,
    "total_deaths_per_million": 17.86,
    "new_deaths_per_million": 0.85,
    "new_deaths_smoothed_per_million": 0.121,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 47.22,
    "population": 2351625,
    "population_density": 4.044,
    "median_age": 25.8,
    "aged_65_older": 3.941,
    "aged_70_older": 2.242,
    "gdp_per_capita": 15807.374,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 237.372,
    "diabetes_prevalence": 4.81,
    "female_smokers": 5.7,
    "male_smokers": 34.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 1.8,
    "life_expectancy": 69.59,
    "human_development_index": 0.717
  },
  {
    "iso_code": "BRA",
    "continent": "South America",
    "location": "Brazil",
    "total_cases": 7675973,
    "new_cases": 56773,
    "new_cases_smoothed": 36004,
    "total_deaths": 194949,
    "new_deaths": 1074,
    "new_deaths_smoothed": 709.571,
    "total_cases_per_million": 36112.13,
    "new_cases_per_million": 267.092,
    "new_cases_smoothed_per_million": 169.383,
    "total_deaths_per_million": 917.151,
    "new_deaths_per_million": 5.053,
    "new_deaths_smoothed_per_million": 3.338,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 52.31,
    "population": 212559409,
    "population_density": 25.04,
    "median_age": 33.5,
    "aged_65_older": 8.552,
    "aged_70_older": 5.06,
    "gdp_per_capita": 14103.452,
    "extreme_poverty": 3.4,
    "cardiovasc_death_rate": 177.961,
    "diabetes_prevalence": 8.11,
    "female_smokers": 10.1,
    "male_smokers": 17.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.2,
    "life_expectancy": 75.88,
    "human_development_index": 0.759
  },
  {
    "iso_code": "BRN",
    "continent": "Asia",
    "location": "Brunei",
    "total_cases": 157,
    "new_cases": 0,
    "new_cases_smoothed": 0.714,
    "total_deaths": 3,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 358.871,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 1.633,
    "total_deaths_per_million": 6.857,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 35.19,
    "population": 437483,
    "population_density": 81.347,
    "median_age": 32.4,
    "aged_65_older": 4.591,
    "aged_70_older": 2.382,
    "gdp_per_capita": 71809.251,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 201.285,
    "diabetes_prevalence": 12.79,
    "female_smokers": 2,
    "male_smokers": 30.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.7,
    "life_expectancy": 75.86,
    "human_development_index": 0.853
  },
  {
    "iso_code": "BGR",
    "continent": "Europe",
    "location": "Bulgaria",
    "total_cases": 202266,
    "new_cases": 1046,
    "new_cases_smoothed": 801.143,
    "total_deaths": 7576,
    "new_deaths": 61,
    "new_deaths_smoothed": 79,
    "total_cases_per_million": 29109.535,
    "new_cases_per_million": 150.537,
    "new_cases_smoothed_per_million": 115.298,
    "total_deaths_per_million": 1090.316,
    "new_deaths_per_million": 8.779,
    "new_deaths_smoothed_per_million": 11.369,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 8473,
    "total_tests": 1149545,
    "total_tests_per_thousand": 165.439,
    "new_tests_per_thousand": 1.219,
    "new_tests_smoothed": 4582,
    "new_tests_smoothed_per_thousand": 0.659,
    "positive_rate": 0.175,
    "tests_per_case": 5.7,
    "tests_units": "tests performed",
    "total_vaccinations": 4739,
    "total_vaccinations_per_hundred": 0.07,
    "stringency_index": 54.63,
    "population": 6948445,
    "population_density": 65.18,
    "median_age": 44.7,
    "aged_65_older": 20.801,
    "aged_70_older": 13.272,
    "gdp_per_capita": 18563.307,
    "extreme_poverty": 1.5,
    "cardiovasc_death_rate": 424.688,
    "diabetes_prevalence": 5.81,
    "female_smokers": 30.1,
    "male_smokers": 44.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 7.454,
    "life_expectancy": 75.05,
    "human_development_index": 0.813
  },
  {
    "iso_code": "BFA",
    "continent": "Africa",
    "location": "Burkina Faso",
    "total_cases": 6707,
    "new_cases": 76,
    "new_cases_smoothed": 109.429,
    "total_deaths": 85,
    "new_deaths": 1,
    "new_deaths_smoothed": 1.143,
    "total_cases_per_million": 320.859,
    "new_cases_per_million": 3.636,
    "new_cases_smoothed_per_million": 5.235,
    "total_deaths_per_million": 4.066,
    "new_deaths_per_million": 0.048,
    "new_deaths_smoothed_per_million": 0.055,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 13.89,
    "population": 20903278,
    "population_density": 70.151,
    "median_age": 17.6,
    "aged_65_older": 2.409,
    "aged_70_older": 1.358,
    "gdp_per_capita": 1703.102,
    "extreme_poverty": 43.7,
    "cardiovasc_death_rate": 269.048,
    "diabetes_prevalence": 2.42,
    "female_smokers": 1.6,
    "male_smokers": 23.9,
    "handwashing_facilities": 11.877,
    "hospital_beds_per_thousand": 0.4,
    "life_expectancy": 61.58,
    "human_development_index": 0.423
  },
  {
    "iso_code": "BDI",
    "continent": "Africa",
    "location": "Burundi",
    "total_cases": 818,
    "new_cases": 9,
    "new_cases_smoothed": 6.429,
    "total_deaths": 2,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 68.793,
    "new_cases_per_million": 0.757,
    "new_cases_smoothed_per_million": 0.541,
    "total_deaths_per_million": 0.168,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 14.81,
    "population": 11890781,
    "population_density": 423.062,
    "median_age": 17.5,
    "aged_65_older": 2.562,
    "aged_70_older": 1.504,
    "gdp_per_capita": 702.225,
    "extreme_poverty": 71.7,
    "cardiovasc_death_rate": 293.068,
    "diabetes_prevalence": 6.05,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 6.144,
    "hospital_beds_per_thousand": 0.8,
    "life_expectancy": 61.58,
    "human_development_index": 0.417
  },
  {
    "iso_code": "KHM",
    "continent": "Asia",
    "location": "Cambodia",
    "total_cases": 378,
    "new_cases": 12,
    "new_cases_smoothed": 2.143,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 22.609,
    "new_cases_per_million": 0.718,
    "new_cases_smoothed_per_million": 0.128,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 16718971,
    "population_density": 90.672,
    "median_age": 25.6,
    "aged_65_older": 4.412,
    "aged_70_older": 2.385,
    "gdp_per_capita": 3645.07,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 270.892,
    "diabetes_prevalence": 4,
    "female_smokers": 2,
    "male_smokers": 33.7,
    "handwashing_facilities": 66.229,
    "hospital_beds_per_thousand": 0.8,
    "life_expectancy": 69.82,
    "human_development_index": 0.582
  },
  {
    "iso_code": "CMR",
    "continent": "Africa",
    "location": "Cameroon",
    "total_cases": 26277,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": 448,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 989.872,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": 16.876,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 26545864,
    "population_density": 50.885,
    "median_age": 18.8,
    "aged_65_older": 3.165,
    "aged_70_older": 1.919,
    "gdp_per_capita": 3364.926,
    "extreme_poverty": 23.8,
    "cardiovasc_death_rate": 244.661,
    "diabetes_prevalence": 7.2,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 2.735,
    "hospital_beds_per_thousand": 1.3,
    "life_expectancy": 59.29,
    "human_development_index": 0.556
  },
  {
    "iso_code": "CAN",
    "continent": "North America",
    "location": "Canada",
    "total_cases": 584409,
    "new_cases": 7143,
    "new_cases_smoothed": 6459.857,
    "total_deaths": 15632,
    "new_deaths": 134,
    "new_deaths_smoothed": 122.429,
    "total_cases_per_million": 15484.25,
    "new_cases_per_million": 189.258,
    "new_cases_smoothed_per_million": 171.158,
    "total_deaths_per_million": 414.179,
    "new_deaths_per_million": 3.55,
    "new_deaths_smoothed_per_million": 3.244,
    "reproduction_rate": "",
    "icu_patients": 774,
    "icu_patients_per_million": 20.508,
    "hosp_patients": 4180,
    "hosp_patients_per_million": 110.751,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 65062,
    "total_tests": 13775115,
    "total_tests_per_thousand": 364.98,
    "new_tests_per_thousand": 1.724,
    "new_tests_smoothed": 61681,
    "new_tests_smoothed_per_thousand": 1.634,
    "positive_rate": 0.104,
    "tests_per_case": 9.6,
    "tests_units": "people tested",
    "total_vaccinations": 99946,
    "total_vaccinations_per_hundred": 0.26,
    "stringency_index": 64.35,
    "population": 37742157,
    "population_density": 4.037,
    "median_age": 41.4,
    "aged_65_older": 16.984,
    "aged_70_older": 10.797,
    "gdp_per_capita": 44017.591,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 105.599,
    "diabetes_prevalence": 7.37,
    "female_smokers": 12,
    "male_smokers": 16.6,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.5,
    "life_expectancy": 82.43,
    "human_development_index": 0.926
  },
  {
    "iso_code": "CPV",
    "continent": "Africa",
    "location": "Cape Verde",
    "total_cases": 11840,
    "new_cases": 47,
    "new_cases_smoothed": 21.714,
    "total_deaths": 113,
    "new_deaths": 1,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 21295.424,
    "new_cases_per_million": 84.534,
    "new_cases_smoothed_per_million": 39.055,
    "total_deaths_per_million": 203.242,
    "new_deaths_per_million": 1.799,
    "new_deaths_smoothed_per_million": 0.257,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 479,
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": 0.862,
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 555988,
    "population_density": 135.58,
    "median_age": 25.7,
    "aged_65_older": 4.46,
    "aged_70_older": 3.437,
    "gdp_per_capita": 6222.554,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 182.219,
    "diabetes_prevalence": 2.42,
    "female_smokers": 2.1,
    "male_smokers": 16.5,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.1,
    "life_expectancy": 72.98,
    "human_development_index": 0.654
  },
  {
    "iso_code": "CAF",
    "continent": "Africa",
    "location": "Central African Republic",
    "total_cases": 4963,
    "new_cases": 0,
    "new_cases_smoothed": 2.143,
    "total_deaths": 63,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 1027.586,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0.444,
    "total_deaths_per_million": 13.044,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 20.37,
    "population": 4829764,
    "population_density": 7.479,
    "median_age": 18.3,
    "aged_65_older": 3.655,
    "aged_70_older": 2.251,
    "gdp_per_capita": 661.24,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 435.727,
    "diabetes_prevalence": 6.1,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 16.603,
    "hospital_beds_per_thousand": 1,
    "life_expectancy": 53.28,
    "human_development_index": 0.367
  },
  {
    "iso_code": "TCD",
    "continent": "Africa",
    "location": "Chad",
    "total_cases": 2113,
    "new_cases": 36,
    "new_cases_smoothed": 22.143,
    "total_deaths": 104,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 128.639,
    "new_cases_per_million": 2.192,
    "new_cases_smoothed_per_million": 1.348,
    "total_deaths_per_million": 6.331,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.017,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 70.37,
    "population": 16425859,
    "population_density": 11.833,
    "median_age": 16.7,
    "aged_65_older": 2.486,
    "aged_70_older": 1.446,
    "gdp_per_capita": 1768.153,
    "extreme_poverty": 38.4,
    "cardiovasc_death_rate": 280.995,
    "diabetes_prevalence": 6.1,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 5.818,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 54.24,
    "human_development_index": 0.404
  },
  {
    "iso_code": "CHL",
    "continent": "South America",
    "location": "Chile",
    "total_cases": 608973,
    "new_cases": 2023,
    "new_cases_smoothed": 2117.286,
    "total_deaths": 16608,
    "new_deaths": 109,
    "new_deaths_smoothed": 43.571,
    "total_cases_per_million": 31856.369,
    "new_cases_per_million": 105.826,
    "new_cases_smoothed_per_million": 110.759,
    "total_deaths_per_million": 868.792,
    "new_deaths_per_million": 5.702,
    "new_deaths_smoothed_per_million": 2.279,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 42570,
    "total_tests": 6443459,
    "total_tests_per_thousand": 337.068,
    "new_tests_per_thousand": 2.227,
    "new_tests_smoothed": 34018,
    "new_tests_smoothed_per_thousand": 1.78,
    "positive_rate": 0.062,
    "tests_per_case": 16.1,
    "tests_units": "tests performed",
    "total_vaccinations": 8648,
    "total_vaccinations_per_hundred": 0.05,
    "stringency_index": 73.61,
    "population": 19116209,
    "population_density": 24.282,
    "median_age": 35.4,
    "aged_65_older": 11.087,
    "aged_70_older": 6.938,
    "gdp_per_capita": 22767.037,
    "extreme_poverty": 1.3,
    "cardiovasc_death_rate": 127.993,
    "diabetes_prevalence": 8.46,
    "female_smokers": 34.2,
    "male_smokers": 41.5,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.11,
    "life_expectancy": 80.18,
    "human_development_index": 0.843
  },
  {
    "iso_code": "CHN",
    "continent": "Asia",
    "location": "China",
    "total_cases": 95963,
    "new_cases": 87,
    "new_cases_smoothed": 82.857,
    "total_deaths": 4782,
    "new_deaths": 1,
    "new_deaths_smoothed": 1.857,
    "total_cases_per_million": 66.672,
    "new_cases_per_million": 0.06,
    "new_cases_smoothed_per_million": 0.058,
    "total_deaths_per_million": 3.322,
    "new_deaths_per_million": 0.001,
    "new_deaths_smoothed_per_million": 0.001,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": 4500000,
    "total_vaccinations_per_hundred": 0.31,
    "stringency_index": 78.24,
    "population": 1439323774,
    "population_density": 147.674,
    "median_age": 38.7,
    "aged_65_older": 10.641,
    "aged_70_older": 5.929,
    "gdp_per_capita": 15308.712,
    "extreme_poverty": 0.7,
    "cardiovasc_death_rate": 261.899,
    "diabetes_prevalence": 9.74,
    "female_smokers": 1.9,
    "male_smokers": 48.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 4.34,
    "life_expectancy": 76.91,
    "human_development_index": 0.752
  },
  {
    "iso_code": "COL",
    "continent": "South America",
    "location": "Colombia",
    "total_cases": 1642775,
    "new_cases": 16314,
    "new_cases_smoothed": 11858.429,
    "total_deaths": 43213,
    "new_deaths": 304,
    "new_deaths_smoothed": 251.286,
    "total_cases_per_million": 32285.414,
    "new_cases_per_million": 320.619,
    "new_cases_smoothed_per_million": 233.053,
    "total_deaths_per_million": 849.264,
    "new_deaths_per_million": 5.975,
    "new_deaths_smoothed_per_million": 4.939,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 33864,
    "total_tests": 6067361,
    "total_tests_per_thousand": 119.242,
    "new_tests_per_thousand": 0.666,
    "new_tests_smoothed": 30473,
    "new_tests_smoothed_per_thousand": 0.599,
    "positive_rate": 0.276,
    "tests_per_case": 3.6,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 60.19,
    "population": 50882884,
    "population_density": 44.223,
    "median_age": 32.2,
    "aged_65_older": 7.646,
    "aged_70_older": 4.312,
    "gdp_per_capita": 13254.949,
    "extreme_poverty": 4.5,
    "cardiovasc_death_rate": 124.24,
    "diabetes_prevalence": 7.44,
    "female_smokers": 4.7,
    "male_smokers": 13.5,
    "handwashing_facilities": 65.386,
    "hospital_beds_per_thousand": 1.71,
    "life_expectancy": 77.29,
    "human_development_index": 0.747
  },
  {
    "iso_code": "COM",
    "continent": "Africa",
    "location": "Comoros",
    "total_cases": 823,
    "new_cases": 58,
    "new_cases_smoothed": 15.429,
    "total_deaths": 10,
    "new_deaths": 1,
    "new_deaths_smoothed": 0.429,
    "total_cases_per_million": 946.418,
    "new_cases_per_million": 66.698,
    "new_cases_smoothed_per_million": 17.742,
    "total_deaths_per_million": 11.5,
    "new_deaths_per_million": 1.15,
    "new_deaths_smoothed_per_million": 0.493,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 869595,
    "population_density": 437.352,
    "median_age": 20.4,
    "aged_65_older": 2.963,
    "aged_70_older": 1.726,
    "gdp_per_capita": 1413.89,
    "extreme_poverty": 18.1,
    "cardiovasc_death_rate": 261.516,
    "diabetes_prevalence": 11.88,
    "female_smokers": 4.4,
    "male_smokers": 23.6,
    "handwashing_facilities": 15.574,
    "hospital_beds_per_thousand": 2.2,
    "life_expectancy": 64.32,
    "human_development_index": 0.503
  },
  {
    "iso_code": "COG",
    "continent": "Africa",
    "location": "Congo",
    "total_cases": 7107,
    "new_cases": 199,
    "new_cases_smoothed": 76.571,
    "total_deaths": 108,
    "new_deaths": 1,
    "new_deaths_smoothed": 1.143,
    "total_cases_per_million": 1287.945,
    "new_cases_per_million": 36.063,
    "new_cases_smoothed_per_million": 13.876,
    "total_deaths_per_million": 19.572,
    "new_deaths_per_million": 0.181,
    "new_deaths_smoothed_per_million": 0.207,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 43.52,
    "population": 5518092,
    "population_density": 15.405,
    "median_age": 19,
    "aged_65_older": 3.402,
    "aged_70_older": 2.063,
    "gdp_per_capita": 4881.406,
    "extreme_poverty": 37,
    "cardiovasc_death_rate": 344.094,
    "diabetes_prevalence": 7.2,
    "female_smokers": 1.7,
    "male_smokers": 52.3,
    "handwashing_facilities": 47.964,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 64.57,
    "human_development_index": 0.606
  },
  {
    "iso_code": "CRI",
    "continent": "North America",
    "location": "Costa Rica",
    "total_cases": 169321,
    "new_cases": 1207,
    "new_cases_smoothed": 904.429,
    "total_deaths": 2185,
    "new_deaths": 14,
    "new_deaths_smoothed": 14.143,
    "total_cases_per_million": 33238.557,
    "new_cases_per_million": 236.94,
    "new_cases_smoothed_per_million": 177.544,
    "total_deaths_per_million": 428.926,
    "new_deaths_per_million": 2.748,
    "new_deaths_smoothed_per_million": 2.776,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": 55,
    "total_vaccinations_per_hundred": 0,
    "stringency_index": 55.56,
    "population": 5094114,
    "population_density": 96.079,
    "median_age": 33.6,
    "aged_65_older": 9.468,
    "aged_70_older": 5.694,
    "gdp_per_capita": 15524.995,
    "extreme_poverty": 1.3,
    "cardiovasc_death_rate": 137.973,
    "diabetes_prevalence": 8.78,
    "female_smokers": 6.4,
    "male_smokers": 17.4,
    "handwashing_facilities": 83.841,
    "hospital_beds_per_thousand": 1.13,
    "life_expectancy": 80.28,
    "human_development_index": 0.794
  },
  {
    "iso_code": "CIV",
    "continent": "Africa",
    "location": "Cote d'Ivoire",
    "total_cases": 22490,
    "new_cases": 124,
    "new_cases_smoothed": 59.857,
    "total_deaths": 137,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.571,
    "total_cases_per_million": 852.596,
    "new_cases_per_million": 4.701,
    "new_cases_smoothed_per_million": 2.269,
    "total_deaths_per_million": 5.194,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.022,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 1646,
    "total_tests": 256649,
    "total_tests_per_thousand": 9.73,
    "new_tests_per_thousand": 0.062,
    "new_tests_smoothed": 1228,
    "new_tests_smoothed_per_thousand": 0.047,
    "positive_rate": 0.044,
    "tests_per_case": 22.5,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 15.74,
    "population": 26378275,
    "population_density": 76.399,
    "median_age": 18.7,
    "aged_65_older": 2.933,
    "aged_70_older": 1.582,
    "gdp_per_capita": 3601.006,
    "extreme_poverty": 28.2,
    "cardiovasc_death_rate": 303.74,
    "diabetes_prevalence": 2.42,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 19.351,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 57.78,
    "human_development_index": 0.492
  },
  {
    "iso_code": "HRV",
    "continent": "Europe",
    "location": "Croatia",
    "total_cases": 210837,
    "new_cases": 2391,
    "new_cases_smoothed": 1216.857,
    "total_deaths": 3920,
    "new_deaths": 60,
    "new_deaths_smoothed": 65.143,
    "total_cases_per_million": 51357.67,
    "new_cases_per_million": 582.422,
    "new_cases_smoothed_per_million": 296.414,
    "total_deaths_per_million": 954.871,
    "new_deaths_per_million": 14.615,
    "new_deaths_smoothed_per_million": 15.868,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": 2664,
    "hosp_patients_per_million": 648.922,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": 1496.58,
    "weekly_hosp_admissions_per_million": 364.551,
    "new_tests": 8441,
    "total_tests": 1019109,
    "total_tests_per_thousand": 248.244,
    "new_tests_per_thousand": 2.056,
    "new_tests_smoothed": 5180,
    "new_tests_smoothed_per_thousand": 1.262,
    "positive_rate": 0.235,
    "tests_per_case": 4.3,
    "tests_units": "people tested",
    "total_vaccinations": 7864,
    "total_vaccinations_per_hundred": 0.19,
    "stringency_index": 41.67,
    "population": 4105268,
    "population_density": 73.726,
    "median_age": 44,
    "aged_65_older": 19.724,
    "aged_70_older": 13.053,
    "gdp_per_capita": 22669.797,
    "extreme_poverty": 0.7,
    "cardiovasc_death_rate": 253.782,
    "diabetes_prevalence": 5.59,
    "female_smokers": 34.3,
    "male_smokers": 39.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 5.54,
    "life_expectancy": 78.49,
    "human_development_index": 0.831
  },
  {
    "iso_code": "CUB",
    "continent": "North America",
    "location": "Cuba",
    "total_cases": 11863,
    "new_cases": 176,
    "new_cases_smoothed": 163.714,
    "total_deaths": 146,
    "new_deaths": 1,
    "new_deaths_smoothed": 1,
    "total_cases_per_million": 1047.356,
    "new_cases_per_million": 15.539,
    "new_cases_smoothed_per_million": 14.454,
    "total_deaths_per_million": 12.89,
    "new_deaths_per_million": 0.088,
    "new_deaths_smoothed_per_million": 0.088,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 14605,
    "total_tests": 1476673,
    "total_tests_per_thousand": 130.372,
    "new_tests_per_thousand": 1.289,
    "new_tests_smoothed": 13542,
    "new_tests_smoothed_per_thousand": 1.196,
    "positive_rate": 0.013,
    "tests_per_case": 79.9,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 56.48,
    "population": 11326616,
    "population_density": 110.408,
    "median_age": 43.1,
    "aged_65_older": 14.738,
    "aged_70_older": 9.719,
    "gdp_per_capita": "",
    "extreme_poverty": "",
    "cardiovasc_death_rate": 190.968,
    "diabetes_prevalence": 8.27,
    "female_smokers": 17.1,
    "male_smokers": 53.3,
    "handwashing_facilities": 85.198,
    "hospital_beds_per_thousand": 5.2,
    "life_expectancy": 78.8,
    "human_development_index": 0.777
  },
  {
    "iso_code": "CYP",
    "continent": "Europe",
    "location": "Cyprus",
    "total_cases": 22019,
    "new_cases": 0,
    "new_cases_smoothed": 386.143,
    "total_deaths": 119,
    "new_deaths": 0,
    "new_deaths_smoothed": 1.714,
    "total_cases_per_million": 25138.743,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 440.853,
    "total_deaths_per_million": 135.86,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 1.957,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": 14,
    "weekly_icu_admissions_per_million": 15.984,
    "weekly_hosp_admissions": 71,
    "weekly_hosp_admissions_per_million": 81.06,
    "new_tests": 18070,
    "total_tests": 1007536,
    "total_tests_per_thousand": 1150.288,
    "new_tests_per_thousand": 20.63,
    "new_tests_smoothed": 9337,
    "new_tests_smoothed_per_thousand": 10.66,
    "positive_rate": 0.051,
    "tests_per_case": 19.7,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 69.44,
    "population": 875899,
    "population_density": 127.657,
    "median_age": 37.3,
    "aged_65_older": 13.416,
    "aged_70_older": 8.563,
    "gdp_per_capita": 32415.132,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 141.171,
    "diabetes_prevalence": 9.24,
    "female_smokers": 19.6,
    "male_smokers": 52.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.4,
    "life_expectancy": 80.98,
    "human_development_index": 0.869
  },
  {
    "iso_code": "CZE",
    "continent": "Europe",
    "location": "Czechia",
    "total_cases": 718661,
    "new_cases": 17039,
    "new_cases_smoothed": 8314.286,
    "total_deaths": 11580,
    "new_deaths": 151,
    "new_deaths_smoothed": 114.857,
    "total_cases_per_million": 67108.246,
    "new_cases_per_million": 1591.094,
    "new_cases_smoothed_per_million": 776.384,
    "total_deaths_per_million": 1081.335,
    "new_deaths_per_million": 14.1,
    "new_deaths_smoothed_per_million": 10.725,
    "reproduction_rate": "",
    "icu_patients": 741,
    "icu_patients_per_million": 69.194,
    "hosp_patients": 4973,
    "hosp_patients_per_million": 464.377,
    "weekly_icu_admissions": 1382.641,
    "weekly_icu_admissions_per_million": 129.11,
    "weekly_hosp_admissions": 8195.29,
    "weekly_hosp_admissions_per_million": 765.273,
    "new_tests": 26547,
    "total_tests": 3811930,
    "total_tests_per_thousand": 355.956,
    "new_tests_per_thousand": 2.479,
    "new_tests_smoothed": 21870,
    "new_tests_smoothed_per_thousand": 2.042,
    "positive_rate": 0.38,
    "tests_per_case": 2.6,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 10708982,
    "population_density": 137.176,
    "median_age": 43.3,
    "aged_65_older": 19.027,
    "aged_70_older": 11.58,
    "gdp_per_capita": 32605.906,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 227.485,
    "diabetes_prevalence": 6.82,
    "female_smokers": 30.5,
    "male_smokers": 38.3,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 6.63,
    "life_expectancy": 79.38,
    "human_development_index": 0.888
  },
  {
    "iso_code": "COD",
    "continent": "Africa",
    "location": "Democratic Republic of Congo",
    "total_cases": 17658,
    "new_cases": 282,
    "new_cases_smoothed": 196.571,
    "total_deaths": 591,
    "new_deaths": 7,
    "new_deaths_smoothed": 2.571,
    "total_cases_per_million": 197.161,
    "new_cases_per_million": 3.149,
    "new_cases_smoothed_per_million": 2.195,
    "total_deaths_per_million": 6.599,
    "new_deaths_per_million": 0.078,
    "new_deaths_smoothed_per_million": 0.029,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 714,
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": 0.008,
    "new_tests_smoothed": 492,
    "new_tests_smoothed_per_thousand": 0.005,
    "positive_rate": 0.388,
    "tests_per_case": 2.6,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 19.44,
    "population": 89561404,
    "population_density": 35.879,
    "median_age": 17,
    "aged_65_older": 3.02,
    "aged_70_older": 1.745,
    "gdp_per_capita": 808.133,
    "extreme_poverty": 77.1,
    "cardiovasc_death_rate": 318.949,
    "diabetes_prevalence": 6.1,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 4.472,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 60.68,
    "human_development_index": 0.457
  },
  {
    "iso_code": "DNK",
    "continent": "Europe",
    "location": "Denmark",
    "total_cases": 164116,
    "new_cases": 2254,
    "new_cases_smoothed": 2455.571,
    "total_deaths": 1298,
    "new_deaths": 42,
    "new_deaths_smoothed": 26.286,
    "total_cases_per_million": 28333.952,
    "new_cases_per_million": 389.144,
    "new_cases_smoothed_per_million": 423.944,
    "total_deaths_per_million": 224.094,
    "new_deaths_per_million": 7.251,
    "new_deaths_smoothed_per_million": 4.538,
    "reproduction_rate": "",
    "icu_patients": 102,
    "icu_patients_per_million": 17.61,
    "hosp_patients": 817,
    "hosp_patients_per_million": 141.052,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": 1049.485,
    "weekly_hosp_admissions_per_million": 181.189,
    "new_tests": 114373,
    "total_tests": 10356082,
    "total_tests_per_thousand": 1787.935,
    "new_tests_per_thousand": 19.746,
    "new_tests_smoothed": 78845,
    "new_tests_smoothed_per_thousand": 13.612,
    "positive_rate": 0.033,
    "tests_per_case": 30.1,
    "tests_units": "tests performed",
    "total_vaccinations": 29483,
    "total_vaccinations_per_hundred": 0.51,
    "stringency_index": 51.85,
    "population": 5792203,
    "population_density": 136.52,
    "median_age": 42.3,
    "aged_65_older": 19.677,
    "aged_70_older": 12.325,
    "gdp_per_capita": 46682.515,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 114.767,
    "diabetes_prevalence": 6.41,
    "female_smokers": 19.3,
    "male_smokers": 18.8,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.5,
    "life_expectancy": 80.9,
    "human_development_index": 0.929
  },
  {
    "iso_code": "DJI",
    "continent": "Africa",
    "location": "Djibouti",
    "total_cases": 5831,
    "new_cases": 7,
    "new_cases_smoothed": 4.429,
    "total_deaths": 61,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 5901.81,
    "new_cases_per_million": 7.085,
    "new_cases_smoothed_per_million": 4.482,
    "total_deaths_per_million": 61.741,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 37.96,
    "population": 988002,
    "population_density": 41.285,
    "median_age": 25.4,
    "aged_65_older": 4.213,
    "aged_70_older": 2.38,
    "gdp_per_capita": 2705.406,
    "extreme_poverty": 22.5,
    "cardiovasc_death_rate": 258.037,
    "diabetes_prevalence": 6.05,
    "female_smokers": 1.7,
    "male_smokers": 24.5,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 1.4,
    "life_expectancy": 67.11,
    "human_development_index": 0.476
  },
  {
    "iso_code": "DMA",
    "continent": "North America",
    "location": "Dominica",
    "total_cases": 88,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 1222.375,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 50.93,
    "population": 71991,
    "population_density": 98.567,
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": 9673.367,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 227.376,
    "diabetes_prevalence": 11.62,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.8,
    "life_expectancy": 75,
    "human_development_index": 0.715
  },
  {
    "iso_code": "DOM",
    "continent": "North America",
    "location": "Dominican Republic",
    "total_cases": 170785,
    "new_cases": 1206,
    "new_cases_smoothed": 1018.714,
    "total_deaths": 2414,
    "new_deaths": 5,
    "new_deaths_smoothed": 1.429,
    "total_cases_per_million": 15743.594,
    "new_cases_per_million": 111.174,
    "new_cases_smoothed_per_million": 93.909,
    "total_deaths_per_million": 222.531,
    "new_deaths_per_million": 0.461,
    "new_deaths_smoothed_per_million": 0.132,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": 860206,
    "total_tests_per_thousand": 79.297,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": 3703,
    "new_tests_smoothed_per_thousand": 0.341,
    "positive_rate": 0.245,
    "tests_per_case": 4.1,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 10847904,
    "population_density": 222.873,
    "median_age": 27.6,
    "aged_65_older": 6.981,
    "aged_70_older": 4.419,
    "gdp_per_capita": 14600.861,
    "extreme_poverty": 1.6,
    "cardiovasc_death_rate": 266.653,
    "diabetes_prevalence": 8.2,
    "female_smokers": 8.5,
    "male_smokers": 19.1,
    "handwashing_facilities": 55.182,
    "hospital_beds_per_thousand": 1.6,
    "life_expectancy": 74.08,
    "human_development_index": 0.736
  },
  {
    "iso_code": "ECU",
    "continent": "South America",
    "location": "Ecuador",
    "total_cases": 212512,
    "new_cases": 1000,
    "new_cases_smoothed": 643.143,
    "total_deaths": 14034,
    "new_deaths": 11,
    "new_deaths_smoothed": 8.143,
    "total_cases_per_million": 12045.076,
    "new_cases_per_million": 56.68,
    "new_cases_smoothed_per_million": 36.453,
    "total_deaths_per_million": 795.44,
    "new_deaths_per_million": 0.623,
    "new_deaths_smoothed_per_million": 0.462,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 5091,
    "total_tests": 697587,
    "total_tests_per_thousand": 39.539,
    "new_tests_per_thousand": 0.289,
    "new_tests_smoothed": 2900,
    "new_tests_smoothed_per_thousand": 0.164,
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 55.56,
    "population": 17643060,
    "population_density": 66.939,
    "median_age": 28.1,
    "aged_65_older": 7.104,
    "aged_70_older": 4.458,
    "gdp_per_capita": 10581.936,
    "extreme_poverty": 3.6,
    "cardiovasc_death_rate": 140.448,
    "diabetes_prevalence": 5.55,
    "female_smokers": 2,
    "male_smokers": 12.3,
    "handwashing_facilities": 80.635,
    "hospital_beds_per_thousand": 1.5,
    "life_expectancy": 77.01,
    "human_development_index": 0.752
  },
  {
    "iso_code": "EGY",
    "continent": "Africa",
    "location": "Egypt",
    "total_cases": 138062,
    "new_cases": 1418,
    "new_cases_smoothed": 1295.571,
    "total_deaths": 7631,
    "new_deaths": 55,
    "new_deaths_smoothed": 53,
    "total_cases_per_million": 1349.126,
    "new_cases_per_million": 13.857,
    "new_cases_smoothed_per_million": 12.66,
    "total_deaths_per_million": 74.569,
    "new_deaths_per_million": 0.537,
    "new_deaths_smoothed_per_million": 0.518,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 60.19,
    "population": 102334403,
    "population_density": 97.999,
    "median_age": 25.3,
    "aged_65_older": 5.159,
    "aged_70_older": 2.891,
    "gdp_per_capita": 10550.206,
    "extreme_poverty": 1.3,
    "cardiovasc_death_rate": 525.432,
    "diabetes_prevalence": 17.31,
    "female_smokers": 0.2,
    "male_smokers": 50.1,
    "handwashing_facilities": 89.827,
    "hospital_beds_per_thousand": 1.6,
    "life_expectancy": 71.99,
    "human_development_index": 0.696
  },
  {
    "iso_code": "SLV",
    "continent": "North America",
    "location": "El Salvador",
    "total_cases": 45960,
    "new_cases": 0,
    "new_cases_smoothed": 191.571,
    "total_deaths": 1327,
    "new_deaths": 0,
    "new_deaths_smoothed": 6.286,
    "total_cases_per_million": 7085.812,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 29.535,
    "total_deaths_per_million": 204.588,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.969,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": 609398,
    "total_tests_per_thousand": 93.953,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": 2484,
    "new_tests_smoothed_per_thousand": 0.383,
    "positive_rate": 0.079,
    "tests_per_case": 12.6,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 47.22,
    "population": 6486201,
    "population_density": 307.811,
    "median_age": 27.6,
    "aged_65_older": 8.273,
    "aged_70_older": 5.417,
    "gdp_per_capita": 7292.458,
    "extreme_poverty": 2.2,
    "cardiovasc_death_rate": 167.295,
    "diabetes_prevalence": 8.87,
    "female_smokers": 2.5,
    "male_smokers": 18.8,
    "handwashing_facilities": 90.65,
    "hospital_beds_per_thousand": 1.3,
    "life_expectancy": 73.32,
    "human_development_index": 0.674
  },
  {
    "iso_code": "GNQ",
    "continent": "Africa",
    "location": "Equatorial Guinea",
    "total_cases": 5277,
    "new_cases": 13,
    "new_cases_smoothed": 5.857,
    "total_deaths": 86,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 3761.266,
    "new_cases_per_million": 9.266,
    "new_cases_smoothed_per_million": 4.175,
    "total_deaths_per_million": 61.298,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.102,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 1402985,
    "population_density": 45.194,
    "median_age": 22.4,
    "aged_65_older": 2.846,
    "aged_70_older": 1.752,
    "gdp_per_capita": 22604.873,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 202.812,
    "diabetes_prevalence": 7.78,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 24.64,
    "hospital_beds_per_thousand": 2.1,
    "life_expectancy": 58.74,
    "human_development_index": 0.591
  },
  {
    "iso_code": "ERI",
    "continent": "Africa",
    "location": "Eritrea",
    "total_cases": 1252,
    "new_cases": 0,
    "new_cases_smoothed": 43,
    "total_deaths": 1,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 353.031,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 12.125,
    "total_deaths_per_million": 0.282,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 75,
    "population": 3546427,
    "population_density": 44.304,
    "median_age": 19.3,
    "aged_65_older": 3.607,
    "aged_70_older": 2.171,
    "gdp_per_capita": 1510.459,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 311.11,
    "diabetes_prevalence": 6.05,
    "female_smokers": 0.2,
    "male_smokers": 11.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 0.7,
    "life_expectancy": 66.32,
    "human_development_index": 0.44
  },
  {
    "iso_code": "EST",
    "continent": "Europe",
    "location": "Estonia",
    "total_cases": 27990,
    "new_cases": 734,
    "new_cases_smoothed": 568.714,
    "total_deaths": 229,
    "new_deaths": 3,
    "new_deaths_smoothed": 4.857,
    "total_cases_per_million": 21100.02,
    "new_cases_per_million": 553.32,
    "new_cases_smoothed_per_million": 428.72,
    "total_deaths_per_million": 172.63,
    "new_deaths_per_million": 2.262,
    "new_deaths_smoothed_per_million": 3.662,
    "reproduction_rate": "",
    "icu_patients": 40,
    "icu_patients_per_million": 30.154,
    "hosp_patients": 435,
    "hosp_patients_per_million": 327.921,
    "weekly_icu_admissions": 29.038,
    "weekly_icu_admissions_per_million": 21.89,
    "weekly_hosp_admissions": 274.356,
    "weekly_hosp_admissions_per_million": 206.821,
    "new_tests": 3508,
    "total_tests": 636858,
    "total_tests_per_thousand": 480.09,
    "new_tests_per_thousand": 2.644,
    "new_tests_smoothed": 4102,
    "new_tests_smoothed_per_thousand": 3.092,
    "positive_rate": 0.139,
    "tests_per_case": 7.2,
    "tests_units": "tests performed",
    "total_vaccinations": 2487,
    "total_vaccinations_per_hundred": 0.19,
    "stringency_index": 55.56,
    "population": 1326539,
    "population_density": 31.033,
    "median_age": 42.7,
    "aged_65_older": 19.452,
    "aged_70_older": 13.491,
    "gdp_per_capita": 29481.252,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 255.569,
    "diabetes_prevalence": 4.02,
    "female_smokers": 24.5,
    "male_smokers": 39.3,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 4.69,
    "life_expectancy": 78.74,
    "human_development_index": 0.871
  },
  {
    "iso_code": "SWZ",
    "continent": "Africa",
    "location": "Eswatini",
    "total_cases": 9358,
    "new_cases": 212,
    "new_cases_smoothed": 189.429,
    "total_deaths": 205,
    "new_deaths": 21,
    "new_deaths_smoothed": 7.286,
    "total_cases_per_million": 8066.101,
    "new_cases_per_million": 182.733,
    "new_cases_smoothed_per_million": 163.277,
    "total_deaths_per_million": 176.699,
    "new_deaths_per_million": 18.101,
    "new_deaths_smoothed_per_million": 6.28,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 50.93,
    "population": 1160164,
    "population_density": 79.492,
    "median_age": 21.5,
    "aged_65_older": 3.163,
    "aged_70_older": 1.845,
    "gdp_per_capita": 7738.975,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 333.436,
    "diabetes_prevalence": 3.94,
    "female_smokers": 1.7,
    "male_smokers": 16.5,
    "handwashing_facilities": 24.097,
    "hospital_beds_per_thousand": 2.1,
    "life_expectancy": 60.19,
    "human_development_index": 0.588
  },
  {
    "iso_code": "ETH",
    "continent": "Africa",
    "location": "Ethiopia",
    "total_cases": 124264,
    "new_cases": 408,
    "new_cases_smoothed": 409.286,
    "total_deaths": 1923,
    "new_deaths": 5,
    "new_deaths_smoothed": 5.857,
    "total_cases_per_million": 1080.899,
    "new_cases_per_million": 3.549,
    "new_cases_smoothed_per_million": 3.56,
    "total_deaths_per_million": 16.727,
    "new_deaths_per_million": 0.043,
    "new_deaths_smoothed_per_million": 0.051,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 5547,
    "total_tests": 1800236,
    "total_tests_per_thousand": 15.659,
    "new_tests_per_thousand": 0.048,
    "new_tests_smoothed": 5341,
    "new_tests_smoothed_per_thousand": 0.046,
    "positive_rate": 0.077,
    "tests_per_case": 13,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 114963583,
    "population_density": 104.957,
    "median_age": 19.8,
    "aged_65_older": 3.526,
    "aged_70_older": 2.063,
    "gdp_per_capita": 1729.927,
    "extreme_poverty": 26.7,
    "cardiovasc_death_rate": 182.634,
    "diabetes_prevalence": 7.47,
    "female_smokers": 0.4,
    "male_smokers": 8.5,
    "handwashing_facilities": 7.96,
    "hospital_beds_per_thousand": 0.3,
    "life_expectancy": 66.6,
    "human_development_index": 0.463
  },
  {
    "iso_code": "FJI",
    "continent": "Oceania",
    "location": "Fiji",
    "total_cases": 49,
    "new_cases": 0,
    "new_cases_smoothed": 0.429,
    "total_deaths": 2,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 54.66,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0.478,
    "total_deaths_per_million": 2.231,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 363,
    "total_tests": 21072,
    "total_tests_per_thousand": 23.506,
    "new_tests_per_thousand": 0.405,
    "new_tests_smoothed": 170,
    "new_tests_smoothed_per_thousand": 0.19,
    "positive_rate": 0,
    "tests_per_case": 566.4,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 896444,
    "population_density": 49.562,
    "median_age": 28.6,
    "aged_65_older": 6.224,
    "aged_70_older": 3.284,
    "gdp_per_capita": 8702.975,
    "extreme_poverty": 1.4,
    "cardiovasc_death_rate": 412.82,
    "diabetes_prevalence": 14.49,
    "female_smokers": 10.2,
    "male_smokers": 34.8,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.3,
    "life_expectancy": 67.44,
    "human_development_index": 0.741
  },
  {
    "iso_code": "FIN",
    "continent": "Europe",
    "location": "Finland",
    "total_cases": 36107,
    "new_cases": 249,
    "new_cases_smoothed": 237.143,
    "total_deaths": 561,
    "new_deaths": 5,
    "new_deaths_smoothed": 5.286,
    "total_cases_per_million": 6516.664,
    "new_cases_per_million": 44.94,
    "new_cases_smoothed_per_million": 42.8,
    "total_deaths_per_million": 101.25,
    "new_deaths_per_million": 0.902,
    "new_deaths_smoothed_per_million": 0.954,
    "reproduction_rate": "",
    "icu_patients": 30,
    "icu_patients_per_million": 5.414,
    "hosp_patients": 263,
    "hosp_patients_per_million": 47.467,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 13667,
    "total_tests": 2491963,
    "total_tests_per_thousand": 449.755,
    "new_tests_per_thousand": 2.467,
    "new_tests_smoothed": 10338,
    "new_tests_smoothed_per_thousand": 1.866,
    "positive_rate": 0.025,
    "tests_per_case": 40.8,
    "tests_units": "tests performed",
    "total_vaccinations": 1767,
    "total_vaccinations_per_hundred": 0.03,
    "stringency_index": 50.46,
    "population": 5540718,
    "population_density": 18.136,
    "median_age": 42.8,
    "aged_65_older": 21.228,
    "aged_70_older": 13.264,
    "gdp_per_capita": 40585.721,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 153.507,
    "diabetes_prevalence": 5.76,
    "female_smokers": 18.3,
    "male_smokers": 22.6,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.28,
    "life_expectancy": 81.91,
    "human_development_index": 0.92
  },
  {
    "iso_code": "FRA",
    "continent": "Europe",
    "location": "France",
    "total_cases": 2677666,
    "new_cases": 20042,
    "new_cases_smoothed": 13333.286,
    "total_deaths": 64759,
    "new_deaths": 251,
    "new_deaths_smoothed": 338.571,
    "total_cases_per_million": 41022.245,
    "new_cases_per_million": 307.046,
    "new_cases_smoothed_per_million": 204.268,
    "total_deaths_per_million": 992.118,
    "new_deaths_per_million": 3.845,
    "new_deaths_smoothed_per_million": 5.187,
    "reproduction_rate": "",
    "icu_patients": 2650,
    "icu_patients_per_million": 40.598,
    "hosp_patients": 24620,
    "hosp_patients_per_million": 377.182,
    "weekly_icu_admissions": 1088.981,
    "weekly_icu_admissions_per_million": 16.683,
    "weekly_hosp_admissions": 7514.751,
    "weekly_hosp_admissions_per_million": 115.127,
    "new_tests": 393766,
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": 6.033,
    "new_tests_smoothed": 376973,
    "new_tests_smoothed_per_thousand": 5.775,
    "positive_rate": 0.032,
    "tests_per_case": 31.2,
    "tests_units": "people tested",
    "total_vaccinations": 138,
    "total_vaccinations_per_hundred": 0,
    "stringency_index": 69.44,
    "population": 65273512,
    "population_density": 122.578,
    "median_age": 42,
    "aged_65_older": 19.718,
    "aged_70_older": 13.079,
    "gdp_per_capita": 38605.671,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 86.06,
    "diabetes_prevalence": 4.77,
    "female_smokers": 30.1,
    "male_smokers": 35.6,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 5.98,
    "life_expectancy": 82.66,
    "human_development_index": 0.901
  },
  {
    "iso_code": "GAB",
    "continent": "Africa",
    "location": "Gabon",
    "total_cases": 9571,
    "new_cases": 0,
    "new_cases_smoothed": 14.571,
    "total_deaths": 64,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 4300.166,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 6.547,
    "total_deaths_per_million": 28.755,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 53.7,
    "population": 2225728,
    "population_density": 7.859,
    "median_age": 23.1,
    "aged_65_older": 4.45,
    "aged_70_older": 2.976,
    "gdp_per_capita": 16562.413,
    "extreme_poverty": 3.4,
    "cardiovasc_death_rate": 259.967,
    "diabetes_prevalence": 7.2,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 6.3,
    "life_expectancy": 66.47,
    "human_development_index": 0.702
  },
  {
    "iso_code": "GMB",
    "continent": "Africa",
    "location": "Gambia",
    "total_cases": 3797,
    "new_cases": 0,
    "new_cases_smoothed": 0.857,
    "total_deaths": 124,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 1571.174,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0.355,
    "total_deaths_per_million": 51.31,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.059,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 2416664,
    "population_density": 207.566,
    "median_age": 17.5,
    "aged_65_older": 2.339,
    "aged_70_older": 1.417,
    "gdp_per_capita": 1561.767,
    "extreme_poverty": 10.1,
    "cardiovasc_death_rate": 331.43,
    "diabetes_prevalence": 1.91,
    "female_smokers": 0.7,
    "male_smokers": 31.2,
    "handwashing_facilities": 7.876,
    "hospital_beds_per_thousand": 1.1,
    "life_expectancy": 62.05,
    "human_development_index": 0.46
  },
  {
    "iso_code": "GEO",
    "continent": "Asia",
    "location": "Georgia",
    "total_cases": 227420,
    "new_cases": 1527,
    "new_cases_smoothed": 1511,
    "total_deaths": 2505,
    "new_deaths": 24,
    "new_deaths_smoothed": 32.714,
    "total_cases_per_million": 57009.281,
    "new_cases_per_million": 382.786,
    "new_cases_smoothed_per_million": 378.775,
    "total_deaths_per_million": 627.949,
    "new_deaths_per_million": 6.016,
    "new_deaths_smoothed_per_million": 8.201,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 84.26,
    "population": 3989175,
    "population_density": 65.032,
    "median_age": 38.7,
    "aged_65_older": 14.864,
    "aged_70_older": 10.244,
    "gdp_per_capita": 9745.079,
    "extreme_poverty": 4.2,
    "cardiovasc_death_rate": 496.218,
    "diabetes_prevalence": 7.11,
    "female_smokers": 5.3,
    "male_smokers": 55.5,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.6,
    "life_expectancy": 73.77,
    "human_development_index": 0.78
  },
  {
    "iso_code": "DEU",
    "continent": "Europe",
    "location": "Germany",
    "total_cases": 1760520,
    "new_cases": 19367,
    "new_cases_smoothed": 18560.571,
    "total_deaths": 33791,
    "new_deaths": 561,
    "new_deaths_smoothed": 637.286,
    "total_cases_per_million": 21012.618,
    "new_cases_per_million": 231.154,
    "new_cases_smoothed_per_million": 221.529,
    "total_deaths_per_million": 403.311,
    "new_deaths_per_million": 6.696,
    "new_deaths_smoothed_per_million": 7.606,
    "reproduction_rate": "",
    "icu_patients": 5546,
    "icu_patients_per_million": 66.194,
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": 2499.817,
    "weekly_hosp_admissions_per_million": 29.836,
    "new_tests": "",
    "total_tests": 34801593,
    "total_tests_per_thousand": 415.373,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": 151038,
    "new_tests_smoothed_per_thousand": 1.803,
    "positive_rate": 0.13,
    "tests_per_case": 7.7,
    "tests_units": "tests performed",
    "total_vaccinations": 165575,
    "total_vaccinations_per_hundred": 0.2,
    "stringency_index": 82.41,
    "population": 83783945,
    "population_density": 237.016,
    "median_age": 46.6,
    "aged_65_older": 21.453,
    "aged_70_older": 15.957,
    "gdp_per_capita": 45229.245,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 156.139,
    "diabetes_prevalence": 8.31,
    "female_smokers": 28.2,
    "male_smokers": 33.1,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 8,
    "life_expectancy": 81.33,
    "human_development_index": 0.936
  },
  {
    "iso_code": "GHA",
    "continent": "Africa",
    "location": "Ghana",
    "total_cases": 54771,
    "new_cases": 0,
    "new_cases_smoothed": 104,
    "total_deaths": 335,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 1762.659,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 3.347,
    "total_deaths_per_million": 10.781,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.009,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 1952,
    "total_tests": 665661,
    "total_tests_per_thousand": 21.423,
    "new_tests_per_thousand": 0.063,
    "new_tests_smoothed": 1975,
    "new_tests_smoothed_per_thousand": 0.064,
    "positive_rate": 0.054,
    "tests_per_case": 18.5,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 38.89,
    "population": 31072945,
    "population_density": 126.719,
    "median_age": 21.1,
    "aged_65_older": 3.385,
    "aged_70_older": 1.948,
    "gdp_per_capita": 4227.63,
    "extreme_poverty": 12,
    "cardiovasc_death_rate": 298.245,
    "diabetes_prevalence": 4.97,
    "female_smokers": 0.3,
    "male_smokers": 7.7,
    "handwashing_facilities": 41.047,
    "hospital_beds_per_thousand": 0.9,
    "life_expectancy": 64.07,
    "human_development_index": 0.592
  },
  {
    "iso_code": "GRC",
    "continent": "Europe",
    "location": "Greece",
    "total_cases": 138850,
    "new_cases": 932,
    "new_cases_smoothed": 659.286,
    "total_deaths": 4838,
    "new_deaths": 50,
    "new_deaths_smoothed": 54.429,
    "total_cases_per_million": 13321.429,
    "new_cases_per_million": 89.417,
    "new_cases_smoothed_per_million": 63.253,
    "total_deaths_per_million": 464.163,
    "new_deaths_per_million": 4.797,
    "new_deaths_smoothed_per_million": 5.222,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": 168.136,
    "weekly_icu_admissions_per_million": 16.131,
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 13572,
    "total_tests": 2803026,
    "total_tests_per_thousand": 268.926,
    "new_tests_per_thousand": 1.302,
    "new_tests_smoothed": 9863,
    "new_tests_smoothed_per_thousand": 0.946,
    "positive_rate": 0.067,
    "tests_per_case": 15,
    "tests_units": "samples tested",
    "total_vaccinations": 1763,
    "total_vaccinations_per_hundred": 0.02,
    "stringency_index": 84.26,
    "population": 10423056,
    "population_density": 83.479,
    "median_age": 45.3,
    "aged_65_older": 20.396,
    "aged_70_older": 14.524,
    "gdp_per_capita": 24574.382,
    "extreme_poverty": 1.5,
    "cardiovasc_death_rate": 175.695,
    "diabetes_prevalence": 4.55,
    "female_smokers": 35.3,
    "male_smokers": 52,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 4.21,
    "life_expectancy": 82.24,
    "human_development_index": 0.87
  },
  {
    "iso_code": "GRD",
    "continent": "North America",
    "location": "Grenada",
    "total_cases": 127,
    "new_cases": 0,
    "new_cases_smoothed": 3.143,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 1128.698,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 27.932,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 112519,
    "population_density": 317.132,
    "median_age": 29.4,
    "aged_65_older": 7.304,
    "aged_70_older": 5.021,
    "gdp_per_capita": 13593.877,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 243.964,
    "diabetes_prevalence": 10.71,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.7,
    "life_expectancy": 72.4,
    "human_development_index": 0.772
  },
  {
    "iso_code": "GTM",
    "continent": "North America",
    "location": "Guatemala",
    "total_cases": 138012,
    "new_cases": 846,
    "new_cases_smoothed": 445.429,
    "total_deaths": 4813,
    "new_deaths": 10,
    "new_deaths_smoothed": 9.143,
    "total_cases_per_million": 7703.468,
    "new_cases_per_million": 47.222,
    "new_cases_smoothed_per_million": 24.863,
    "total_deaths_per_million": 268.649,
    "new_deaths_per_million": 0.558,
    "new_deaths_smoothed_per_million": 0.51,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 5269,
    "total_tests": 640195,
    "total_tests_per_thousand": 35.734,
    "new_tests_per_thousand": 0.294,
    "new_tests_smoothed": 2993,
    "new_tests_smoothed_per_thousand": 0.167,
    "positive_rate": 0.139,
    "tests_per_case": 7.2,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 49.54,
    "population": 17915567,
    "population_density": 157.834,
    "median_age": 22.9,
    "aged_65_older": 4.694,
    "aged_70_older": 3.016,
    "gdp_per_capita": 7423.808,
    "extreme_poverty": 8.7,
    "cardiovasc_death_rate": 155.898,
    "diabetes_prevalence": 10.18,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 76.665,
    "hospital_beds_per_thousand": 0.6,
    "life_expectancy": 74.3,
    "human_development_index": 0.65
  },
  {
    "iso_code": "GIN",
    "continent": "Africa",
    "location": "Guinea",
    "total_cases": 13722,
    "new_cases": 15,
    "new_cases_smoothed": 13.143,
    "total_deaths": 81,
    "new_deaths": 1,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 1044.865,
    "new_cases_per_million": 1.142,
    "new_cases_smoothed_per_million": 1.001,
    "total_deaths_per_million": 6.168,
    "new_deaths_per_million": 0.076,
    "new_deaths_smoothed_per_million": 0.011,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 13132792,
    "population_density": 51.755,
    "median_age": 19,
    "aged_65_older": 3.135,
    "aged_70_older": 1.733,
    "gdp_per_capita": 1998.926,
    "extreme_poverty": 35.3,
    "cardiovasc_death_rate": 336.717,
    "diabetes_prevalence": 2.42,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 17.45,
    "hospital_beds_per_thousand": 0.3,
    "life_expectancy": 61.6,
    "human_development_index": 0.459
  },
  {
    "iso_code": "GNB",
    "continent": "Africa",
    "location": "Guinea-Bissau",
    "total_cases": 2452,
    "new_cases": 0,
    "new_cases_smoothed": 0.714,
    "total_deaths": 45,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 1245.936,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0.363,
    "total_deaths_per_million": 22.866,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 1967998,
    "population_density": 66.191,
    "median_age": 19.4,
    "aged_65_older": 3.002,
    "aged_70_older": 1.565,
    "gdp_per_capita": 1548.675,
    "extreme_poverty": 67.1,
    "cardiovasc_death_rate": 382.474,
    "diabetes_prevalence": 2.42,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 6.403,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 58.32,
    "human_development_index": 0.455
  },
  {
    "iso_code": "GUY",
    "continent": "South America",
    "location": "Guyana",
    "total_cases": 6332,
    "new_cases": 13,
    "new_cases_smoothed": 9.429,
    "total_deaths": 164,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 8050.254,
    "new_cases_per_million": 16.528,
    "new_cases_smoothed_per_million": 11.987,
    "total_deaths_per_million": 208.503,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.363,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 68.52,
    "population": 786559,
    "population_density": 3.952,
    "median_age": 26.3,
    "aged_65_older": 5.305,
    "aged_70_older": 2.837,
    "gdp_per_capita": 7435.047,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 373.159,
    "diabetes_prevalence": 11.62,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 77.159,
    "hospital_beds_per_thousand": 1.6,
    "life_expectancy": 69.91,
    "human_development_index": 0.654
  },
  {
    "iso_code": "HTI",
    "continent": "North America",
    "location": "Haiti",
    "total_cases": 10015,
    "new_cases": 16,
    "new_cases_smoothed": 30,
    "total_deaths": 236,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 878.314,
    "new_cases_per_million": 1.403,
    "new_cases_smoothed_per_million": 2.631,
    "total_deaths_per_million": 20.697,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.025,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 28.7,
    "population": 11402533,
    "population_density": 398.448,
    "median_age": 24.3,
    "aged_65_older": 4.8,
    "aged_70_older": 2.954,
    "gdp_per_capita": 1653.173,
    "extreme_poverty": 23.5,
    "cardiovasc_death_rate": 430.548,
    "diabetes_prevalence": 6.65,
    "female_smokers": 2.9,
    "male_smokers": 23.1,
    "handwashing_facilities": 22.863,
    "hospital_beds_per_thousand": 0.7,
    "life_expectancy": 64,
    "human_development_index": 0.498
  },
  {
    "iso_code": "HND",
    "continent": "North America",
    "location": "Honduras",
    "total_cases": 121827,
    "new_cases": 915,
    "new_cases_smoothed": 541.571,
    "total_deaths": 3130,
    "new_deaths": 19,
    "new_deaths_smoothed": 10.857,
    "total_cases_per_million": 12300.032,
    "new_cases_per_million": 92.381,
    "new_cases_smoothed_per_million": 54.679,
    "total_deaths_per_million": 316.015,
    "new_deaths_per_million": 1.918,
    "new_deaths_smoothed_per_million": 1.096,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 9904608,
    "population_density": 82.805,
    "median_age": 24.9,
    "aged_65_older": 4.652,
    "aged_70_older": 2.883,
    "gdp_per_capita": 4541.795,
    "extreme_poverty": 16,
    "cardiovasc_death_rate": 240.208,
    "diabetes_prevalence": 7.21,
    "female_smokers": 2,
    "male_smokers": "",
    "handwashing_facilities": 84.169,
    "hospital_beds_per_thousand": 0.7,
    "life_expectancy": 75.27,
    "human_development_index": 0.617
  },
  {
    "iso_code": "HUN",
    "continent": "Europe",
    "location": "Hungary",
    "total_cases": 322514,
    "new_cases": 2971,
    "new_cases_smoothed": 1565.714,
    "total_deaths": 9537,
    "new_deaths": 108,
    "new_deaths_smoothed": 115.429,
    "total_cases_per_million": 33385.333,
    "new_cases_per_million": 307.546,
    "new_cases_smoothed_per_million": 162.076,
    "total_deaths_per_million": 987.231,
    "new_deaths_per_million": 11.18,
    "new_deaths_smoothed_per_million": 11.949,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": 6072,
    "hosp_patients_per_million": 628.549,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 17390,
    "total_tests": 2227027,
    "total_tests_per_thousand": 230.533,
    "new_tests_per_thousand": 1.8,
    "new_tests_smoothed": 11638,
    "new_tests_smoothed_per_thousand": 1.205,
    "positive_rate": 0.135,
    "tests_per_case": 7.4,
    "tests_units": "tests performed",
    "total_vaccinations": 5110,
    "total_vaccinations_per_hundred": 0.05,
    "stringency_index": 72.22,
    "population": 9660350,
    "population_density": 108.043,
    "median_age": 43.4,
    "aged_65_older": 18.577,
    "aged_70_older": 11.976,
    "gdp_per_capita": 26777.561,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 278.296,
    "diabetes_prevalence": 7.55,
    "female_smokers": 26.8,
    "male_smokers": 34.8,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 7.02,
    "life_expectancy": 76.88,
    "human_development_index": 0.838
  },
  {
    "iso_code": "ISL",
    "continent": "Europe",
    "location": "Iceland",
    "total_cases": 5754,
    "new_cases": 0,
    "new_cases_smoothed": 10.143,
    "total_deaths": 29,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 16861.538,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 29.723,
    "total_deaths_per_million": 84.982,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.419,
    "reproduction_rate": "",
    "icu_patients": 1,
    "icu_patients_per_million": 2.93,
    "hosp_patients": 25,
    "hosp_patients_per_million": 73.26,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": 2.868,
    "weekly_hosp_admissions_per_million": 8.404,
    "new_tests": 955,
    "total_tests": 239413,
    "total_tests_per_thousand": 701.577,
    "new_tests_per_thousand": 2.799,
    "new_tests_smoothed": 732,
    "new_tests_smoothed_per_thousand": 2.145,
    "positive_rate": 0.013,
    "tests_per_case": 74.3,
    "tests_units": "tests performed",
    "total_vaccinations": 4875,
    "total_vaccinations_per_hundred": 1.43,
    "stringency_index": "",
    "population": 341250,
    "population_density": 3.404,
    "median_age": 37.3,
    "aged_65_older": 14.431,
    "aged_70_older": 9.207,
    "gdp_per_capita": 46482.958,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 117.992,
    "diabetes_prevalence": 5.31,
    "female_smokers": 14.3,
    "male_smokers": 15.2,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.91,
    "life_expectancy": 82.99,
    "human_development_index": 0.935
  },
  {
    "iso_code": "IND",
    "continent": "Asia",
    "location": "India",
    "total_cases": 10266674,
    "new_cases": 0,
    "new_cases_smoothed": 17118.429,
    "total_deaths": 148738,
    "new_deaths": 0,
    "new_deaths_smoothed": 235.143,
    "total_cases_per_million": 7439.595,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 12.405,
    "total_deaths_per_million": 107.781,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.17,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 1127244,
    "total_tests": 172049274,
    "total_tests_per_thousand": 124.673,
    "new_tests_per_thousand": 0.817,
    "new_tests_smoothed": 962987,
    "new_tests_smoothed_per_thousand": 0.698,
    "positive_rate": 0.018,
    "tests_per_case": 56.3,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 68.98,
    "population": 1380004385,
    "population_density": 450.419,
    "median_age": 28.2,
    "aged_65_older": 5.989,
    "aged_70_older": 3.414,
    "gdp_per_capita": 6426.674,
    "extreme_poverty": 21.2,
    "cardiovasc_death_rate": 282.28,
    "diabetes_prevalence": 10.39,
    "female_smokers": 1.9,
    "male_smokers": 20.6,
    "handwashing_facilities": 59.55,
    "hospital_beds_per_thousand": 0.53,
    "life_expectancy": 69.66,
    "human_development_index": 0.64
  },
  {
    "iso_code": "IDN",
    "continent": "Asia",
    "location": "Indonesia",
    "total_cases": 743198,
    "new_cases": 8074,
    "new_cases_smoothed": 7194.286,
    "total_deaths": 22138,
    "new_deaths": 194,
    "new_deaths_smoothed": 221.286,
    "total_cases_per_million": 2717.125,
    "new_cases_per_million": 29.518,
    "new_cases_smoothed_per_million": 26.302,
    "total_deaths_per_million": 80.936,
    "new_deaths_per_million": 0.709,
    "new_deaths_smoothed_per_million": 0.809,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 37265,
    "total_tests": 4912745,
    "total_tests_per_thousand": 17.961,
    "new_tests_per_thousand": 0.136,
    "new_tests_smoothed": 40994,
    "new_tests_smoothed_per_thousand": 0.15,
    "positive_rate": 0.175,
    "tests_per_case": 5.7,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 58.8,
    "population": 273523621,
    "population_density": 145.725,
    "median_age": 29.3,
    "aged_65_older": 5.319,
    "aged_70_older": 3.053,
    "gdp_per_capita": 11188.744,
    "extreme_poverty": 5.7,
    "cardiovasc_death_rate": 342.864,
    "diabetes_prevalence": 6.32,
    "female_smokers": 2.8,
    "male_smokers": 76.1,
    "handwashing_facilities": 64.204,
    "hospital_beds_per_thousand": 1.04,
    "life_expectancy": 71.72,
    "human_development_index": 0.694
  },
  {
    "iso_code": "",
    "continent": "",
    "location": "International",
    "total_cases": 721,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": 15,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": "",
    "new_cases_per_million": "",
    "new_cases_smoothed_per_million": "",
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": "",
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": "",
    "population_density": "",
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": "",
    "extreme_poverty": "",
    "cardiovasc_death_rate": "",
    "diabetes_prevalence": "",
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": "",
    "life_expectancy": "",
    "human_development_index": ""
  },
  {
    "iso_code": "IRN",
    "continent": "Asia",
    "location": "Iran",
    "total_cases": 1225142,
    "new_cases": 6389,
    "new_cases_smoothed": 5994.286,
    "total_deaths": 55223,
    "new_deaths": 128,
    "new_deaths_smoothed": 130.714,
    "total_cases_per_million": 14586.247,
    "new_cases_per_million": 76.066,
    "new_cases_smoothed_per_million": 71.367,
    "total_deaths_per_million": 657.472,
    "new_deaths_per_million": 1.524,
    "new_deaths_smoothed_per_million": 1.556,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 53721,
    "total_tests": 7620667,
    "total_tests_per_thousand": 90.73,
    "new_tests_per_thousand": 0.64,
    "new_tests_smoothed": 52520,
    "new_tests_smoothed_per_thousand": 0.625,
    "positive_rate": 0.114,
    "tests_per_case": 8.8,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 83992953,
    "population_density": 49.831,
    "median_age": 32.4,
    "aged_65_older": 5.44,
    "aged_70_older": 3.182,
    "gdp_per_capita": 19082.62,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 270.308,
    "diabetes_prevalence": 9.59,
    "female_smokers": 0.8,
    "male_smokers": 21.1,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 1.5,
    "life_expectancy": 76.68,
    "human_development_index": 0.798
  },
  {
    "iso_code": "IRQ",
    "continent": "Asia",
    "location": "Iraq",
    "total_cases": 595291,
    "new_cases": 849,
    "new_cases_smoothed": 926.857,
    "total_deaths": 12813,
    "new_deaths": 5,
    "new_deaths_smoothed": 9.857,
    "total_cases_per_million": 14799.949,
    "new_cases_per_million": 21.108,
    "new_cases_smoothed_per_million": 23.043,
    "total_deaths_per_million": 318.553,
    "new_deaths_per_million": 0.124,
    "new_deaths_smoothed_per_million": 0.245,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 37231,
    "total_tests": 4511833,
    "total_tests_per_thousand": 112.172,
    "new_tests_per_thousand": 0.926,
    "new_tests_smoothed": 36311,
    "new_tests_smoothed_per_thousand": 0.903,
    "positive_rate": 0.027,
    "tests_per_case": 37.6,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 48.15,
    "population": 40222503,
    "population_density": 88.125,
    "median_age": 20,
    "aged_65_older": 3.186,
    "aged_70_older": 1.957,
    "gdp_per_capita": 15663.986,
    "extreme_poverty": 2.5,
    "cardiovasc_death_rate": 218.612,
    "diabetes_prevalence": 8.83,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 94.576,
    "hospital_beds_per_thousand": 1.4,
    "life_expectancy": 70.6,
    "human_development_index": 0.685
  },
  {
    "iso_code": "IRL",
    "continent": "Europe",
    "location": "Ireland",
    "total_cases": 91779,
    "new_cases": 1622,
    "new_cases_smoothed": 1243.714,
    "total_deaths": 2237,
    "new_deaths": 11,
    "new_deaths_smoothed": 6.429,
    "total_cases_per_million": 18587.038,
    "new_cases_per_million": 328.487,
    "new_cases_smoothed_per_million": 251.876,
    "total_deaths_per_million": 453.036,
    "new_deaths_per_million": 2.228,
    "new_deaths_smoothed_per_million": 1.302,
    "reproduction_rate": "",
    "icu_patients": 27,
    "icu_patients_per_million": 5.468,
    "hosp_patients": 321,
    "hosp_patients_per_million": 65.009,
    "weekly_icu_admissions": 16.109,
    "weekly_icu_admissions_per_million": 3.262,
    "weekly_hosp_admissions": 121.828,
    "weekly_hosp_admissions_per_million": 24.673,
    "new_tests": 26238,
    "total_tests": 2374694,
    "total_tests_per_thousand": 480.922,
    "new_tests_per_thousand": 5.314,
    "new_tests_smoothed": 14840,
    "new_tests_smoothed_per_thousand": 3.005,
    "positive_rate": 0.084,
    "tests_per_case": 11.9,
    "tests_units": "tests performed",
    "total_vaccinations": 1800,
    "total_vaccinations_per_hundred": 0.04,
    "stringency_index": 75,
    "population": 4937796,
    "population_density": 69.874,
    "median_age": 38.7,
    "aged_65_older": 13.928,
    "aged_70_older": 8.678,
    "gdp_per_capita": 67335.293,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 126.459,
    "diabetes_prevalence": 3.28,
    "female_smokers": 23,
    "male_smokers": 25.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.96,
    "life_expectancy": 82.3,
    "human_development_index": 0.938
  },
  {
    "iso_code": "ISR",
    "continent": "Asia",
    "location": "Israel",
    "total_cases": 423262,
    "new_cases": 6678,
    "new_cases_smoothed": 4797.714,
    "total_deaths": 3325,
    "new_deaths": 18,
    "new_deaths_smoothed": 22,
    "total_cases_per_million": 48900.698,
    "new_cases_per_million": 771.529,
    "new_cases_smoothed_per_million": 554.294,
    "total_deaths_per_million": 384.147,
    "new_deaths_per_million": 2.08,
    "new_deaths_smoothed_per_million": 2.542,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 87029,
    "total_tests": 7838938,
    "total_tests_per_thousand": 905.655,
    "new_tests_per_thousand": 10.055,
    "new_tests_smoothed": 87759,
    "new_tests_smoothed_per_thousand": 10.139,
    "positive_rate": 0.039,
    "tests_per_case": 25.3,
    "tests_units": "tests performed",
    "total_vaccinations": 1000000,
    "total_vaccinations_per_hundred": 11.55,
    "stringency_index": 74.07,
    "population": 8655541,
    "population_density": 402.606,
    "median_age": 30.6,
    "aged_65_older": 11.733,
    "aged_70_older": 7.359,
    "gdp_per_capita": 33132.32,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 93.32,
    "diabetes_prevalence": 6.74,
    "female_smokers": 15.4,
    "male_smokers": 35.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.99,
    "life_expectancy": 82.97,
    "human_development_index": 0.903
  },
  {
    "iso_code": "ITA",
    "continent": "Europe",
    "location": "Italy",
    "total_cases": 2107166,
    "new_cases": 23477,
    "new_cases_smoothed": 13978.429,
    "total_deaths": 74159,
    "new_deaths": 555,
    "new_deaths_smoothed": 465.571,
    "total_cases_per_million": 34851.179,
    "new_cases_per_million": 388.295,
    "new_cases_smoothed_per_million": 231.194,
    "total_deaths_per_million": 1226.542,
    "new_deaths_per_million": 9.179,
    "new_deaths_smoothed_per_million": 7.7,
    "reproduction_rate": "",
    "icu_patients": 2580,
    "icu_patients_per_million": 42.672,
    "hosp_patients": 26151,
    "hosp_patients_per_million": 432.521,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": 3630.141,
    "weekly_hosp_admissions_per_million": 60.04,
    "new_tests": 186004,
    "total_tests": 26598607,
    "total_tests_per_thousand": 439.924,
    "new_tests_per_thousand": 3.076,
    "new_tests_smoothed": 120892,
    "new_tests_smoothed_per_thousand": 1.999,
    "positive_rate": 0.116,
    "tests_per_case": 8.6,
    "tests_units": "tests performed",
    "total_vaccinations": 35850,
    "total_vaccinations_per_hundred": 0.06,
    "stringency_index": "",
    "population": 60461828,
    "population_density": 205.859,
    "median_age": 47.9,
    "aged_65_older": 23.021,
    "aged_70_older": 16.24,
    "gdp_per_capita": 35220.084,
    "extreme_poverty": 2,
    "cardiovasc_death_rate": 113.151,
    "diabetes_prevalence": 4.78,
    "female_smokers": 19.8,
    "male_smokers": 27.8,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.18,
    "life_expectancy": 83.51,
    "human_development_index": 0.88
  },
  {
    "iso_code": "JAM",
    "continent": "North America",
    "location": "Jamaica",
    "total_cases": 12827,
    "new_cases": 34,
    "new_cases_smoothed": 44,
    "total_deaths": 302,
    "new_deaths": 0,
    "new_deaths_smoothed": 1.429,
    "total_cases_per_million": 4331.747,
    "new_cases_per_million": 11.482,
    "new_cases_smoothed_per_million": 14.859,
    "total_deaths_per_million": 101.987,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.482,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 1113,
    "total_tests": 138342,
    "total_tests_per_thousand": 46.719,
    "new_tests_per_thousand": 0.376,
    "new_tests_smoothed": 453,
    "new_tests_smoothed_per_thousand": 0.153,
    "positive_rate": 0.117,
    "tests_per_case": 8.6,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 2961161,
    "population_density": 266.879,
    "median_age": 31.4,
    "aged_65_older": 9.684,
    "aged_70_older": 6.39,
    "gdp_per_capita": 8193.571,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 206.537,
    "diabetes_prevalence": 11.28,
    "female_smokers": 5.3,
    "male_smokers": 28.6,
    "handwashing_facilities": 66.425,
    "hospital_beds_per_thousand": 1.7,
    "life_expectancy": 74.47,
    "human_development_index": 0.732
  },
  {
    "iso_code": "JPN",
    "continent": "Asia",
    "location": "Japan",
    "total_cases": 235811,
    "new_cases": 4540,
    "new_cases_smoothed": 3577.429,
    "total_deaths": 3292,
    "new_deaths": 49,
    "new_deaths_smoothed": 46.429,
    "total_cases_per_million": 1864.466,
    "new_cases_per_million": 35.896,
    "new_cases_smoothed_per_million": 28.285,
    "total_deaths_per_million": 26.029,
    "new_deaths_per_million": 0.387,
    "new_deaths_smoothed_per_million": 0.367,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 39575,
    "total_tests": 4486717,
    "total_tests_per_thousand": 35.475,
    "new_tests_per_thousand": 0.313,
    "new_tests_smoothed": 37825,
    "new_tests_smoothed_per_thousand": 0.299,
    "positive_rate": 0.095,
    "tests_per_case": 10.6,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 53.7,
    "population": 126476458,
    "population_density": 347.778,
    "median_age": 48.2,
    "aged_65_older": 27.049,
    "aged_70_older": 18.493,
    "gdp_per_capita": 39002.223,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 79.37,
    "diabetes_prevalence": 5.72,
    "female_smokers": 11.2,
    "male_smokers": 33.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 13.05,
    "life_expectancy": 84.63,
    "human_development_index": 0.909
  },
  {
    "iso_code": "JOR",
    "continent": "Asia",
    "location": "Jordan",
    "total_cases": 294494,
    "new_cases": 1427,
    "new_cases_smoothed": 1543.429,
    "total_deaths": 3834,
    "new_deaths": 19,
    "new_deaths_smoothed": 21.857,
    "total_cases_per_million": 28863.075,
    "new_cases_per_million": 139.859,
    "new_cases_smoothed_per_million": 151.27,
    "total_deaths_per_million": 375.767,
    "new_deaths_per_million": 1.862,
    "new_deaths_smoothed_per_million": 2.142,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 25933,
    "total_tests": 3152125,
    "total_tests_per_thousand": 308.937,
    "new_tests_per_thousand": 2.542,
    "new_tests_smoothed": 18500,
    "new_tests_smoothed_per_thousand": 1.813,
    "positive_rate": 0.086,
    "tests_per_case": 11.7,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 78.7,
    "population": 10203140,
    "population_density": 109.285,
    "median_age": 23.2,
    "aged_65_older": 3.81,
    "aged_70_older": 2.361,
    "gdp_per_capita": 8337.49,
    "extreme_poverty": 0.1,
    "cardiovasc_death_rate": 208.257,
    "diabetes_prevalence": 11.75,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 1.4,
    "life_expectancy": 74.53,
    "human_development_index": 0.735
  },
  {
    "iso_code": "KAZ",
    "continent": "Asia",
    "location": "Kazakhstan",
    "total_cases": 201196,
    "new_cases": 753,
    "new_cases_smoothed": 886.143,
    "total_deaths": 2761,
    "new_deaths": 0,
    "new_deaths_smoothed": 12,
    "total_cases_per_million": 10715.191,
    "new_cases_per_million": 40.103,
    "new_cases_smoothed_per_million": 47.194,
    "total_deaths_per_million": 147.044,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.639,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 33627,
    "total_tests": 5250104,
    "total_tests_per_thousand": 279.607,
    "new_tests_per_thousand": 1.791,
    "new_tests_smoothed": 28919,
    "new_tests_smoothed_per_thousand": 1.54,
    "positive_rate": 0.028,
    "tests_per_case": 36.1,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 71.76,
    "population": 18776707,
    "population_density": 6.681,
    "median_age": 30.6,
    "aged_65_older": 6.991,
    "aged_70_older": 4.625,
    "gdp_per_capita": 24055.588,
    "extreme_poverty": 0.1,
    "cardiovasc_death_rate": 466.792,
    "diabetes_prevalence": 7.11,
    "female_smokers": 7,
    "male_smokers": 43.1,
    "handwashing_facilities": 98.999,
    "hospital_beds_per_thousand": 6.7,
    "life_expectancy": 73.6,
    "human_development_index": 0.8
  },
  {
    "iso_code": "KEN",
    "continent": "Africa",
    "location": "Kenya",
    "total_cases": 96458,
    "new_cases": 207,
    "new_cases_smoothed": 146.714,
    "total_deaths": 1670,
    "new_deaths": 3,
    "new_deaths_smoothed": 2.571,
    "total_cases_per_million": 1793.857,
    "new_cases_per_million": 3.85,
    "new_cases_smoothed_per_million": 2.728,
    "total_deaths_per_million": 31.057,
    "new_deaths_per_million": 0.056,
    "new_deaths_smoothed_per_million": 0.048,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": 1041679,
    "total_tests_per_thousand": 19.372,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": 3819,
    "new_tests_smoothed_per_thousand": 0.071,
    "positive_rate": 0.04,
    "tests_per_case": 25.3,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 62.96,
    "population": 53771300,
    "population_density": 87.324,
    "median_age": 20,
    "aged_65_older": 2.686,
    "aged_70_older": 1.528,
    "gdp_per_capita": 2993.028,
    "extreme_poverty": 36.8,
    "cardiovasc_death_rate": 218.637,
    "diabetes_prevalence": 2.92,
    "female_smokers": 1.2,
    "male_smokers": 20.4,
    "handwashing_facilities": 24.651,
    "hospital_beds_per_thousand": 1.4,
    "life_expectancy": 66.7,
    "human_development_index": 0.59
  },
  {
    "iso_code": "OWID_KOS",
    "continent": "Europe",
    "location": "Kosovo",
    "total_cases": 51144,
    "new_cases": 234,
    "new_cases_smoothed": 243.429,
    "total_deaths": 1332,
    "new_deaths": 7,
    "new_deaths_smoothed": 8.286,
    "total_cases_per_million": 26461.449,
    "new_cases_per_million": 121.07,
    "new_cases_smoothed_per_million": 125.948,
    "total_deaths_per_million": 689.165,
    "new_deaths_per_million": 3.622,
    "new_deaths_smoothed_per_million": 4.287,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 74.07,
    "population": 1932774,
    "population_density": 168.155,
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": 9795.834,
    "extreme_poverty": 0.6,
    "cardiovasc_death_rate": "",
    "diabetes_prevalence": "",
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": "",
    "life_expectancy": "",
    "human_development_index": ""
  },
  {
    "iso_code": "KWT",
    "continent": "Asia",
    "location": "Kuwait",
    "total_cases": 150584,
    "new_cases": 286,
    "new_cases_smoothed": 223.857,
    "total_deaths": 934,
    "new_deaths": 1,
    "new_deaths_smoothed": 1.143,
    "total_cases_per_million": 35260.925,
    "new_cases_per_million": 66.97,
    "new_cases_smoothed_per_million": 52.419,
    "total_deaths_per_million": 218.707,
    "new_deaths_per_million": 0.234,
    "new_deaths_smoothed_per_million": 0.268,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 2429,
    "total_tests": 1251543,
    "total_tests_per_thousand": 293.063,
    "new_tests_per_thousand": 0.569,
    "new_tests_smoothed": 2752,
    "new_tests_smoothed_per_thousand": 0.644,
    "positive_rate": 0.079,
    "tests_per_case": 12.6,
    "tests_units": "tests performed",
    "total_vaccinations": 2500,
    "total_vaccinations_per_hundred": 0.06,
    "stringency_index": "",
    "population": 4270563,
    "population_density": 232.128,
    "median_age": 33.7,
    "aged_65_older": 2.345,
    "aged_70_older": 1.114,
    "gdp_per_capita": 65530.537,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 132.235,
    "diabetes_prevalence": 15.84,
    "female_smokers": 2.7,
    "male_smokers": 37,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2,
    "life_expectancy": 75.49,
    "human_development_index": 0.803
  },
  {
    "iso_code": "KGZ",
    "continent": "Asia",
    "location": "Kyrgyzstan",
    "total_cases": 81034,
    "new_cases": 191,
    "new_cases_smoothed": 169.857,
    "total_deaths": 1355,
    "new_deaths": 1,
    "new_deaths_smoothed": 2.143,
    "total_cases_per_million": 12420.544,
    "new_cases_per_million": 29.276,
    "new_cases_smoothed_per_million": 26.035,
    "total_deaths_per_million": 207.689,
    "new_deaths_per_million": 0.153,
    "new_deaths_smoothed_per_million": 0.328,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 51.85,
    "population": 6524191,
    "population_density": 32.333,
    "median_age": 26.3,
    "aged_65_older": 4.489,
    "aged_70_older": 2.882,
    "gdp_per_capita": 3393.474,
    "extreme_poverty": 1.4,
    "cardiovasc_death_rate": 436.362,
    "diabetes_prevalence": 7.11,
    "female_smokers": 3.6,
    "male_smokers": 50.5,
    "handwashing_facilities": 89.22,
    "hospital_beds_per_thousand": 4.5,
    "life_expectancy": 71.45,
    "human_development_index": 0.672
  },
  {
    "iso_code": "LAO",
    "continent": "Asia",
    "location": "Laos",
    "total_cases": 41,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 5.635,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 29.63,
    "population": 7275556,
    "population_density": 29.715,
    "median_age": 24.4,
    "aged_65_older": 4.029,
    "aged_70_older": 2.322,
    "gdp_per_capita": 6397.36,
    "extreme_poverty": 22.7,
    "cardiovasc_death_rate": 368.111,
    "diabetes_prevalence": 4,
    "female_smokers": 7.3,
    "male_smokers": 51.2,
    "handwashing_facilities": 49.839,
    "hospital_beds_per_thousand": 1.5,
    "life_expectancy": 67.92,
    "human_development_index": 0.601
  },
  {
    "iso_code": "LVA",
    "continent": "Europe",
    "location": "Latvia",
    "total_cases": 40904,
    "new_cases": 1861,
    "new_cases_smoothed": 878.571,
    "total_deaths": 635,
    "new_deaths": 9,
    "new_deaths_smoothed": 21.286,
    "total_cases_per_million": 21685.906,
    "new_cases_per_million": 986.639,
    "new_cases_smoothed_per_million": 465.789,
    "total_deaths_per_million": 336.655,
    "new_deaths_per_million": 4.771,
    "new_deaths_smoothed_per_million": 11.285,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": 1010,
    "hosp_patients_per_million": 535.468,
    "weekly_icu_admissions": 90.382,
    "weekly_icu_admissions_per_million": 47.917,
    "weekly_hosp_admissions": 1487.374,
    "weekly_hosp_admissions_per_million": 788.555,
    "new_tests": 12395,
    "total_tests": 877015,
    "total_tests_per_thousand": 464.963,
    "new_tests_per_thousand": 6.571,
    "new_tests_smoothed": 7442,
    "new_tests_smoothed_per_thousand": 3.945,
    "positive_rate": 0.118,
    "tests_per_case": 8.5,
    "tests_units": "tests performed",
    "total_vaccinations": 573,
    "total_vaccinations_per_hundred": 0.03,
    "stringency_index": "",
    "population": 1886202,
    "population_density": 31.212,
    "median_age": 43.9,
    "aged_65_older": 19.754,
    "aged_70_older": 14.136,
    "gdp_per_capita": 25063.846,
    "extreme_poverty": 0.7,
    "cardiovasc_death_rate": 350.06,
    "diabetes_prevalence": 4.91,
    "female_smokers": 25.6,
    "male_smokers": 51,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 5.57,
    "life_expectancy": 75.29,
    "human_development_index": 0.847
  },
  {
    "iso_code": "LBN",
    "continent": "Asia",
    "location": "Lebanon",
    "total_cases": 181503,
    "new_cases": 3507,
    "new_cases_smoothed": 2224.286,
    "total_deaths": 1468,
    "new_deaths": 12,
    "new_deaths_smoothed": 16.429,
    "total_cases_per_million": 26592.124,
    "new_cases_per_million": 513.813,
    "new_cases_smoothed_per_million": 325.882,
    "total_deaths_per_million": 215.078,
    "new_deaths_per_million": 1.758,
    "new_deaths_smoothed_per_million": 2.407,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 87.04,
    "population": 6825442,
    "population_density": 594.561,
    "median_age": 31.1,
    "aged_65_older": 8.514,
    "aged_70_older": 5.43,
    "gdp_per_capita": 13367.565,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 266.591,
    "diabetes_prevalence": 12.71,
    "female_smokers": 26.9,
    "male_smokers": 40.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.9,
    "life_expectancy": 78.93,
    "human_development_index": 0.757
  },
  {
    "iso_code": "LSO",
    "continent": "Africa",
    "location": "Lesotho",
    "total_cases": 3094,
    "new_cases": 0,
    "new_cases_smoothed": 52.714,
    "total_deaths": 51,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 1444.275,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 24.607,
    "total_deaths_per_million": 23.807,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 36.11,
    "population": 2142252,
    "population_density": 73.562,
    "median_age": 22.2,
    "aged_65_older": 4.506,
    "aged_70_older": 2.647,
    "gdp_per_capita": 2851.153,
    "extreme_poverty": 59.6,
    "cardiovasc_death_rate": 405.126,
    "diabetes_prevalence": 3.94,
    "female_smokers": 0.4,
    "male_smokers": 53.9,
    "handwashing_facilities": 2.117,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 54.33,
    "human_development_index": 0.52
  },
  {
    "iso_code": "LBR",
    "continent": "Africa",
    "location": "Liberia",
    "total_cases": 1779,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": 83,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 351.743,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": 16.411,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 50,
    "population": 5057677,
    "population_density": 49.127,
    "median_age": 19.2,
    "aged_65_older": 3.057,
    "aged_70_older": 1.756,
    "gdp_per_capita": 752.788,
    "extreme_poverty": 38.6,
    "cardiovasc_death_rate": 272.509,
    "diabetes_prevalence": 2.42,
    "female_smokers": 1.5,
    "male_smokers": 18.1,
    "handwashing_facilities": 1.188,
    "hospital_beds_per_thousand": 0.8,
    "life_expectancy": 64.1,
    "human_development_index": 0.435
  },
  {
    "iso_code": "LBY",
    "continent": "Africa",
    "location": "Libya",
    "total_cases": 100277,
    "new_cases": 342,
    "new_cases_smoothed": 440.714,
    "total_deaths": 1478,
    "new_deaths": 19,
    "new_deaths_smoothed": 11.286,
    "total_cases_per_million": 14593.627,
    "new_cases_per_million": 49.772,
    "new_cases_smoothed_per_million": 64.139,
    "total_deaths_per_million": 215.098,
    "new_deaths_per_million": 2.765,
    "new_deaths_smoothed_per_million": 1.642,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 2624,
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": 0.382,
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 82.41,
    "population": 6871287,
    "population_density": 3.623,
    "median_age": 29,
    "aged_65_older": 4.424,
    "aged_70_older": 2.816,
    "gdp_per_capita": 17881.509,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 341.862,
    "diabetes_prevalence": 10.43,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.7,
    "life_expectancy": 72.91,
    "human_development_index": 0.706
  },
  {
    "iso_code": "LIE",
    "continent": "Europe",
    "location": "Liechtenstein",
    "total_cases": 2136,
    "new_cases": 32,
    "new_cases_smoothed": 30.857,
    "total_deaths": 39,
    "new_deaths": 0,
    "new_deaths_smoothed": 1.286,
    "total_cases_per_million": 56008.601,
    "new_cases_per_million": 839.08,
    "new_cases_smoothed_per_million": 809.113,
    "total_deaths_per_million": 1022.629,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 33.713,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 38137,
    "population_density": 237.012,
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": "",
    "extreme_poverty": "",
    "cardiovasc_death_rate": "",
    "diabetes_prevalence": 7.77,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.397,
    "life_expectancy": 82.49,
    "human_development_index": 0.916
  },
  {
    "iso_code": "LTU",
    "continent": "Europe",
    "location": "Lithuania",
    "total_cases": 140579,
    "new_cases": 2360,
    "new_cases_smoothed": 2384.286,
    "total_deaths": 1458,
    "new_deaths": 36,
    "new_deaths_smoothed": 38.857,
    "total_cases_per_million": 51639.961,
    "new_cases_per_million": 866.917,
    "new_cases_smoothed_per_million": 875.838,
    "total_deaths_per_million": 535.578,
    "new_deaths_per_million": 13.224,
    "new_deaths_smoothed_per_million": 14.274,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": 2510,
    "hosp_patients_per_million": 922.018,
    "weekly_icu_admissions": 120.81,
    "weekly_icu_admissions_per_million": 44.378,
    "weekly_hosp_admissions": 597.228,
    "weekly_hosp_admissions_per_million": 219.384,
    "new_tests": 11745,
    "total_tests": 1639941,
    "total_tests_per_thousand": 602.412,
    "new_tests_per_thousand": 4.314,
    "new_tests_smoothed": 9735,
    "new_tests_smoothed_per_thousand": 3.576,
    "positive_rate": 0.245,
    "tests_per_case": 4.1,
    "tests_units": "tests performed",
    "total_vaccinations": 2270,
    "total_vaccinations_per_hundred": 0.08,
    "stringency_index": 82.41,
    "population": 2722291,
    "population_density": 45.135,
    "median_age": 43.5,
    "aged_65_older": 19.002,
    "aged_70_older": 13.778,
    "gdp_per_capita": 29524.265,
    "extreme_poverty": 0.7,
    "cardiovasc_death_rate": 342.989,
    "diabetes_prevalence": 3.67,
    "female_smokers": 21.3,
    "male_smokers": 38,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 6.56,
    "life_expectancy": 75.93,
    "human_development_index": 0.858
  },
  {
    "iso_code": "LUX",
    "continent": "Europe",
    "location": "Luxembourg",
    "total_cases": 46415,
    "new_cases": 0,
    "new_cases_smoothed": 172.286,
    "total_deaths": 495,
    "new_deaths": 0,
    "new_deaths_smoothed": 5.286,
    "total_cases_per_million": 74148.21,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 275.227,
    "total_deaths_per_million": 790.765,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 8.444,
    "reproduction_rate": "",
    "icu_patients": 32,
    "icu_patients_per_million": 51.12,
    "hosp_patients": 150,
    "hosp_patients_per_million": 239.626,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 8850,
    "total_tests": 1666224,
    "total_tests_per_thousand": 2661.802,
    "new_tests_per_thousand": 14.138,
    "new_tests_smoothed": 5697,
    "new_tests_smoothed_per_thousand": 9.101,
    "positive_rate": 0.032,
    "tests_per_case": 31.2,
    "tests_units": "tests performed",
    "total_vaccinations": 1200,
    "total_vaccinations_per_hundred": 0.19,
    "stringency_index": "",
    "population": 625976,
    "population_density": 231.447,
    "median_age": 39.7,
    "aged_65_older": 14.312,
    "aged_70_older": 9.842,
    "gdp_per_capita": 94277.965,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 128.275,
    "diabetes_prevalence": 4.42,
    "female_smokers": 20.9,
    "male_smokers": 26,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 4.51,
    "life_expectancy": 82.25,
    "human_development_index": 0.904
  },
  {
    "iso_code": "MDG",
    "continent": "Africa",
    "location": "Madagascar",
    "total_cases": 17714,
    "new_cases": 0,
    "new_cases_smoothed": 11.571,
    "total_deaths": 261,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 639.702,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0.418,
    "total_deaths_per_million": 9.425,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.005,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": 100305,
    "total_tests_per_thousand": 3.622,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": 242,
    "new_tests_smoothed_per_thousand": 0.009,
    "positive_rate": 0.027,
    "tests_per_case": 36.8,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 37.04,
    "population": 27691019,
    "population_density": 43.951,
    "median_age": 19.6,
    "aged_65_older": 2.929,
    "aged_70_older": 1.686,
    "gdp_per_capita": 1416.44,
    "extreme_poverty": 77.6,
    "cardiovasc_death_rate": 405.994,
    "diabetes_prevalence": 3.94,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 50.54,
    "hospital_beds_per_thousand": 0.2,
    "life_expectancy": 67.04,
    "human_development_index": 0.519
  },
  {
    "iso_code": "MWI",
    "continent": "Africa",
    "location": "Malawi",
    "total_cases": 6583,
    "new_cases": 112,
    "new_cases_smoothed": 43.714,
    "total_deaths": 189,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 344.12,
    "new_cases_per_million": 5.855,
    "new_cases_smoothed_per_million": 2.285,
    "total_deaths_per_million": 9.88,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.015,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": 84352,
    "total_tests_per_thousand": 4.409,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": 261,
    "new_tests_smoothed_per_thousand": 0.014,
    "positive_rate": 0.167,
    "tests_per_case": 6,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 50.93,
    "population": 19129955,
    "population_density": 197.519,
    "median_age": 18.1,
    "aged_65_older": 2.979,
    "aged_70_older": 1.783,
    "gdp_per_capita": 1095.042,
    "extreme_poverty": 71.4,
    "cardiovasc_death_rate": 227.349,
    "diabetes_prevalence": 3.94,
    "female_smokers": 4.4,
    "male_smokers": 24.7,
    "handwashing_facilities": 8.704,
    "hospital_beds_per_thousand": 1.3,
    "life_expectancy": 64.26,
    "human_development_index": 0.477
  },
  {
    "iso_code": "MYS",
    "continent": "Asia",
    "location": "Malaysia",
    "total_cases": 113010,
    "new_cases": 2525,
    "new_cases_smoothed": 1813.143,
    "total_deaths": 471,
    "new_deaths": 8,
    "new_deaths_smoothed": 3.571,
    "total_cases_per_million": 3491.627,
    "new_cases_per_million": 78.014,
    "new_cases_smoothed_per_million": 56.02,
    "total_deaths_per_million": 14.552,
    "new_deaths_per_million": 0.247,
    "new_deaths_smoothed_per_million": 0.11,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 25812,
    "total_tests": 3317646,
    "total_tests_per_thousand": 102.504,
    "new_tests_per_thousand": 0.798,
    "new_tests_smoothed": 21072,
    "new_tests_smoothed_per_thousand": 0.651,
    "positive_rate": 0.08,
    "tests_per_case": 12.6,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 67.13,
    "population": 32365998,
    "population_density": 96.254,
    "median_age": 29.9,
    "aged_65_older": 6.293,
    "aged_70_older": 3.407,
    "gdp_per_capita": 26808.164,
    "extreme_poverty": 0.1,
    "cardiovasc_death_rate": 260.942,
    "diabetes_prevalence": 16.74,
    "female_smokers": 1,
    "male_smokers": 42.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 1.9,
    "life_expectancy": 76.16,
    "human_development_index": 0.802
  },
  {
    "iso_code": "MDV",
    "continent": "Asia",
    "location": "Maldives",
    "total_cases": 13757,
    "new_cases": 19,
    "new_cases_smoothed": 28.429,
    "total_deaths": 48,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 25450.381,
    "new_cases_per_million": 35.15,
    "new_cases_smoothed_per_million": 52.593,
    "total_deaths_per_million": 88.8,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 558,
    "total_tests": 259020,
    "total_tests_per_thousand": 479.186,
    "new_tests_per_thousand": 1.032,
    "new_tests_smoothed": 519,
    "new_tests_smoothed_per_thousand": 0.96,
    "positive_rate": 0.055,
    "tests_per_case": 18.1,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 540542,
    "population_density": 1454.433,
    "median_age": 30.6,
    "aged_65_older": 4.12,
    "aged_70_older": 2.875,
    "gdp_per_capita": 15183.616,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 164.905,
    "diabetes_prevalence": 9.19,
    "female_smokers": 2.1,
    "male_smokers": 55,
    "handwashing_facilities": 95.803,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 78.92,
    "human_development_index": 0.717
  },
  {
    "iso_code": "MLI",
    "continent": "Africa",
    "location": "Mali",
    "total_cases": 7090,
    "new_cases": 61,
    "new_cases_smoothed": 92.714,
    "total_deaths": 269,
    "new_deaths": 0,
    "new_deaths_smoothed": 4.857,
    "total_cases_per_million": 350.109,
    "new_cases_per_million": 3.012,
    "new_cases_smoothed_per_million": 4.578,
    "total_deaths_per_million": 13.283,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.24,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 35.19,
    "population": 20250834,
    "population_density": 15.196,
    "median_age": 16.4,
    "aged_65_older": 2.519,
    "aged_70_older": 1.486,
    "gdp_per_capita": 2014.306,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 268.024,
    "diabetes_prevalence": 2.42,
    "female_smokers": 1.6,
    "male_smokers": 23,
    "handwashing_facilities": 52.232,
    "hospital_beds_per_thousand": 0.1,
    "life_expectancy": 59.31,
    "human_development_index": 0.427
  },
  {
    "iso_code": "MLT",
    "continent": "Europe",
    "location": "Malta",
    "total_cases": 12774,
    "new_cases": 109,
    "new_cases_smoothed": 101.714,
    "total_deaths": 219,
    "new_deaths": 3,
    "new_deaths_smoothed": 2.286,
    "total_cases_per_million": 28930.627,
    "new_cases_per_million": 246.864,
    "new_cases_smoothed_per_million": 230.363,
    "total_deaths_per_million": 495.992,
    "new_deaths_per_million": 6.794,
    "new_deaths_smoothed_per_million": 5.177,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": 21.47,
    "weekly_hosp_admissions_per_million": 48.626,
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 52.78,
    "population": 441539,
    "population_density": 1454.037,
    "median_age": 42.4,
    "aged_65_older": 19.426,
    "aged_70_older": 11.324,
    "gdp_per_capita": 36513.323,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 168.711,
    "diabetes_prevalence": 8.83,
    "female_smokers": 20.9,
    "male_smokers": 30.2,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 4.485,
    "life_expectancy": 82.53,
    "human_development_index": 0.878
  },
  {
    "iso_code": "MHL",
    "continent": "Oceania",
    "location": "Marshall Islands",
    "total_cases": 4,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 67.574,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 59194,
    "population_density": 295.15,
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": 3819.202,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 557.793,
    "diabetes_prevalence": 30.53,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 82.502,
    "hospital_beds_per_thousand": 2.7,
    "life_expectancy": 73.7,
    "human_development_index": 0.708
  },
  {
    "iso_code": "MRT",
    "continent": "Africa",
    "location": "Mauritania",
    "total_cases": 14364,
    "new_cases": 173,
    "new_cases_smoothed": 178,
    "total_deaths": 347,
    "new_deaths": 8,
    "new_deaths_smoothed": 6.571,
    "total_cases_per_million": 3089.258,
    "new_cases_per_million": 37.207,
    "new_cases_smoothed_per_million": 38.282,
    "total_deaths_per_million": 74.629,
    "new_deaths_per_million": 1.721,
    "new_deaths_smoothed_per_million": 1.413,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 51.85,
    "population": 4649660,
    "population_density": 4.289,
    "median_age": 20.3,
    "aged_65_older": 3.138,
    "aged_70_older": 1.792,
    "gdp_per_capita": 3597.633,
    "extreme_poverty": 6,
    "cardiovasc_death_rate": 232.347,
    "diabetes_prevalence": 2.42,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 15.95,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 64.92,
    "human_development_index": 0.52
  },
  {
    "iso_code": "MUS",
    "continent": "Africa",
    "location": "Mauritius",
    "total_cases": 527,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": 10,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 414.384,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": 7.863,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 16.67,
    "population": 1271767,
    "population_density": 622.962,
    "median_age": 37.4,
    "aged_65_older": 10.945,
    "aged_70_older": 5.884,
    "gdp_per_capita": 20292.745,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 224.644,
    "diabetes_prevalence": 22.02,
    "female_smokers": 3.2,
    "male_smokers": 40.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.4,
    "life_expectancy": 74.99,
    "human_development_index": 0.79
  },
  {
    "iso_code": "MEX",
    "continent": "North America",
    "location": "Mexico",
    "total_cases": 1426094,
    "new_cases": 12159,
    "new_cases_smoothed": 9075.714,
    "total_deaths": 125807,
    "new_deaths": 910,
    "new_deaths_smoothed": 662.143,
    "total_cases_per_million": 11060.758,
    "new_cases_per_million": 94.305,
    "new_cases_smoothed_per_million": 70.391,
    "total_deaths_per_million": 975.757,
    "new_deaths_per_million": 7.058,
    "new_deaths_smoothed_per_million": 5.136,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 10855,
    "total_tests": 3220988,
    "total_tests_per_thousand": 24.982,
    "new_tests_per_thousand": 0.084,
    "new_tests_smoothed": 12206,
    "new_tests_smoothed_per_thousand": 0.095,
    "positive_rate": 0.423,
    "tests_per_case": 2.4,
    "tests_units": "people tested",
    "total_vaccinations": 24998,
    "total_vaccinations_per_hundred": 0.02,
    "stringency_index": 71.76,
    "population": 128932753,
    "population_density": 66.444,
    "median_age": 29.3,
    "aged_65_older": 6.857,
    "aged_70_older": 4.321,
    "gdp_per_capita": 17336.469,
    "extreme_poverty": 2.5,
    "cardiovasc_death_rate": 152.783,
    "diabetes_prevalence": 13.06,
    "female_smokers": 6.9,
    "male_smokers": 21.4,
    "handwashing_facilities": 87.847,
    "hospital_beds_per_thousand": 1.38,
    "life_expectancy": 75.05,
    "human_development_index": 0.774
  },
  {
    "iso_code": "MDA",
    "continent": "Europe",
    "location": "Moldova",
    "total_cases": 144818,
    "new_cases": 977,
    "new_cases_smoothed": 769,
    "total_deaths": 2985,
    "new_deaths": 25,
    "new_deaths_smoothed": 19.571,
    "total_cases_per_million": 35899.685,
    "new_cases_per_million": 242.194,
    "new_cases_smoothed_per_million": 190.631,
    "total_deaths_per_million": 739.967,
    "new_deaths_per_million": 6.197,
    "new_deaths_smoothed_per_million": 4.852,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 57.41,
    "population": 4033963,
    "population_density": 123.655,
    "median_age": 37.6,
    "aged_65_older": 10.864,
    "aged_70_older": 6.955,
    "gdp_per_capita": 5189.972,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 408.502,
    "diabetes_prevalence": 5.72,
    "female_smokers": 5.9,
    "male_smokers": 44.6,
    "handwashing_facilities": 86.979,
    "hospital_beds_per_thousand": 5.8,
    "life_expectancy": 71.9,
    "human_development_index": 0.7
  },
  {
    "iso_code": "MCO",
    "continent": "Europe",
    "location": "Monaco",
    "total_cases": 875,
    "new_cases": 22,
    "new_cases_smoothed": 13.143,
    "total_deaths": 3,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 22296.402,
    "new_cases_per_million": 560.595,
    "new_cases_smoothed_per_million": 334.901,
    "total_deaths_per_million": 76.445,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 71.3,
    "population": 39244,
    "population_density": 19347.5,
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": "",
    "extreme_poverty": "",
    "cardiovasc_death_rate": "",
    "diabetes_prevalence": 5.46,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 13.8,
    "life_expectancy": 86.75,
    "human_development_index": ""
  },
  {
    "iso_code": "MNG",
    "continent": "Asia",
    "location": "Mongolia",
    "total_cases": 1220,
    "new_cases": 5,
    "new_cases_smoothed": 20.714,
    "total_deaths": 1,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 372.145,
    "new_cases_per_million": 1.525,
    "new_cases_smoothed_per_million": 6.319,
    "total_deaths_per_million": 0.305,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.044,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 75.46,
    "population": 3278292,
    "population_density": 1.98,
    "median_age": 28.6,
    "aged_65_older": 4.031,
    "aged_70_older": 2.421,
    "gdp_per_capita": 11840.846,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 460.043,
    "diabetes_prevalence": 4.82,
    "female_smokers": 5.5,
    "male_smokers": 46.5,
    "handwashing_facilities": 71.18,
    "hospital_beds_per_thousand": 7,
    "life_expectancy": 69.87,
    "human_development_index": 0.741
  },
  {
    "iso_code": "MNE",
    "continent": "Europe",
    "location": "Montenegro",
    "total_cases": 48247,
    "new_cases": 465,
    "new_cases_smoothed": 403.429,
    "total_deaths": 682,
    "new_deaths": 1,
    "new_deaths_smoothed": 4.857,
    "total_cases_per_million": 76818.849,
    "new_cases_per_million": 740.373,
    "new_cases_smoothed_per_million": 642.339,
    "total_deaths_per_million": 1085.88,
    "new_deaths_per_million": 1.592,
    "new_deaths_smoothed_per_million": 7.734,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 628062,
    "population_density": 46.28,
    "median_age": 39.1,
    "aged_65_older": 14.762,
    "aged_70_older": 9.395,
    "gdp_per_capita": 16409.288,
    "extreme_poverty": 1,
    "cardiovasc_death_rate": 387.305,
    "diabetes_prevalence": 10.08,
    "female_smokers": 44,
    "male_smokers": 47.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.861,
    "life_expectancy": 76.88,
    "human_development_index": 0.814
  },
  {
    "iso_code": "MAR",
    "continent": "Africa",
    "location": "Morocco",
    "total_cases": 439193,
    "new_cases": 1861,
    "new_cases_smoothed": 1904.143,
    "total_deaths": 7388,
    "new_deaths": 33,
    "new_deaths_smoothed": 36.857,
    "total_cases_per_million": 11898.845,
    "new_cases_per_million": 50.419,
    "new_cases_smoothed_per_million": 51.588,
    "total_deaths_per_million": 200.16,
    "new_deaths_per_million": 0.894,
    "new_deaths_smoothed_per_million": 0.999,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 17806,
    "total_tests": 4439680,
    "total_tests_per_thousand": 120.282,
    "new_tests_per_thousand": 0.482,
    "new_tests_smoothed": 14389,
    "new_tests_smoothed_per_thousand": 0.39,
    "positive_rate": 0.14,
    "tests_per_case": 7.1,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 66.2,
    "population": 36910558,
    "population_density": 80.08,
    "median_age": 29.6,
    "aged_65_older": 6.769,
    "aged_70_older": 4.209,
    "gdp_per_capita": 7485.013,
    "extreme_poverty": 1,
    "cardiovasc_death_rate": 419.146,
    "diabetes_prevalence": 7.14,
    "female_smokers": 0.8,
    "male_smokers": 47.1,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 1.1,
    "life_expectancy": 76.68,
    "human_development_index": 0.667
  },
  {
    "iso_code": "MOZ",
    "continent": "Africa",
    "location": "Mozambique",
    "total_cases": 18642,
    "new_cases": 157,
    "new_cases_smoothed": 87.286,
    "total_deaths": 166,
    "new_deaths": 1,
    "new_deaths_smoothed": 1.429,
    "total_cases_per_million": 596.44,
    "new_cases_per_million": 5.023,
    "new_cases_smoothed_per_million": 2.793,
    "total_deaths_per_million": 5.311,
    "new_deaths_per_million": 0.032,
    "new_deaths_smoothed_per_million": 0.046,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 1674,
    "total_tests": 270250,
    "total_tests_per_thousand": 8.646,
    "new_tests_per_thousand": 0.054,
    "new_tests_smoothed": 1218,
    "new_tests_smoothed_per_thousand": 0.039,
    "positive_rate": 0.084,
    "tests_per_case": 11.9,
    "tests_units": "units unclear",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 55.56,
    "population": 31255435,
    "population_density": 37.728,
    "median_age": 17.7,
    "aged_65_older": 3.158,
    "aged_70_older": 1.87,
    "gdp_per_capita": 1136.103,
    "extreme_poverty": 62.9,
    "cardiovasc_death_rate": 329.942,
    "diabetes_prevalence": 3.3,
    "female_smokers": 5.1,
    "male_smokers": 29.1,
    "handwashing_facilities": 12.227,
    "hospital_beds_per_thousand": 0.7,
    "life_expectancy": 60.85,
    "human_development_index": 0.437
  },
  {
    "iso_code": "MMR",
    "continent": "Asia",
    "location": "Myanmar",
    "total_cases": 124630,
    "new_cases": 890,
    "new_cases_smoothed": 691.714,
    "total_deaths": 2682,
    "new_deaths": 18,
    "new_deaths_smoothed": 21.429,
    "total_cases_per_million": 2290.58,
    "new_cases_per_million": 16.357,
    "new_cases_smoothed_per_million": 12.713,
    "total_deaths_per_million": 49.293,
    "new_deaths_per_million": 0.331,
    "new_deaths_smoothed_per_million": 0.394,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 17010,
    "total_tests": 1794253,
    "total_tests_per_thousand": 32.977,
    "new_tests_per_thousand": 0.313,
    "new_tests_smoothed": 22157,
    "new_tests_smoothed_per_thousand": 0.407,
    "positive_rate": 0.031,
    "tests_per_case": 31.8,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 75.93,
    "population": 54409794,
    "population_density": 81.721,
    "median_age": 29.1,
    "aged_65_older": 5.732,
    "aged_70_older": 3.12,
    "gdp_per_capita": 5591.597,
    "extreme_poverty": 6.4,
    "cardiovasc_death_rate": 202.104,
    "diabetes_prevalence": 4.61,
    "female_smokers": 6.3,
    "male_smokers": 35.2,
    "handwashing_facilities": 79.287,
    "hospital_beds_per_thousand": 0.9,
    "life_expectancy": 67.13,
    "human_development_index": 0.578
  },
  {
    "iso_code": "NAM",
    "continent": "Africa",
    "location": "Namibia",
    "total_cases": 23941,
    "new_cases": 608,
    "new_cases_smoothed": 480.286,
    "total_deaths": 205,
    "new_deaths": 9,
    "new_deaths_smoothed": 2.571,
    "total_cases_per_million": 9422.193,
    "new_cases_per_million": 239.284,
    "new_cases_smoothed_per_million": 189.021,
    "total_deaths_per_million": 80.68,
    "new_deaths_per_million": 3.542,
    "new_deaths_smoothed_per_million": 1.012,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 2236,
    "total_tests": 206934,
    "total_tests_per_thousand": 81.441,
    "new_tests_per_thousand": 0.88,
    "new_tests_smoothed": 1895,
    "new_tests_smoothed_per_thousand": 0.746,
    "positive_rate": 0.252,
    "tests_per_case": 4,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 2540916,
    "population_density": 3.078,
    "median_age": 22,
    "aged_65_older": 3.552,
    "aged_70_older": 2.085,
    "gdp_per_capita": 9541.808,
    "extreme_poverty": 13.4,
    "cardiovasc_death_rate": 243.811,
    "diabetes_prevalence": 3.94,
    "female_smokers": 9.7,
    "male_smokers": 34.2,
    "handwashing_facilities": 44.6,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 63.71,
    "human_development_index": 0.647
  },
  {
    "iso_code": "NPL",
    "continent": "Asia",
    "location": "Nepal",
    "total_cases": 260593,
    "new_cases": 534,
    "new_cases_smoothed": 571.571,
    "total_deaths": 1856,
    "new_deaths": 9,
    "new_deaths_smoothed": 6.857,
    "total_cases_per_million": 8943.773,
    "new_cases_per_million": 18.327,
    "new_cases_smoothed_per_million": 19.617,
    "total_deaths_per_million": 63.699,
    "new_deaths_per_million": 0.309,
    "new_deaths_smoothed_per_million": 0.235,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 6135,
    "total_tests": 1932477,
    "total_tests_per_thousand": 66.324,
    "new_tests_per_thousand": 0.211,
    "new_tests_smoothed": 5175,
    "new_tests_smoothed_per_thousand": 0.178,
    "positive_rate": 0.11,
    "tests_per_case": 9.1,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 60.19,
    "population": 29136808,
    "population_density": 204.43,
    "median_age": 25,
    "aged_65_older": 5.809,
    "aged_70_older": 3.212,
    "gdp_per_capita": 2442.804,
    "extreme_poverty": 15,
    "cardiovasc_death_rate": 260.797,
    "diabetes_prevalence": 7.26,
    "female_smokers": 9.5,
    "male_smokers": 37.8,
    "handwashing_facilities": 47.782,
    "hospital_beds_per_thousand": 0.3,
    "life_expectancy": 70.78,
    "human_development_index": 0.574
  },
  {
    "iso_code": "NLD",
    "continent": "Europe",
    "location": "Netherlands",
    "total_cases": 808382,
    "new_cases": 9790,
    "new_cases_smoothed": 9269.857,
    "total_deaths": 11525,
    "new_deaths": 108,
    "new_deaths_smoothed": 87.286,
    "total_cases_per_million": 47177.589,
    "new_cases_per_million": 571.349,
    "new_cases_smoothed_per_million": 540.994,
    "total_deaths_per_million": 672.605,
    "new_deaths_per_million": 6.303,
    "new_deaths_smoothed_per_million": 5.094,
    "reproduction_rate": "",
    "icu_patients": 631,
    "icu_patients_per_million": 36.825,
    "hosp_patients": 1797,
    "hosp_patients_per_million": 104.874,
    "weekly_icu_admissions": 276.622,
    "weekly_icu_admissions_per_million": 16.144,
    "weekly_hosp_admissions": 1779.702,
    "weekly_hosp_admissions_per_million": 103.864,
    "new_tests": "",
    "total_tests": 5117007,
    "total_tests_per_thousand": 298.631,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": 27343,
    "new_tests_smoothed_per_thousand": 1.596,
    "positive_rate": 0.136,
    "tests_per_case": 7.4,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 17134873,
    "population_density": 508.544,
    "median_age": 43.2,
    "aged_65_older": 18.779,
    "aged_70_older": 11.881,
    "gdp_per_capita": 48472.545,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 109.361,
    "diabetes_prevalence": 5.29,
    "female_smokers": 24.4,
    "male_smokers": 27.3,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.32,
    "life_expectancy": 82.28,
    "human_development_index": 0.931
  },
  {
    "iso_code": "NZL",
    "continent": "Oceania",
    "location": "New Zealand",
    "total_cases": 2162,
    "new_cases": 0,
    "new_cases_smoothed": 4.857,
    "total_deaths": 25,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 448.34,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 1.007,
    "total_deaths_per_million": 5.184,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 2161,
    "total_tests": 1405854,
    "total_tests_per_thousand": 291.536,
    "new_tests_per_thousand": 0.448,
    "new_tests_smoothed": 2999,
    "new_tests_smoothed_per_thousand": 0.622,
    "positive_rate": 0.002,
    "tests_per_case": 617.5,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 22.22,
    "population": 4822233,
    "population_density": 18.206,
    "median_age": 37.9,
    "aged_65_older": 15.322,
    "aged_70_older": 9.72,
    "gdp_per_capita": 36085.843,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 128.797,
    "diabetes_prevalence": 8.08,
    "female_smokers": 14.8,
    "male_smokers": 17.2,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.61,
    "life_expectancy": 82.29,
    "human_development_index": 0.917
  },
  {
    "iso_code": "NIC",
    "continent": "North America",
    "location": "Nicaragua",
    "total_cases": 6046,
    "new_cases": 0,
    "new_cases_smoothed": 7.857,
    "total_deaths": 165,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 912.665,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 1.186,
    "total_deaths_per_million": 24.907,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.022,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 8.33,
    "population": 6624554,
    "population_density": 51.667,
    "median_age": 27.3,
    "aged_65_older": 5.445,
    "aged_70_older": 3.519,
    "gdp_per_capita": 5321.444,
    "extreme_poverty": 3.2,
    "cardiovasc_death_rate": 137.016,
    "diabetes_prevalence": 11.47,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 0.9,
    "life_expectancy": 74.48,
    "human_development_index": 0.658
  },
  {
    "iso_code": "NER",
    "continent": "Africa",
    "location": "Niger",
    "total_cases": 3323,
    "new_cases": 115,
    "new_cases_smoothed": 55.714,
    "total_deaths": 104,
    "new_deaths": 2,
    "new_deaths_smoothed": 2,
    "total_cases_per_million": 137.276,
    "new_cases_per_million": 4.751,
    "new_cases_smoothed_per_million": 2.302,
    "total_deaths_per_million": 4.296,
    "new_deaths_per_million": 0.083,
    "new_deaths_smoothed_per_million": 0.083,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 24206636,
    "population_density": 16.955,
    "median_age": 15.1,
    "aged_65_older": 2.553,
    "aged_70_older": 1.378,
    "gdp_per_capita": 926,
    "extreme_poverty": 44.5,
    "cardiovasc_death_rate": 238.339,
    "diabetes_prevalence": 2.42,
    "female_smokers": 0.1,
    "male_smokers": 15.4,
    "handwashing_facilities": 8.978,
    "hospital_beds_per_thousand": 0.3,
    "life_expectancy": 62.42,
    "human_development_index": 0.354
  },
  {
    "iso_code": "NGA",
    "continent": "Africa",
    "location": "Nigeria",
    "total_cases": 87607,
    "new_cases": 1031,
    "new_cases_smoothed": 806.286,
    "total_deaths": 1289,
    "new_deaths": 11,
    "new_deaths_smoothed": 6.714,
    "total_cases_per_million": 424.989,
    "new_cases_per_million": 5.001,
    "new_cases_smoothed_per_million": 3.911,
    "total_deaths_per_million": 6.253,
    "new_deaths_per_million": 0.053,
    "new_deaths_smoothed_per_million": 0.033,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 9446,
    "total_tests": 948048,
    "total_tests_per_thousand": 4.599,
    "new_tests_per_thousand": 0.046,
    "new_tests_smoothed": 5133,
    "new_tests_smoothed_per_thousand": 0.025,
    "positive_rate": 0.157,
    "tests_per_case": 6.4,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 50.93,
    "population": 206139587,
    "population_density": 209.588,
    "median_age": 18.1,
    "aged_65_older": 2.751,
    "aged_70_older": 1.447,
    "gdp_per_capita": 5338.454,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 181.013,
    "diabetes_prevalence": 2.42,
    "female_smokers": 0.6,
    "male_smokers": 10.8,
    "handwashing_facilities": 41.949,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 54.69,
    "human_development_index": 0.532
  },
  {
    "iso_code": "MKD",
    "continent": "Europe",
    "location": "North Macedonia",
    "total_cases": 83329,
    "new_cases": 535,
    "new_cases_smoothed": 417.429,
    "total_deaths": 2503,
    "new_deaths": 15,
    "new_deaths_smoothed": 18.286,
    "total_cases_per_million": 39997.024,
    "new_cases_per_million": 256.794,
    "new_cases_smoothed_per_million": 200.361,
    "total_deaths_per_million": 1201.413,
    "new_deaths_per_million": 7.2,
    "new_deaths_smoothed_per_million": 8.777,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 2366,
    "total_tests": 398822,
    "total_tests_per_thousand": 191.43,
    "new_tests_per_thousand": 1.136,
    "new_tests_smoothed": 1840,
    "new_tests_smoothed_per_thousand": 0.883,
    "positive_rate": 0.242,
    "tests_per_case": 4.1,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 2083380,
    "population_density": 82.6,
    "median_age": 39.1,
    "aged_65_older": 13.26,
    "aged_70_older": 8.16,
    "gdp_per_capita": 13111.214,
    "extreme_poverty": 5,
    "cardiovasc_death_rate": 322.688,
    "diabetes_prevalence": 10.08,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 4.28,
    "life_expectancy": 75.8,
    "human_development_index": 0.757
  },
  {
    "iso_code": "NOR",
    "continent": "Europe",
    "location": "Norway",
    "total_cases": 49567,
    "new_cases": 557,
    "new_cases_smoothed": 519.429,
    "total_deaths": 436,
    "new_deaths": 0,
    "new_deaths_smoothed": 2.143,
    "total_cases_per_million": 9143.108,
    "new_cases_per_million": 102.744,
    "new_cases_smoothed_per_million": 95.814,
    "total_deaths_per_million": 80.424,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.395,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": 129,
    "hosp_patients_per_million": 23.795,
    "weekly_icu_admissions": 17.297,
    "weekly_icu_admissions_per_million": 3.191,
    "weekly_hosp_admissions": 81.397,
    "weekly_hosp_admissions_per_million": 15.014,
    "new_tests": 8962,
    "total_tests": 2744599,
    "total_tests_per_thousand": 506.268,
    "new_tests_per_thousand": 1.653,
    "new_tests_smoothed": 15287,
    "new_tests_smoothed_per_thousand": 2.82,
    "positive_rate": 0.029,
    "tests_per_case": 33.9,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 52.78,
    "population": 5421242,
    "population_density": 14.462,
    "median_age": 39.7,
    "aged_65_older": 16.821,
    "aged_70_older": 10.813,
    "gdp_per_capita": 64800.057,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 114.316,
    "diabetes_prevalence": 5.31,
    "female_smokers": 19.6,
    "male_smokers": 20.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.6,
    "life_expectancy": 82.4,
    "human_development_index": 0.953
  },
  {
    "iso_code": "OMN",
    "continent": "Asia",
    "location": "Oman",
    "total_cases": 128867,
    "new_cases": 148,
    "new_cases_smoothed": 82.429,
    "total_deaths": 1499,
    "new_deaths": 2,
    "new_deaths_smoothed": 1.143,
    "total_cases_per_million": 25235.273,
    "new_cases_per_million": 28.982,
    "new_cases_smoothed_per_million": 16.142,
    "total_deaths_per_million": 293.54,
    "new_deaths_per_million": 0.392,
    "new_deaths_smoothed_per_million": 0.224,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": 6516,
    "total_vaccinations_per_hundred": 0.13,
    "stringency_index": 37.04,
    "population": 5106622,
    "population_density": 14.98,
    "median_age": 30.7,
    "aged_65_older": 2.355,
    "aged_70_older": 1.53,
    "gdp_per_capita": 37960.709,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 266.342,
    "diabetes_prevalence": 12.61,
    "female_smokers": 0.5,
    "male_smokers": 15.6,
    "handwashing_facilities": 97.4,
    "hospital_beds_per_thousand": 1.6,
    "life_expectancy": 77.86,
    "human_development_index": 0.821
  },
  {
    "iso_code": "PAK",
    "continent": "Asia",
    "location": "Pakistan",
    "total_cases": 482178,
    "new_cases": 2463,
    "new_cases_smoothed": 2136.571,
    "total_deaths": 10176,
    "new_deaths": 71,
    "new_deaths_smoothed": 60.429,
    "total_cases_per_million": 2182.864,
    "new_cases_per_million": 11.15,
    "new_cases_smoothed_per_million": 9.672,
    "total_deaths_per_million": 46.068,
    "new_deaths_per_million": 0.321,
    "new_deaths_smoothed_per_million": 0.274,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 39695,
    "total_tests": 6696068,
    "total_tests_per_thousand": 30.314,
    "new_tests_per_thousand": 0.18,
    "new_tests_smoothed": 38261,
    "new_tests_smoothed_per_thousand": 0.173,
    "positive_rate": 0.056,
    "tests_per_case": 17.9,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 64.35,
    "population": 220892331,
    "population_density": 255.573,
    "median_age": 23.5,
    "aged_65_older": 4.495,
    "aged_70_older": 2.78,
    "gdp_per_capita": 5034.708,
    "extreme_poverty": 4,
    "cardiovasc_death_rate": 423.031,
    "diabetes_prevalence": 8.35,
    "female_smokers": 2.8,
    "male_smokers": 36.7,
    "handwashing_facilities": 59.607,
    "hospital_beds_per_thousand": 0.6,
    "life_expectancy": 67.27,
    "human_development_index": 0.562
  },
  {
    "iso_code": "PSE",
    "continent": "Asia",
    "location": "Palestine",
    "total_cases": 138004,
    "new_cases": 1268,
    "new_cases_smoothed": 1274.857,
    "total_deaths": 1400,
    "new_deaths": 29,
    "new_deaths_smoothed": 22.429,
    "total_cases_per_million": 27052.097,
    "new_cases_per_million": 248.558,
    "new_cases_smoothed_per_million": 249.903,
    "total_deaths_per_million": 274.434,
    "new_deaths_per_million": 5.685,
    "new_deaths_smoothed_per_million": 4.397,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 5336,
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": 1.046,
    "new_tests_smoothed": 4936,
    "new_tests_smoothed_per_thousand": 0.968,
    "positive_rate": 0.258,
    "tests_per_case": 3.9,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 62.5,
    "population": 5101416,
    "population_density": 778.202,
    "median_age": 20.4,
    "aged_65_older": 3.043,
    "aged_70_older": 1.726,
    "gdp_per_capita": 4449.898,
    "extreme_poverty": 1,
    "cardiovasc_death_rate": 265.91,
    "diabetes_prevalence": 10.59,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": "",
    "life_expectancy": 74.05,
    "human_development_index": 0.686
  },
  {
    "iso_code": "PAN",
    "continent": "North America",
    "location": "Panama",
    "total_cases": 246790,
    "new_cases": 4046,
    "new_cases_smoothed": 3302.286,
    "total_deaths": 4022,
    "new_deaths": 47,
    "new_deaths_smoothed": 43.857,
    "total_cases_per_million": 57196.586,
    "new_cases_per_million": 937.71,
    "new_cases_smoothed_per_million": 765.345,
    "total_deaths_per_million": 932.147,
    "new_deaths_per_million": 10.893,
    "new_deaths_smoothed_per_million": 10.164,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 15791,
    "total_tests": 1288060,
    "total_tests_per_thousand": 298.524,
    "new_tests_per_thousand": 3.66,
    "new_tests_smoothed": 12410,
    "new_tests_smoothed_per_thousand": 2.876,
    "positive_rate": 0.259,
    "tests_per_case": 3.9,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 62.04,
    "population": 4314768,
    "population_density": 55.133,
    "median_age": 29.7,
    "aged_65_older": 7.918,
    "aged_70_older": 5.03,
    "gdp_per_capita": 22267.037,
    "extreme_poverty": 2.2,
    "cardiovasc_death_rate": 128.346,
    "diabetes_prevalence": 8.33,
    "female_smokers": 2.4,
    "male_smokers": 9.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.3,
    "life_expectancy": 78.51,
    "human_development_index": 0.789
  },
  {
    "iso_code": "PNG",
    "continent": "Oceania",
    "location": "Papua New Guinea",
    "total_cases": 780,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": 9,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 87.18,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": 1.006,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 37.96,
    "population": 8947027,
    "population_density": 18.22,
    "median_age": 22.6,
    "aged_65_older": 3.808,
    "aged_70_older": 2.142,
    "gdp_per_capita": 3823.194,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 561.494,
    "diabetes_prevalence": 17.65,
    "female_smokers": 23.5,
    "male_smokers": 48.8,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": "",
    "life_expectancy": 64.5,
    "human_development_index": 0.544
  },
  {
    "iso_code": "PRY",
    "continent": "South America",
    "location": "Paraguay",
    "total_cases": 107932,
    "new_cases": 974,
    "new_cases_smoothed": 657.571,
    "total_deaths": 2262,
    "new_deaths": 20,
    "new_deaths_smoothed": 15.429,
    "total_cases_per_million": 15132.358,
    "new_cases_per_million": 136.557,
    "new_cases_smoothed_per_million": 92.193,
    "total_deaths_per_million": 317.139,
    "new_deaths_per_million": 2.804,
    "new_deaths_smoothed_per_million": 2.163,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 2150,
    "total_tests": 548263,
    "total_tests_per_thousand": 76.868,
    "new_tests_per_thousand": 0.301,
    "new_tests_smoothed": 3176,
    "new_tests_smoothed_per_thousand": 0.445,
    "positive_rate": 0.208,
    "tests_per_case": 4.8,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 52.78,
    "population": 7132530,
    "population_density": 17.144,
    "median_age": 26.5,
    "aged_65_older": 6.378,
    "aged_70_older": 3.833,
    "gdp_per_capita": 8827.01,
    "extreme_poverty": 1.7,
    "cardiovasc_death_rate": 199.128,
    "diabetes_prevalence": 8.27,
    "female_smokers": 5,
    "male_smokers": 21.6,
    "handwashing_facilities": 79.602,
    "hospital_beds_per_thousand": 1.3,
    "life_expectancy": 74.25,
    "human_development_index": 0.702
  },
  {
    "iso_code": "PER",
    "continent": "South America",
    "location": "Peru",
    "total_cases": 1015137,
    "new_cases": 4641,
    "new_cases_smoothed": 2140.571,
    "total_deaths": 37680,
    "new_deaths": 106,
    "new_deaths_smoothed": 66,
    "total_cases_per_million": 30787.994,
    "new_cases_per_million": 140.756,
    "new_cases_smoothed_per_million": 64.921,
    "total_deaths_per_million": 1142.793,
    "new_deaths_per_million": 3.215,
    "new_deaths_smoothed_per_million": 2.002,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 967,
    "total_tests": 1426978,
    "total_tests_per_thousand": 43.279,
    "new_tests_per_thousand": 0.029,
    "new_tests_smoothed": 3869,
    "new_tests_smoothed_per_thousand": 0.117,
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 67.59,
    "population": 32971846,
    "population_density": 25.129,
    "median_age": 29.1,
    "aged_65_older": 7.151,
    "aged_70_older": 4.455,
    "gdp_per_capita": 12236.706,
    "extreme_poverty": 3.5,
    "cardiovasc_death_rate": 85.755,
    "diabetes_prevalence": 5.95,
    "female_smokers": 4.8,
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 1.6,
    "life_expectancy": 76.74,
    "human_development_index": 0.75
  },
  {
    "iso_code": "PHL",
    "continent": "Asia",
    "location": "Philippines",
    "total_cases": 474064,
    "new_cases": 1532,
    "new_cases_smoothed": 1191.429,
    "total_deaths": 9244,
    "new_deaths": 14,
    "new_deaths_smoothed": 27,
    "total_cases_per_million": 4326.148,
    "new_cases_per_million": 13.981,
    "new_cases_smoothed_per_million": 10.873,
    "total_deaths_per_million": 84.358,
    "new_deaths_per_million": 0.128,
    "new_deaths_smoothed_per_million": 0.246,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 21805,
    "total_tests": 6366519,
    "total_tests_per_thousand": 58.099,
    "new_tests_per_thousand": 0.199,
    "new_tests_smoothed": 20288,
    "new_tests_smoothed_per_thousand": 0.185,
    "positive_rate": 0.06,
    "tests_per_case": 16.7,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 64.81,
    "population": 109581085,
    "population_density": 351.873,
    "median_age": 25.2,
    "aged_65_older": 4.803,
    "aged_70_older": 2.661,
    "gdp_per_capita": 7599.188,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 370.437,
    "diabetes_prevalence": 7.07,
    "female_smokers": 7.8,
    "male_smokers": 40.8,
    "handwashing_facilities": 78.463,
    "hospital_beds_per_thousand": 1,
    "life_expectancy": 71.23,
    "human_development_index": 0.699
  },
  {
    "iso_code": "POL",
    "continent": "Europe",
    "location": "Poland",
    "total_cases": 1294878,
    "new_cases": 13464,
    "new_cases_smoothed": 7840,
    "total_deaths": 28554,
    "new_deaths": 535,
    "new_deaths_smoothed": 257.429,
    "total_cases_per_million": 34213.848,
    "new_cases_per_million": 355.752,
    "new_cases_smoothed_per_million": 207.152,
    "total_deaths_per_million": 754.467,
    "new_deaths_per_million": 14.136,
    "new_deaths_smoothed_per_million": 6.802,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": 16686,
    "hosp_patients_per_million": 440.885,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 39052,
    "total_tests": 6941502,
    "total_tests_per_thousand": 183.411,
    "new_tests_per_thousand": 1.032,
    "new_tests_smoothed": 24312,
    "new_tests_smoothed_per_thousand": 0.642,
    "positive_rate": 0.322,
    "tests_per_case": 3.1,
    "tests_units": "people tested",
    "total_vaccinations": 47600,
    "total_vaccinations_per_hundred": 0.13,
    "stringency_index": "",
    "population": 37846605,
    "population_density": 124.027,
    "median_age": 41.8,
    "aged_65_older": 16.763,
    "aged_70_older": 10.202,
    "gdp_per_capita": 27216.445,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 227.331,
    "diabetes_prevalence": 5.91,
    "female_smokers": 23.3,
    "male_smokers": 33.1,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 6.62,
    "life_expectancy": 78.73,
    "human_development_index": 0.865
  },
  {
    "iso_code": "PRT",
    "continent": "Europe",
    "location": "Portugal",
    "total_cases": 413678,
    "new_cases": 7627,
    "new_cases_smoothed": 3720.286,
    "total_deaths": 6906,
    "new_deaths": 76,
    "new_deaths_smoothed": 70.429,
    "total_cases_per_million": 40569.764,
    "new_cases_per_million": 747.987,
    "new_cases_smoothed_per_million": 364.852,
    "total_deaths_per_million": 677.277,
    "new_deaths_per_million": 7.453,
    "new_deaths_smoothed_per_million": 6.907,
    "reproduction_rate": "",
    "icu_patients": 504,
    "icu_patients_per_million": 49.428,
    "hosp_patients": 2870,
    "hosp_patients_per_million": 281.463,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": 263.932,
    "weekly_hosp_admissions_per_million": 25.884,
    "new_tests": 37990,
    "total_tests": 5323341,
    "total_tests_per_thousand": 522.065,
    "new_tests_per_thousand": 3.726,
    "new_tests_smoothed": 36883,
    "new_tests_smoothed_per_thousand": 3.617,
    "positive_rate": 0.098,
    "tests_per_case": 10.2,
    "tests_units": "tests performed",
    "total_vaccinations": 16701,
    "total_vaccinations_per_hundred": 0.16,
    "stringency_index": 67.59,
    "population": 10196707,
    "population_density": 112.371,
    "median_age": 46.2,
    "aged_65_older": 21.502,
    "aged_70_older": 14.924,
    "gdp_per_capita": 27936.896,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 127.842,
    "diabetes_prevalence": 9.85,
    "female_smokers": 16.3,
    "male_smokers": 30,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.39,
    "life_expectancy": 82.05,
    "human_development_index": 0.847
  },
  {
    "iso_code": "QAT",
    "continent": "Asia",
    "location": "Qatar",
    "total_cases": 143834,
    "new_cases": 213,
    "new_cases_smoothed": 175.571,
    "total_deaths": 245,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 49923.986,
    "new_cases_per_million": 73.931,
    "new_cases_smoothed_per_million": 60.94,
    "total_deaths_per_million": 85.038,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.099,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 5213,
    "total_tests": 1241406,
    "total_tests_per_thousand": 430.885,
    "new_tests_per_thousand": 1.809,
    "new_tests_smoothed": 4228,
    "new_tests_smoothed_per_thousand": 1.468,
    "positive_rate": 0.042,
    "tests_per_case": 24.1,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 64.81,
    "population": 2881060,
    "population_density": 227.322,
    "median_age": 31.9,
    "aged_65_older": 1.307,
    "aged_70_older": 0.617,
    "gdp_per_capita": 116935.6,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 176.69,
    "diabetes_prevalence": 16.52,
    "female_smokers": 0.8,
    "male_smokers": 26.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 1.2,
    "life_expectancy": 80.23,
    "human_development_index": 0.856
  },
  {
    "iso_code": "ROU",
    "continent": "Europe",
    "location": "Romania",
    "total_cases": 632263,
    "new_cases": 4322,
    "new_cases_smoothed": 3386,
    "total_deaths": 15767,
    "new_deaths": 171,
    "new_deaths_smoothed": 122.143,
    "total_cases_per_million": 32865.862,
    "new_cases_per_million": 224.663,
    "new_cases_smoothed_per_million": 176.009,
    "total_deaths_per_million": 819.589,
    "new_deaths_per_million": 8.889,
    "new_deaths_smoothed_per_million": 6.349,
    "reproduction_rate": "",
    "icu_patients": 1210,
    "icu_patients_per_million": 62.897,
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": 6618.185,
    "weekly_hosp_admissions_per_million": 344.022,
    "new_tests": 24864,
    "total_tests": 4775921,
    "total_tests_per_thousand": 248.259,
    "new_tests_per_thousand": 1.292,
    "new_tests_smoothed": 15012,
    "new_tests_smoothed_per_thousand": 0.78,
    "positive_rate": 0.226,
    "tests_per_case": 4.4,
    "tests_units": "tests performed",
    "total_vaccinations": 11656,
    "total_vaccinations_per_hundred": 0.06,
    "stringency_index": 76.85,
    "population": 19237682,
    "population_density": 85.129,
    "median_age": 43,
    "aged_65_older": 17.85,
    "aged_70_older": 11.69,
    "gdp_per_capita": 23313.199,
    "extreme_poverty": 5.7,
    "cardiovasc_death_rate": 370.946,
    "diabetes_prevalence": 9.74,
    "female_smokers": 22.9,
    "male_smokers": 37.1,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 6.892,
    "life_expectancy": 76.05,
    "human_development_index": 0.811
  },
  {
    "iso_code": "RUS",
    "continent": "Europe",
    "location": "Russia",
    "total_cases": 3127347,
    "new_cases": 27329,
    "new_cases_smoothed": 27521.714,
    "total_deaths": 56271,
    "new_deaths": 579,
    "new_deaths_smoothed": 548.143,
    "total_cases_per_million": 21429.805,
    "new_cases_per_million": 187.269,
    "new_cases_smoothed_per_million": 188.59,
    "total_deaths_per_million": 385.591,
    "new_deaths_per_million": 3.968,
    "new_deaths_smoothed_per_million": 3.756,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 474243,
    "total_tests": 91123132,
    "total_tests_per_thousand": 624.411,
    "new_tests_per_thousand": 3.25,
    "new_tests_smoothed": 432679,
    "new_tests_smoothed_per_thousand": 2.965,
    "positive_rate": 0.064,
    "tests_per_case": 15.7,
    "tests_units": "tests performed",
    "total_vaccinations": 52000,
    "total_vaccinations_per_hundred": 0.04,
    "stringency_index": "",
    "population": 145934460,
    "population_density": 8.823,
    "median_age": 39.6,
    "aged_65_older": 14.178,
    "aged_70_older": 9.393,
    "gdp_per_capita": 24765.954,
    "extreme_poverty": 0.1,
    "cardiovasc_death_rate": 431.297,
    "diabetes_prevalence": 6.18,
    "female_smokers": 23.4,
    "male_smokers": 58.3,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 8.05,
    "life_expectancy": 72.58,
    "human_development_index": 0.816
  },
  {
    "iso_code": "RWA",
    "continent": "Africa",
    "location": "Rwanda",
    "total_cases": 8383,
    "new_cases": 133,
    "new_cases_smoothed": 101.857,
    "total_deaths": 92,
    "new_deaths": 6,
    "new_deaths_smoothed": 3.286,
    "total_cases_per_million": 647.226,
    "new_cases_per_million": 10.269,
    "new_cases_smoothed_per_million": 7.864,
    "total_deaths_per_million": 7.103,
    "new_deaths_per_million": 0.463,
    "new_deaths_smoothed_per_million": 0.254,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 3409,
    "total_tests": 725804,
    "total_tests_per_thousand": 56.037,
    "new_tests_per_thousand": 0.263,
    "new_tests_smoothed": 3548,
    "new_tests_smoothed_per_thousand": 0.274,
    "positive_rate": 0.029,
    "tests_per_case": 34.8,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 55.09,
    "population": 12952209,
    "population_density": 494.869,
    "median_age": 20.3,
    "aged_65_older": 2.974,
    "aged_70_older": 1.642,
    "gdp_per_capita": 1854.211,
    "extreme_poverty": 56,
    "cardiovasc_death_rate": 191.375,
    "diabetes_prevalence": 4.28,
    "female_smokers": 4.7,
    "male_smokers": 21,
    "handwashing_facilities": 4.617,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 69.02,
    "human_development_index": 0.524
  },
  {
    "iso_code": "KNA",
    "continent": "North America",
    "location": "Saint Kitts and Nevis",
    "total_cases": 32,
    "new_cases": 0,
    "new_cases_smoothed": 0.286,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 601.594,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 5.371,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 53192,
    "population_density": 212.865,
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": 24654.385,
    "extreme_poverty": "",
    "cardiovasc_death_rate": "",
    "diabetes_prevalence": 12.84,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.3,
    "life_expectancy": 76.23,
    "human_development_index": 0.778
  },
  {
    "iso_code": "LCA",
    "continent": "North America",
    "location": "Saint Lucia",
    "total_cases": 353,
    "new_cases": 13,
    "new_cases_smoothed": 6.857,
    "total_deaths": 5,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 1922.354,
    "new_cases_per_million": 70.795,
    "new_cases_smoothed_per_million": 37.342,
    "total_deaths_per_million": 27.229,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 183629,
    "population_density": 293.187,
    "median_age": 34.9,
    "aged_65_older": 9.721,
    "aged_70_older": 6.405,
    "gdp_per_capita": 12951.839,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 204.62,
    "diabetes_prevalence": 11.62,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 87.202,
    "hospital_beds_per_thousand": 1.3,
    "life_expectancy": 76.2,
    "human_development_index": 0.747
  },
  {
    "iso_code": "VCT",
    "continent": "North America",
    "location": "Saint Vincent and the Grenadines",
    "total_cases": 121,
    "new_cases": 6,
    "new_cases_smoothed": 1.857,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 1090.611,
    "new_cases_per_million": 54.08,
    "new_cases_smoothed_per_million": 16.739,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 110947,
    "population_density": 281.787,
    "median_age": 31.8,
    "aged_65_older": 7.724,
    "aged_70_older": 4.832,
    "gdp_per_capita": 10727.146,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 252.675,
    "diabetes_prevalence": 11.62,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.6,
    "life_expectancy": 72.53,
    "human_development_index": 0.723
  },
  {
    "iso_code": "WSM",
    "continent": "Oceania",
    "location": "Samoa",
    "total_cases": 2,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 10.08,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 198410,
    "population_density": 69.413,
    "median_age": 22,
    "aged_65_older": 5.606,
    "aged_70_older": 3.564,
    "gdp_per_capita": 6021.557,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 348.977,
    "diabetes_prevalence": 9.21,
    "female_smokers": 16.7,
    "male_smokers": 38.1,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": "",
    "life_expectancy": 73.32,
    "human_development_index": 0.713
  },
  {
    "iso_code": "SMR",
    "continent": "Europe",
    "location": "San Marino",
    "total_cases": 2428,
    "new_cases": 44,
    "new_cases_smoothed": 28.143,
    "total_deaths": 59,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.429,
    "total_cases_per_million": 71542.224,
    "new_cases_per_million": 1296.482,
    "new_cases_smoothed_per_million": 829.243,
    "total_deaths_per_million": 1738.464,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 12.628,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 51.85,
    "population": 33938,
    "population_density": 556.667,
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": 56861.47,
    "extreme_poverty": "",
    "cardiovasc_death_rate": "",
    "diabetes_prevalence": 5.64,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.8,
    "life_expectancy": 84.97,
    "human_development_index": ""
  },
  {
    "iso_code": "STP",
    "continent": "Africa",
    "location": "Sao Tome and Principe",
    "total_cases": 1014,
    "new_cases": 0,
    "new_cases_smoothed": 0.286,
    "total_deaths": 17,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 4626.736,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 1.304,
    "total_deaths_per_million": 77.569,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 219161,
    "population_density": 212.841,
    "median_age": 18.7,
    "aged_65_older": 2.886,
    "aged_70_older": 2.162,
    "gdp_per_capita": 3052.714,
    "extreme_poverty": 32.3,
    "cardiovasc_death_rate": 270.113,
    "diabetes_prevalence": 2.42,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 41.34,
    "hospital_beds_per_thousand": 2.9,
    "life_expectancy": 70.39,
    "human_development_index": 0.589
  },
  {
    "iso_code": "SAU",
    "continent": "Asia",
    "location": "Saudi Arabia",
    "total_cases": 362741,
    "new_cases": 140,
    "new_cases_smoothed": 145.143,
    "total_deaths": 6223,
    "new_deaths": 9,
    "new_deaths_smoothed": 9.143,
    "total_cases_per_million": 10419.44,
    "new_cases_per_million": 4.021,
    "new_cases_smoothed_per_million": 4.169,
    "total_deaths_per_million": 178.751,
    "new_deaths_per_million": 0.259,
    "new_deaths_smoothed_per_million": 0.263,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 36529,
    "total_tests": 11010384,
    "total_tests_per_thousand": 316.264,
    "new_tests_per_thousand": 1.049,
    "new_tests_smoothed": 31499,
    "new_tests_smoothed_per_thousand": 0.905,
    "positive_rate": 0.005,
    "tests_per_case": 207,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 50,
    "population": 34813867,
    "population_density": 15.322,
    "median_age": 31.9,
    "aged_65_older": 3.295,
    "aged_70_older": 1.845,
    "gdp_per_capita": 49045.411,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 259.538,
    "diabetes_prevalence": 17.72,
    "female_smokers": 1.8,
    "male_smokers": 25.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.7,
    "life_expectancy": 75.13,
    "human_development_index": 0.853
  },
  {
    "iso_code": "SEN",
    "continent": "Africa",
    "location": "Senegal",
    "total_cases": 19140,
    "new_cases": 195,
    "new_cases_smoothed": 134.286,
    "total_deaths": 410,
    "new_deaths": 8,
    "new_deaths_smoothed": 4.143,
    "total_cases_per_million": 1143.101,
    "new_cases_per_million": 11.646,
    "new_cases_smoothed_per_million": 8.02,
    "total_deaths_per_million": 24.486,
    "new_deaths_per_million": 0.478,
    "new_deaths_smoothed_per_million": 0.247,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 1402,
    "total_tests": 277801,
    "total_tests_per_thousand": 16.591,
    "new_tests_per_thousand": 0.084,
    "new_tests_smoothed": 1296,
    "new_tests_smoothed_per_thousand": 0.077,
    "positive_rate": 0.104,
    "tests_per_case": 9.7,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 36.11,
    "population": 16743930,
    "population_density": 82.328,
    "median_age": 18.7,
    "aged_65_older": 3.008,
    "aged_70_older": 1.796,
    "gdp_per_capita": 2470.58,
    "extreme_poverty": 38,
    "cardiovasc_death_rate": 241.219,
    "diabetes_prevalence": 2.42,
    "female_smokers": 0.4,
    "male_smokers": 16.6,
    "handwashing_facilities": 20.859,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 67.94,
    "human_development_index": 0.505
  },
  {
    "iso_code": "SRB",
    "continent": "Europe",
    "location": "Serbia",
    "total_cases": 337923,
    "new_cases": 2932,
    "new_cases_smoothed": 3082.714,
    "total_deaths": 3211,
    "new_deaths": 48,
    "new_deaths_smoothed": 47,
    "total_cases_per_million": 49660.994,
    "new_cases_per_million": 430.885,
    "new_cases_smoothed_per_million": 453.034,
    "total_deaths_per_million": 471.887,
    "new_deaths_per_million": 7.054,
    "new_deaths_smoothed_per_million": 6.907,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 12897,
    "total_tests": 2296303,
    "total_tests_per_thousand": 337.464,
    "new_tests_per_thousand": 1.895,
    "new_tests_smoothed": 13273,
    "new_tests_smoothed_per_thousand": 1.951,
    "positive_rate": 0.232,
    "tests_per_case": 4.3,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 60.19,
    "population": 6804596,
    "population_density": 80.291,
    "median_age": 41.2,
    "aged_65_older": 17.366,
    "aged_70_older": "",
    "gdp_per_capita": 14048.881,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 439.415,
    "diabetes_prevalence": 10.08,
    "female_smokers": 37.7,
    "male_smokers": 40.2,
    "handwashing_facilities": 97.719,
    "hospital_beds_per_thousand": 5.609,
    "life_expectancy": 76,
    "human_development_index": 0.787
  },
  {
    "iso_code": "SYC",
    "continent": "Africa",
    "location": "Seychelles",
    "total_cases": 275,
    "new_cases": 19,
    "new_cases_smoothed": 10,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 2796.421,
    "new_cases_per_million": 193.207,
    "new_cases_smoothed_per_million": 101.688,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 31.48,
    "population": 98340,
    "population_density": 208.354,
    "median_age": 36.2,
    "aged_65_older": 8.606,
    "aged_70_older": 5.586,
    "gdp_per_capita": 26382.287,
    "extreme_poverty": 1.1,
    "cardiovasc_death_rate": 242.648,
    "diabetes_prevalence": 10.55,
    "female_smokers": 7.1,
    "male_smokers": 35.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.6,
    "life_expectancy": 73.4,
    "human_development_index": 0.797
  },
  {
    "iso_code": "SLE",
    "continent": "Africa",
    "location": "Sierra Leone",
    "total_cases": 2611,
    "new_cases": 28,
    "new_cases_smoothed": 11.429,
    "total_deaths": 76,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 327.317,
    "new_cases_per_million": 3.51,
    "new_cases_smoothed_per_million": 1.433,
    "total_deaths_per_million": 9.527,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 7976985,
    "population_density": 104.7,
    "median_age": 19.1,
    "aged_65_older": 2.538,
    "aged_70_older": 1.285,
    "gdp_per_capita": 1390.3,
    "extreme_poverty": 52.2,
    "cardiovasc_death_rate": 325.721,
    "diabetes_prevalence": 2.42,
    "female_smokers": 8.8,
    "male_smokers": 41.3,
    "handwashing_facilities": 19.275,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 54.7,
    "human_development_index": 0.419
  },
  {
    "iso_code": "SGP",
    "continent": "Asia",
    "location": "Singapore",
    "total_cases": 58599,
    "new_cases": 30,
    "new_cases_smoothed": 14.857,
    "total_deaths": 29,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 10016.336,
    "new_cases_per_million": 5.128,
    "new_cases_smoothed_per_million": 2.54,
    "total_deaths_per_million": 4.957,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": 5426351,
    "total_tests_per_thousand": 927.527,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": 27123,
    "new_tests_smoothed_per_thousand": 4.636,
    "positive_rate": 0.001,
    "tests_per_case": 1957.4,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 56.48,
    "population": 5850343,
    "population_density": 7915.731,
    "median_age": 42.4,
    "aged_65_older": 12.922,
    "aged_70_older": 7.049,
    "gdp_per_capita": 85535.383,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 92.243,
    "diabetes_prevalence": 10.99,
    "female_smokers": 5.2,
    "male_smokers": 28.3,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.4,
    "life_expectancy": 83.62,
    "human_development_index": 0.932
  },
  {
    "iso_code": "SVK",
    "continent": "Europe",
    "location": "Slovakia",
    "total_cases": 179543,
    "new_cases": 6315,
    "new_cases_smoothed": 2568.714,
    "total_deaths": 2138,
    "new_deaths": 73,
    "new_deaths_smoothed": 58,
    "total_cases_per_million": 32885.484,
    "new_cases_per_million": 1156.669,
    "new_cases_smoothed_per_million": 470.491,
    "total_deaths_per_million": 391.601,
    "new_deaths_per_million": 13.371,
    "new_deaths_smoothed_per_million": 10.623,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": 2426,
    "hosp_patients_per_million": 444.351,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 16479,
    "total_tests": 1445486,
    "total_tests_per_thousand": 264.758,
    "new_tests_per_thousand": 3.018,
    "new_tests_smoothed": 9934,
    "new_tests_smoothed_per_thousand": 1.82,
    "positive_rate": 0.259,
    "tests_per_case": 3.9,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 46.3,
    "population": 5459643,
    "population_density": 113.128,
    "median_age": 41.2,
    "aged_65_older": 15.07,
    "aged_70_older": 9.167,
    "gdp_per_capita": 30155.152,
    "extreme_poverty": 0.7,
    "cardiovasc_death_rate": 287.959,
    "diabetes_prevalence": 7.29,
    "female_smokers": 23.1,
    "male_smokers": 37.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 5.82,
    "life_expectancy": 77.54,
    "human_development_index": 0.855
  },
  {
    "iso_code": "SVN",
    "continent": "Europe",
    "location": "Slovenia",
    "total_cases": 122152,
    "new_cases": 2412,
    "new_cases_smoothed": 1447.286,
    "total_deaths": 2697,
    "new_deaths": 32,
    "new_deaths_smoothed": 31.143,
    "total_cases_per_million": 58757.093,
    "new_cases_per_million": 1160.211,
    "new_cases_smoothed_per_million": 696.168,
    "total_deaths_per_million": 1297.301,
    "new_deaths_per_million": 15.393,
    "new_deaths_smoothed_per_million": 14.98,
    "reproduction_rate": "",
    "icu_patients": 206,
    "icu_patients_per_million": 99.089,
    "hosp_patients": 1179,
    "hosp_patients_per_million": 567.118,
    "weekly_icu_admissions": 113.892,
    "weekly_icu_admissions_per_million": 54.784,
    "weekly_hosp_admissions": 688.346,
    "weekly_hosp_admissions_per_million": 331.105,
    "new_tests": 8914,
    "total_tests": 718537,
    "total_tests_per_thousand": 345.628,
    "new_tests_per_thousand": 4.288,
    "new_tests_smoothed": 7584,
    "new_tests_smoothed_per_thousand": 3.648,
    "positive_rate": 0.191,
    "tests_per_case": 5.2,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 68.52,
    "population": 2078932,
    "population_density": 102.619,
    "median_age": 44.5,
    "aged_65_older": 19.062,
    "aged_70_older": 12.93,
    "gdp_per_capita": 31400.84,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 153.493,
    "diabetes_prevalence": 7.25,
    "female_smokers": 20.1,
    "male_smokers": 25,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 4.5,
    "life_expectancy": 81.32,
    "human_development_index": 0.896
  },
  {
    "iso_code": "SLB",
    "continent": "Oceania",
    "location": "Solomon Islands",
    "total_cases": 17,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 24.75,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 36.11,
    "population": 686878,
    "population_density": 21.841,
    "median_age": 20.8,
    "aged_65_older": 3.507,
    "aged_70_older": 2.043,
    "gdp_per_capita": 2205.923,
    "extreme_poverty": 25.1,
    "cardiovasc_death_rate": 459.78,
    "diabetes_prevalence": 18.68,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 35.89,
    "hospital_beds_per_thousand": 1.4,
    "life_expectancy": 73,
    "human_development_index": 0.546
  },
  {
    "iso_code": "SOM",
    "continent": "Africa",
    "location": "Somalia",
    "total_cases": 4714,
    "new_cases": 0,
    "new_cases_smoothed": 3.429,
    "total_deaths": 130,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.429,
    "total_cases_per_million": 296.604,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0.216,
    "total_deaths_per_million": 8.18,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.027,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 37.96,
    "population": 15893219,
    "population_density": 23.5,
    "median_age": 16.8,
    "aged_65_older": 2.731,
    "aged_70_older": 1.496,
    "gdp_per_capita": "",
    "extreme_poverty": "",
    "cardiovasc_death_rate": 365.769,
    "diabetes_prevalence": 6.05,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 9.831,
    "hospital_beds_per_thousand": 0.9,
    "life_expectancy": 57.4,
    "human_development_index": ""
  },
  {
    "iso_code": "ZAF",
    "continent": "Africa",
    "location": "South Africa",
    "total_cases": 1057161,
    "new_cases": 18000,
    "new_cases_smoothed": 12656.857,
    "total_deaths": 28469,
    "new_deaths": 436,
    "new_deaths_smoothed": 355.143,
    "total_cases_per_million": 17824.723,
    "new_cases_per_million": 303.497,
    "new_cases_smoothed_per_million": 213.406,
    "total_deaths_per_million": 480.014,
    "new_deaths_per_million": 7.351,
    "new_deaths_smoothed_per_million": 5.988,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 55447,
    "total_tests": 6609208,
    "total_tests_per_thousand": 111.437,
    "new_tests_per_thousand": 0.935,
    "new_tests_smoothed": 40489,
    "new_tests_smoothed_per_thousand": 0.683,
    "positive_rate": 0.313,
    "tests_per_case": 3.2,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 41.67,
    "population": 59308690,
    "population_density": 46.754,
    "median_age": 27.3,
    "aged_65_older": 5.344,
    "aged_70_older": 3.053,
    "gdp_per_capita": 12294.876,
    "extreme_poverty": 18.9,
    "cardiovasc_death_rate": 200.38,
    "diabetes_prevalence": 5.52,
    "female_smokers": 8.1,
    "male_smokers": 33.2,
    "handwashing_facilities": 43.993,
    "hospital_beds_per_thousand": 2.32,
    "life_expectancy": 64.13,
    "human_development_index": 0.699
  },
  {
    "iso_code": "KOR",
    "continent": "Asia",
    "location": "South Korea",
    "total_cases": 61769,
    "new_cases": 1029,
    "new_cases_smoothed": 999.857,
    "total_deaths": 917,
    "new_deaths": 17,
    "new_deaths_smoothed": 20.571,
    "total_cases_per_million": 1204.798,
    "new_cases_per_million": 20.071,
    "new_cases_smoothed_per_million": 19.502,
    "total_deaths_per_million": 17.886,
    "new_deaths_per_million": 0.332,
    "new_deaths_smoothed_per_million": 0.401,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 55791,
    "total_tests": 4043542,
    "total_tests_per_thousand": 78.869,
    "new_tests_per_thousand": 1.088,
    "new_tests_smoothed": 44552,
    "new_tests_smoothed_per_thousand": 0.869,
    "positive_rate": 0.022,
    "tests_per_case": 44.6,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 60.65,
    "population": 51269183,
    "population_density": 527.967,
    "median_age": 43.4,
    "aged_65_older": 13.914,
    "aged_70_older": 8.622,
    "gdp_per_capita": 35938.374,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 85.998,
    "diabetes_prevalence": 6.8,
    "female_smokers": 6.2,
    "male_smokers": 40.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 12.27,
    "life_expectancy": 83.03,
    "human_development_index": 0.903
  },
  {
    "iso_code": "SSD",
    "continent": "Africa",
    "location": "South Sudan",
    "total_cases": 3558,
    "new_cases": 18,
    "new_cases_smoothed": 14.714,
    "total_deaths": 63,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 317.857,
    "new_cases_per_million": 1.608,
    "new_cases_smoothed_per_million": 1.315,
    "total_deaths_per_million": 5.628,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.013,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 260,
    "total_tests": 76232,
    "total_tests_per_thousand": 6.81,
    "new_tests_per_thousand": 0.023,
    "new_tests_smoothed": 282,
    "new_tests_smoothed_per_thousand": 0.025,
    "positive_rate": 0.052,
    "tests_per_case": 19.2,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 50,
    "population": 11193729,
    "population_density": "",
    "median_age": 19.2,
    "aged_65_older": 3.441,
    "aged_70_older": 2.032,
    "gdp_per_capita": 1569.888,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 280.775,
    "diabetes_prevalence": 10.43,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": "",
    "life_expectancy": 57.85,
    "human_development_index": 0.388
  },
  {
    "iso_code": "ESP",
    "continent": "Europe",
    "location": "Spain",
    "total_cases": 1928265,
    "new_cases": 18047,
    "new_cases_smoothed": 10473.429,
    "total_deaths": 50837,
    "new_deaths": 148,
    "new_deaths_smoothed": 144.714,
    "total_cases_per_million": 41242.091,
    "new_cases_per_million": 385.993,
    "new_cases_smoothed_per_million": 224.008,
    "total_deaths_per_million": 1087.311,
    "new_deaths_per_million": 3.165,
    "new_deaths_smoothed_per_million": 3.095,
    "reproduction_rate": "",
    "icu_patients": 1907,
    "icu_patients_per_million": 40.787,
    "hosp_patients": 10744,
    "hosp_patients_per_million": 229.795,
    "weekly_icu_admissions": 39.845,
    "weekly_icu_admissions_per_million": 0.852,
    "weekly_hosp_admissions": 990.14,
    "weekly_hosp_admissions_per_million": 21.177,
    "new_tests": "",
    "total_tests": 21859996,
    "total_tests_per_thousand": 467.546,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": 139418,
    "new_tests_smoothed_per_thousand": 2.982,
    "positive_rate": 0.071,
    "tests_per_case": 14,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 46754783,
    "population_density": 93.105,
    "median_age": 45.5,
    "aged_65_older": 19.436,
    "aged_70_older": 13.799,
    "gdp_per_capita": 34272.36,
    "extreme_poverty": 1,
    "cardiovasc_death_rate": 99.403,
    "diabetes_prevalence": 7.17,
    "female_smokers": 27.4,
    "male_smokers": 31.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.97,
    "life_expectancy": 83.56,
    "human_development_index": 0.891
  },
  {
    "iso_code": "LKA",
    "continent": "Asia",
    "location": "Sri Lanka",
    "total_cases": 43299,
    "new_cases": 597,
    "new_cases_smoothed": 581.143,
    "total_deaths": 204,
    "new_deaths": 5,
    "new_deaths_smoothed": 2.714,
    "total_cases_per_million": 2022.066,
    "new_cases_per_million": 27.88,
    "new_cases_smoothed_per_million": 27.139,
    "total_deaths_per_million": 9.527,
    "new_deaths_per_million": 0.234,
    "new_deaths_smoothed_per_million": 0.127,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 14243,
    "total_tests": 1250417,
    "total_tests_per_thousand": 58.395,
    "new_tests_per_thousand": 0.665,
    "new_tests_smoothed": 11838,
    "new_tests_smoothed_per_thousand": 0.553,
    "positive_rate": 0.049,
    "tests_per_case": 20.4,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 21413250,
    "population_density": 341.955,
    "median_age": 34.1,
    "aged_65_older": 10.069,
    "aged_70_older": 5.331,
    "gdp_per_capita": 11669.077,
    "extreme_poverty": 0.7,
    "cardiovasc_death_rate": 197.093,
    "diabetes_prevalence": 10.68,
    "female_smokers": 0.3,
    "male_smokers": 27,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 3.6,
    "life_expectancy": 76.98,
    "human_development_index": 0.77
  },
  {
    "iso_code": "SDN",
    "continent": "Africa",
    "location": "Sudan",
    "total_cases": 23316,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": 1468,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 531.731,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": 33.478,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 21.3,
    "population": 43849269,
    "population_density": 23.258,
    "median_age": 19.7,
    "aged_65_older": 3.548,
    "aged_70_older": 2.034,
    "gdp_per_capita": 4466.507,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 431.388,
    "diabetes_prevalence": 15.67,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 23.437,
    "hospital_beds_per_thousand": 0.8,
    "life_expectancy": 65.31,
    "human_development_index": 0.502
  },
  {
    "iso_code": "SUR",
    "continent": "South America",
    "location": "Suriname",
    "total_cases": 6210,
    "new_cases": 29,
    "new_cases_smoothed": 57.143,
    "total_deaths": 122,
    "new_deaths": 1,
    "new_deaths_smoothed": 0.571,
    "total_cases_per_million": 10585.817,
    "new_cases_per_million": 49.435,
    "new_cases_smoothed_per_million": 97.408,
    "total_deaths_per_million": 207.966,
    "new_deaths_per_million": 1.705,
    "new_deaths_smoothed_per_million": 0.974,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 57.41,
    "population": 586634,
    "population_density": 3.612,
    "median_age": 29.6,
    "aged_65_older": 6.933,
    "aged_70_older": 4.229,
    "gdp_per_capita": 13767.119,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 258.314,
    "diabetes_prevalence": 12.54,
    "female_smokers": 7.4,
    "male_smokers": 42.9,
    "handwashing_facilities": 67.779,
    "hospital_beds_per_thousand": 3.1,
    "life_expectancy": 71.68,
    "human_development_index": 0.72
  },
  {
    "iso_code": "SWE",
    "continent": "Europe",
    "location": "Sweden",
    "total_cases": 437379,
    "new_cases": 0,
    "new_cases_smoothed": 5904.429,
    "total_deaths": 8727,
    "new_deaths": 0,
    "new_deaths_smoothed": 64,
    "total_cases_per_million": 43307.982,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 584.639,
    "total_deaths_per_million": 864.122,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 6.337,
    "reproduction_rate": "",
    "icu_patients": 323,
    "icu_patients_per_million": 31.983,
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": 175.722,
    "weekly_icu_admissions_per_million": 17.399,
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 34116,
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": 3.378,
    "new_tests_smoothed": 34116,
    "new_tests_smoothed_per_thousand": 3.378,
    "positive_rate": 0.121,
    "tests_per_case": 8.3,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 71.3,
    "population": 10099270,
    "population_density": 24.718,
    "median_age": 41,
    "aged_65_older": 19.985,
    "aged_70_older": 13.433,
    "gdp_per_capita": 46949.283,
    "extreme_poverty": 0.5,
    "cardiovasc_death_rate": 133.982,
    "diabetes_prevalence": 4.79,
    "female_smokers": 18.8,
    "male_smokers": 18.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.22,
    "life_expectancy": 82.8,
    "human_development_index": 0.933
  },
  {
    "iso_code": "CHE",
    "continent": "Europe",
    "location": "Switzerland",
    "total_cases": 452296,
    "new_cases": 4391,
    "new_cases_smoothed": 3442.714,
    "total_deaths": 7645,
    "new_deaths": 51,
    "new_deaths_smoothed": 78.143,
    "total_cases_per_million": 52260.654,
    "new_cases_per_million": 507.359,
    "new_cases_smoothed_per_million": 397.789,
    "total_deaths_per_million": 883.343,
    "new_deaths_per_million": 5.893,
    "new_deaths_smoothed_per_million": 9.029,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 14654,
    "total_tests": 3301664,
    "total_tests_per_thousand": 381.492,
    "new_tests_per_thousand": 1.693,
    "new_tests_smoothed": 15146,
    "new_tests_smoothed_per_thousand": 1.75,
    "positive_rate": 0.171,
    "tests_per_case": 5.8,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 60.19,
    "population": 8654618,
    "population_density": 214.243,
    "median_age": 43.1,
    "aged_65_older": 18.436,
    "aged_70_older": 12.644,
    "gdp_per_capita": 57410.166,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 99.739,
    "diabetes_prevalence": 5.59,
    "female_smokers": 22.6,
    "male_smokers": 28.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 4.53,
    "life_expectancy": 83.78,
    "human_development_index": 0.944
  },
  {
    "iso_code": "SYR",
    "continent": "Asia",
    "location": "Syria",
    "total_cases": 11434,
    "new_cases": 90,
    "new_cases_smoothed": 104.714,
    "total_deaths": 711,
    "new_deaths": 7,
    "new_deaths_smoothed": 8.429,
    "total_cases_per_million": 653.347,
    "new_cases_per_million": 5.143,
    "new_cases_smoothed_per_million": 5.983,
    "total_deaths_per_million": 40.627,
    "new_deaths_per_million": 0.4,
    "new_deaths_smoothed_per_million": 0.482,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 54.17,
    "population": 17500657,
    "population_density": "",
    "median_age": 21.7,
    "aged_65_older": "",
    "aged_70_older": 2.577,
    "gdp_per_capita": "",
    "extreme_poverty": "",
    "cardiovasc_death_rate": 376.264,
    "diabetes_prevalence": "",
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 70.598,
    "hospital_beds_per_thousand": 1.5,
    "life_expectancy": 72.7,
    "human_development_index": 0.536
  },
  {
    "iso_code": "TWN",
    "continent": "Asia",
    "location": "Taiwan",
    "total_cases": 799,
    "new_cases": 2,
    "new_cases_smoothed": 3.286,
    "total_deaths": 7,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 33.548,
    "new_cases_per_million": 0.084,
    "new_cases_smoothed_per_million": 0.138,
    "total_deaths_per_million": 0.294,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 506,
    "total_tests": 127000,
    "total_tests_per_thousand": 5.332,
    "new_tests_per_thousand": 0.021,
    "new_tests_smoothed": 638,
    "new_tests_smoothed_per_thousand": 0.027,
    "positive_rate": 0.005,
    "tests_per_case": 194.2,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 19.44,
    "population": 23816775,
    "population_density": "",
    "median_age": 42.2,
    "aged_65_older": "",
    "aged_70_older": 8.353,
    "gdp_per_capita": "",
    "extreme_poverty": "",
    "cardiovasc_death_rate": 103.957,
    "diabetes_prevalence": "",
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": "",
    "life_expectancy": 80.46,
    "human_development_index": ""
  },
  {
    "iso_code": "TJK",
    "continent": "Asia",
    "location": "Tajikistan",
    "total_cases": 13296,
    "new_cases": 0,
    "new_cases_smoothed": 27.143,
    "total_deaths": 90,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.143,
    "total_cases_per_million": 1394.055,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 2.846,
    "total_deaths_per_million": 9.436,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.015,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 36.11,
    "population": 9537642,
    "population_density": 64.281,
    "median_age": 23.3,
    "aged_65_older": 3.466,
    "aged_70_older": 2.155,
    "gdp_per_capita": 2896.913,
    "extreme_poverty": 4.8,
    "cardiovasc_death_rate": 427.698,
    "diabetes_prevalence": 7.11,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 72.704,
    "hospital_beds_per_thousand": 4.8,
    "life_expectancy": 71.1,
    "human_development_index": 0.65
  },
  {
    "iso_code": "TZA",
    "continent": "Africa",
    "location": "Tanzania",
    "total_cases": 509,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": 21,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 8.521,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": 0.352,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 6.48,
    "population": 59734213,
    "population_density": 64.699,
    "median_age": 17.7,
    "aged_65_older": 3.108,
    "aged_70_older": 1.874,
    "gdp_per_capita": 2683.304,
    "extreme_poverty": 49.1,
    "cardiovasc_death_rate": 217.288,
    "diabetes_prevalence": 5.75,
    "female_smokers": 3.3,
    "male_smokers": 26.7,
    "handwashing_facilities": 47.953,
    "hospital_beds_per_thousand": 0.7,
    "life_expectancy": 65.46,
    "human_development_index": 0.538
  },
  {
    "iso_code": "THA",
    "continent": "Asia",
    "location": "Thailand",
    "total_cases": 7163,
    "new_cases": 473,
    "new_cases_smoothed": 179,
    "total_deaths": 63,
    "new_deaths": 2,
    "new_deaths_smoothed": 0.429,
    "total_cases_per_million": 102.622,
    "new_cases_per_million": 6.777,
    "new_cases_smoothed_per_million": 2.564,
    "total_deaths_per_million": 0.903,
    "new_deaths_per_million": 0.029,
    "new_deaths_smoothed_per_million": 0.006,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 4760,
    "total_tests": 1395666,
    "total_tests_per_thousand": 19.995,
    "new_tests_per_thousand": 0.068,
    "new_tests_smoothed": 4718,
    "new_tests_smoothed_per_thousand": 0.068,
    "positive_rate": 0.004,
    "tests_per_case": 237.6,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 50,
    "population": 69799978,
    "population_density": 135.132,
    "median_age": 40.1,
    "aged_65_older": 11.373,
    "aged_70_older": 6.89,
    "gdp_per_capita": 16277.671,
    "extreme_poverty": 0.1,
    "cardiovasc_death_rate": 109.861,
    "diabetes_prevalence": 7.04,
    "female_smokers": 1.9,
    "male_smokers": 38.8,
    "handwashing_facilities": 90.67,
    "hospital_beds_per_thousand": 2.1,
    "life_expectancy": 77.15,
    "human_development_index": 0.755
  },
  {
    "iso_code": "TLS",
    "continent": "Asia",
    "location": "Timor",
    "total_cases": 44,
    "new_cases": 0,
    "new_cases_smoothed": 0.429,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 33.373,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0.325,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 27.78,
    "population": 1318442,
    "population_density": 87.176,
    "median_age": 18,
    "aged_65_older": 3.556,
    "aged_70_older": 1.897,
    "gdp_per_capita": 6570.102,
    "extreme_poverty": 30.3,
    "cardiovasc_death_rate": 335.346,
    "diabetes_prevalence": 6.86,
    "female_smokers": 6.3,
    "male_smokers": 78.1,
    "handwashing_facilities": 28.178,
    "hospital_beds_per_thousand": 5.9,
    "life_expectancy": 69.5,
    "human_development_index": 0.625
  },
  {
    "iso_code": "TGO",
    "continent": "Africa",
    "location": "Togo",
    "total_cases": 3633,
    "new_cases": 22,
    "new_cases_smoothed": 16.143,
    "total_deaths": 68,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 438.835,
    "new_cases_per_million": 2.657,
    "new_cases_smoothed_per_million": 1.95,
    "total_deaths_per_million": 8.214,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 768,
    "total_tests": 178330,
    "total_tests_per_thousand": 21.541,
    "new_tests_per_thousand": 0.093,
    "new_tests_smoothed": 934,
    "new_tests_smoothed_per_thousand": 0.113,
    "positive_rate": 0.018,
    "tests_per_case": 55.9,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 64.81,
    "population": 8278737,
    "population_density": 143.366,
    "median_age": 19.4,
    "aged_65_older": 2.839,
    "aged_70_older": 1.525,
    "gdp_per_capita": 1429.813,
    "extreme_poverty": 49.2,
    "cardiovasc_death_rate": 280.033,
    "diabetes_prevalence": 6.15,
    "female_smokers": 0.9,
    "male_smokers": 14.2,
    "handwashing_facilities": 10.475,
    "hospital_beds_per_thousand": 0.7,
    "life_expectancy": 61.04,
    "human_development_index": 0.503
  },
  {
    "iso_code": "TTO",
    "continent": "North America",
    "location": "Trinidad and Tobago",
    "total_cases": 7150,
    "new_cases": 18,
    "new_cases_smoothed": 8.714,
    "total_deaths": 127,
    "new_deaths": 1,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 5109,
    "new_cases_per_million": 12.862,
    "new_cases_smoothed_per_million": 6.227,
    "total_deaths_per_million": 90.747,
    "new_deaths_per_million": 0.715,
    "new_deaths_smoothed_per_million": 0.204,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 30892,
    "total_tests": 70152,
    "total_tests_per_thousand": 50.127,
    "new_tests_per_thousand": 22.074,
    "new_tests_smoothed": 4494,
    "new_tests_smoothed_per_thousand": 3.211,
    "positive_rate": 0.004,
    "tests_per_case": 247.7,
    "tests_units": "people tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 1399491,
    "population_density": 266.886,
    "median_age": 36.2,
    "aged_65_older": 10.014,
    "aged_70_older": 5.819,
    "gdp_per_capita": 28763.071,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 228.467,
    "diabetes_prevalence": 10.97,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": 89.443,
    "hospital_beds_per_thousand": 3,
    "life_expectancy": 73.51,
    "human_development_index": 0.784
  },
  {
    "iso_code": "TUN",
    "continent": "Africa",
    "location": "Tunisia",
    "total_cases": 139140,
    "new_cases": 1924,
    "new_cases_smoothed": 1769.714,
    "total_deaths": 4676,
    "new_deaths": 56,
    "new_deaths_smoothed": 50.286,
    "total_cases_per_million": 11772.95,
    "new_cases_per_million": 162.794,
    "new_cases_smoothed_per_million": 149.74,
    "total_deaths_per_million": 395.647,
    "new_deaths_per_million": 4.738,
    "new_deaths_smoothed_per_million": 4.255,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 11818618,
    "population_density": 74.228,
    "median_age": 32.7,
    "aged_65_older": 8.001,
    "aged_70_older": 5.075,
    "gdp_per_capita": 10849.297,
    "extreme_poverty": 2,
    "cardiovasc_death_rate": 318.991,
    "diabetes_prevalence": 8.52,
    "female_smokers": 1.1,
    "male_smokers": 65.8,
    "handwashing_facilities": 78.687,
    "hospital_beds_per_thousand": 2.3,
    "life_expectancy": 76.7,
    "human_development_index": 0.735
  },
  {
    "iso_code": "TUR",
    "continent": "Asia",
    "location": "Turkey",
    "total_cases": 2208652,
    "new_cases": 14380,
    "new_cases_smoothed": 15420,
    "total_deaths": 20881,
    "new_deaths": 239,
    "new_deaths_smoothed": 252.286,
    "total_cases_per_million": 26187.769,
    "new_cases_per_million": 170.502,
    "new_cases_smoothed_per_million": 182.833,
    "total_deaths_per_million": 247.584,
    "new_deaths_per_million": 2.834,
    "new_deaths_smoothed_per_million": 2.991,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 178217,
    "total_tests": 24504567,
    "total_tests_per_thousand": 290.548,
    "new_tests_per_thousand": 2.113,
    "new_tests_smoothed": 182676,
    "new_tests_smoothed_per_thousand": 2.166,
    "positive_rate": 0.084,
    "tests_per_case": 11.8,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 62.5,
    "population": 84339067,
    "population_density": 104.914,
    "median_age": 31.6,
    "aged_65_older": 8.153,
    "aged_70_older": 5.061,
    "gdp_per_capita": 25129.341,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 171.285,
    "diabetes_prevalence": 12.13,
    "female_smokers": 14.1,
    "male_smokers": 41.1,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.81,
    "life_expectancy": 77.69,
    "human_development_index": 0.791
  },
  {
    "iso_code": "UGA",
    "continent": "Africa",
    "location": "Uganda",
    "total_cases": 35216,
    "new_cases": 539,
    "new_cases_smoothed": 328.857,
    "total_deaths": 251,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.857,
    "total_cases_per_million": 769.9,
    "new_cases_per_million": 11.784,
    "new_cases_smoothed_per_million": 7.19,
    "total_deaths_per_million": 5.487,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.019,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 3566,
    "total_tests": 743098,
    "total_tests_per_thousand": 16.246,
    "new_tests_per_thousand": 0.078,
    "new_tests_smoothed": 2930,
    "new_tests_smoothed_per_thousand": 0.064,
    "positive_rate": 0.116,
    "tests_per_case": 8.7,
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 47.22,
    "population": 45741000,
    "population_density": 213.759,
    "median_age": 16.4,
    "aged_65_older": 2.168,
    "aged_70_older": 1.308,
    "gdp_per_capita": 1697.707,
    "extreme_poverty": 41.6,
    "cardiovasc_death_rate": 213.333,
    "diabetes_prevalence": 2.5,
    "female_smokers": 3.4,
    "male_smokers": 16.7,
    "handwashing_facilities": 21.222,
    "hospital_beds_per_thousand": 0.5,
    "life_expectancy": 63.37,
    "human_development_index": 0.516
  },
  {
    "iso_code": "UKR",
    "continent": "Europe",
    "location": "Ukraine",
    "total_cases": 1086997,
    "new_cases": 10117,
    "new_cases_smoothed": 8124.571,
    "total_deaths": 19281,
    "new_deaths": 223,
    "new_deaths_smoothed": 174.857,
    "total_cases_per_million": 24854.872,
    "new_cases_per_million": 231.332,
    "new_cases_smoothed_per_million": 185.773,
    "total_deaths_per_million": 440.872,
    "new_deaths_per_million": 5.099,
    "new_deaths_smoothed_per_million": 3.998,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 74436,
    "total_tests": 5585615,
    "total_tests_per_thousand": 127.719,
    "new_tests_per_thousand": 1.702,
    "new_tests_smoothed": 33828,
    "new_tests_smoothed_per_thousand": 0.773,
    "positive_rate": 0.248,
    "tests_per_case": 4,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 61.57,
    "population": 43733759,
    "population_density": 77.39,
    "median_age": 41.4,
    "aged_65_older": 16.462,
    "aged_70_older": 11.133,
    "gdp_per_capita": 7894.393,
    "extreme_poverty": 0.1,
    "cardiovasc_death_rate": 539.849,
    "diabetes_prevalence": 7.11,
    "female_smokers": 13.5,
    "male_smokers": 47.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 8.8,
    "life_expectancy": 72.06,
    "human_development_index": 0.751
  },
  {
    "iso_code": "ARE",
    "continent": "Asia",
    "location": "United Arab Emirates",
    "total_cases": 207822,
    "new_cases": 1730,
    "new_cases_smoothed": 1341,
    "total_deaths": 669,
    "new_deaths": 4,
    "new_deaths_smoothed": 3.143,
    "total_cases_per_million": 21012.497,
    "new_cases_per_million": 174.917,
    "new_cases_smoothed_per_million": 135.586,
    "total_deaths_per_million": 67.641,
    "new_deaths_per_million": 0.404,
    "new_deaths_smoothed_per_million": 0.318,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 146721,
    "total_tests": 20890341,
    "total_tests_per_thousand": 2112.184,
    "new_tests_per_thousand": 14.835,
    "new_tests_smoothed": 143511,
    "new_tests_smoothed_per_thousand": 14.51,
    "positive_rate": 0.009,
    "tests_per_case": 107,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 49.07,
    "population": 9890400,
    "population_density": 112.442,
    "median_age": 34,
    "aged_65_older": 1.144,
    "aged_70_older": 0.526,
    "gdp_per_capita": 67293.483,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 317.84,
    "diabetes_prevalence": 17.26,
    "female_smokers": 1.2,
    "male_smokers": 37.4,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 1.2,
    "life_expectancy": 77.97,
    "human_development_index": 0.863
  },
  {
    "iso_code": "GBR",
    "continent": "Europe",
    "location": "United Kingdom",
    "total_cases": 2496231,
    "new_cases": 56029,
    "new_cases_smoothed": 43012.429,
    "total_deaths": 73622,
    "new_deaths": 965,
    "new_deaths_smoothed": 555.714,
    "total_cases_per_million": 36770.923,
    "new_cases_per_million": 825.339,
    "new_cases_smoothed_per_million": 633.598,
    "total_deaths_per_million": 1084.495,
    "new_deaths_per_million": 14.215,
    "new_deaths_smoothed_per_million": 8.186,
    "reproduction_rate": "",
    "icu_patients": 1751,
    "icu_patients_per_million": 25.793,
    "hosp_patients": 22520,
    "hosp_patients_per_million": 331.733,
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": 13757.061,
    "weekly_hosp_admissions_per_million": 202.649,
    "new_tests": 420492,
    "total_tests": 52058247,
    "total_tests_per_thousand": 766.848,
    "new_tests_per_thousand": 6.194,
    "new_tests_smoothed": 374544,
    "new_tests_smoothed_per_thousand": 5.517,
    "positive_rate": 0.108,
    "tests_per_case": 9.2,
    "tests_units": "tests performed",
    "total_vaccinations": 1000000,
    "total_vaccinations_per_hundred": 1.47,
    "stringency_index": 78.7,
    "population": 67886004,
    "population_density": 272.898,
    "median_age": 40.8,
    "aged_65_older": 18.517,
    "aged_70_older": 12.527,
    "gdp_per_capita": 39753.244,
    "extreme_poverty": 0.2,
    "cardiovasc_death_rate": 122.137,
    "diabetes_prevalence": 4.28,
    "female_smokers": 20,
    "male_smokers": 24.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.54,
    "life_expectancy": 81.32,
    "human_development_index": 0.922
  },
  {
    "iso_code": "USA",
    "continent": "North America",
    "location": "United States",
    "total_cases": 19968087,
    "new_cases": 227315,
    "new_cases_smoothed": 186106.286,
    "total_deaths": 345737,
    "new_deaths": 3419,
    "new_deaths_smoothed": 2387.714,
    "total_cases_per_million": 60326.064,
    "new_cases_per_million": 686.747,
    "new_cases_smoothed_per_million": 562.25,
    "total_deaths_per_million": 1044.514,
    "new_deaths_per_million": 10.329,
    "new_deaths_smoothed_per_million": 7.214,
    "reproduction_rate": "",
    "icu_patients": 23069,
    "icu_patients_per_million": 69.694,
    "hosp_patients": 125220,
    "hosp_patients_per_million": 378.305,
    "weekly_icu_admissions": 1134,
    "weekly_icu_admissions_per_million": 3.426,
    "weekly_hosp_admissions": 23403,
    "weekly_hosp_admissions_per_million": 70.703,
    "new_tests": 243910,
    "total_tests": 239260589,
    "total_tests_per_thousand": 722.836,
    "new_tests_per_thousand": 0.737,
    "new_tests_smoothed": 1019080,
    "new_tests_smoothed_per_thousand": 3.079,
    "positive_rate": 0.121,
    "tests_per_case": 8.3,
    "tests_units": "tests performed",
    "total_vaccinations": 2794588,
    "total_vaccinations_per_hundred": 0.84,
    "stringency_index": 71.76,
    "population": 331002647,
    "population_density": 35.608,
    "median_age": 38.3,
    "aged_65_older": 15.413,
    "aged_70_older": 9.732,
    "gdp_per_capita": 54225.446,
    "extreme_poverty": 1.2,
    "cardiovasc_death_rate": 151.089,
    "diabetes_prevalence": 10.79,
    "female_smokers": 19.1,
    "male_smokers": 24.6,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.77,
    "life_expectancy": 78.86,
    "human_development_index": 0.924
  },
  {
    "iso_code": "URY",
    "continent": "South America",
    "location": "Uruguay",
    "total_cases": 19119,
    "new_cases": 639,
    "new_cases_smoothed": 523.143,
    "total_deaths": 181,
    "new_deaths": 7,
    "new_deaths_smoothed": 6.571,
    "total_cases_per_million": 5503.887,
    "new_cases_per_million": 183.952,
    "new_cases_smoothed_per_million": 150.6,
    "total_deaths_per_million": 52.105,
    "new_deaths_per_million": 2.015,
    "new_deaths_smoothed_per_million": 1.892,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 6779,
    "total_tests": 640128,
    "total_tests_per_thousand": 184.277,
    "new_tests_per_thousand": 1.952,
    "new_tests_smoothed": 6187,
    "new_tests_smoothed_per_thousand": 1.781,
    "positive_rate": 0.085,
    "tests_per_case": 11.8,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 66.67,
    "population": 3473727,
    "population_density": 19.751,
    "median_age": 35.6,
    "aged_65_older": 14.655,
    "aged_70_older": 10.361,
    "gdp_per_capita": 20551.409,
    "extreme_poverty": 0.1,
    "cardiovasc_death_rate": 160.708,
    "diabetes_prevalence": 6.93,
    "female_smokers": 14,
    "male_smokers": 19.9,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 2.8,
    "life_expectancy": 77.91,
    "human_development_index": 0.804
  },
  {
    "iso_code": "UZB",
    "continent": "Asia",
    "location": "Uzbekistan",
    "total_cases": 77060,
    "new_cases": 75,
    "new_cases_smoothed": 86.286,
    "total_deaths": 614,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.286,
    "total_cases_per_million": 2302.415,
    "new_cases_per_million": 2.241,
    "new_cases_smoothed_per_million": 2.578,
    "total_deaths_per_million": 18.345,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.009,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 39.81,
    "population": 33469199,
    "population_density": 76.134,
    "median_age": 28.2,
    "aged_65_older": 4.469,
    "aged_70_older": 2.873,
    "gdp_per_capita": 6253.104,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 724.417,
    "diabetes_prevalence": 7.57,
    "female_smokers": 1.3,
    "male_smokers": 24.7,
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 4,
    "life_expectancy": 71.72,
    "human_development_index": 0.71
  },
  {
    "iso_code": "VUT",
    "continent": "Oceania",
    "location": "Vanuatu",
    "total_cases": 1,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 3.256,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 30.56,
    "population": 307150,
    "population_density": 22.662,
    "median_age": 23.1,
    "aged_65_older": 4.394,
    "aged_70_older": 2.62,
    "gdp_per_capita": 2921.909,
    "extreme_poverty": 13.2,
    "cardiovasc_death_rate": 546.3,
    "diabetes_prevalence": 12.02,
    "female_smokers": 2.8,
    "male_smokers": 34.5,
    "handwashing_facilities": 25.209,
    "hospital_beds_per_thousand": "",
    "life_expectancy": 70.47,
    "human_development_index": 0.603
  },
  {
    "iso_code": "VAT",
    "continent": "Europe",
    "location": "Vatican",
    "total_cases": 27,
    "new_cases": 0,
    "new_cases_smoothed": 0,
    "total_deaths": "",
    "new_deaths": "",
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 33374.536,
    "new_cases_per_million": 0,
    "new_cases_smoothed_per_million": 0,
    "total_deaths_per_million": "",
    "new_deaths_per_million": "",
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 809,
    "population_density": "",
    "median_age": "",
    "aged_65_older": "",
    "aged_70_older": "",
    "gdp_per_capita": "",
    "extreme_poverty": "",
    "cardiovasc_death_rate": "",
    "diabetes_prevalence": "",
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": "",
    "life_expectancy": 75.12,
    "human_development_index": ""
  },
  {
    "iso_code": "VEN",
    "continent": "South America",
    "location": "Venezuela",
    "total_cases": 113558,
    "new_cases": 437,
    "new_cases_smoothed": 330.429,
    "total_deaths": 1028,
    "new_deaths": 3,
    "new_deaths_smoothed": 3.143,
    "total_cases_per_million": 3993.467,
    "new_cases_per_million": 15.368,
    "new_cases_smoothed_per_million": 11.62,
    "total_deaths_per_million": 36.151,
    "new_deaths_per_million": 0.106,
    "new_deaths_smoothed_per_million": 0.111,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 84.26,
    "population": 28435943,
    "population_density": 36.253,
    "median_age": 29,
    "aged_65_older": 6.614,
    "aged_70_older": 3.915,
    "gdp_per_capita": 16745.022,
    "extreme_poverty": "",
    "cardiovasc_death_rate": 204.85,
    "diabetes_prevalence": 6.47,
    "female_smokers": "",
    "male_smokers": "",
    "handwashing_facilities": "",
    "hospital_beds_per_thousand": 0.8,
    "life_expectancy": 72.06,
    "human_development_index": 0.761
  },
  {
    "iso_code": "VNM",
    "continent": "Asia",
    "location": "Vietnam",
    "total_cases": 1465,
    "new_cases": 9,
    "new_cases_smoothed": 4.714,
    "total_deaths": 35,
    "new_deaths": 0,
    "new_deaths_smoothed": 0,
    "total_cases_per_million": 15.051,
    "new_cases_per_million": 0.092,
    "new_cases_smoothed_per_million": 0.048,
    "total_deaths_per_million": 0.36,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": 1469955,
    "total_tests_per_thousand": 15.101,
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "samples tested",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": "",
    "population": 97338583,
    "population_density": 308.127,
    "median_age": 32.6,
    "aged_65_older": 7.15,
    "aged_70_older": 4.718,
    "gdp_per_capita": 6171.884,
    "extreme_poverty": 2,
    "cardiovasc_death_rate": 245.465,
    "diabetes_prevalence": 6,
    "female_smokers": 1,
    "male_smokers": 45.9,
    "handwashing_facilities": 85.847,
    "hospital_beds_per_thousand": 2.6,
    "life_expectancy": 75.4,
    "human_development_index": 0.694
  },
  {
    "iso_code": "OWID_WRL",
    "continent": "",
    "location": "World",
    "total_cases": 83424446,
    "new_cases": 716166,
    "new_cases_smoothed": 577201.143,
    "total_deaths": 1818116,
    "new_deaths": 13108,
    "new_deaths_smoothed": 10830.714,
    "total_cases_per_million": 10702.579,
    "new_cases_per_million": 91.877,
    "new_cases_smoothed_per_million": 74.05,
    "total_deaths_per_million": 233.247,
    "new_deaths_per_million": 1.682,
    "new_deaths_smoothed_per_million": 1.389,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": 9896753,
    "total_vaccinations_per_hundred": 0.13,
    "stringency_index": "",
    "population": 7794798729,
    "population_density": 58.045,
    "median_age": 30.9,
    "aged_65_older": 8.696,
    "aged_70_older": 5.355,
    "gdp_per_capita": 15469.207,
    "extreme_poverty": 10,
    "cardiovasc_death_rate": 233.07,
    "diabetes_prevalence": 8.51,
    "female_smokers": 6.434,
    "male_smokers": 34.635,
    "handwashing_facilities": 60.13,
    "hospital_beds_per_thousand": 2.705,
    "life_expectancy": 72.58,
    "human_development_index": ""
  },
  {
    "iso_code": "YEM",
    "continent": "Asia",
    "location": "Yemen",
    "total_cases": 2099,
    "new_cases": 2,
    "new_cases_smoothed": 1,
    "total_deaths": 610,
    "new_deaths": 0,
    "new_deaths_smoothed": 0.571,
    "total_cases_per_million": 70.375,
    "new_cases_per_million": 0.067,
    "new_cases_smoothed_per_million": 0.034,
    "total_deaths_per_million": 20.452,
    "new_deaths_per_million": 0,
    "new_deaths_smoothed_per_million": 0.019,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": "",
    "total_tests": "",
    "total_tests_per_thousand": "",
    "new_tests_per_thousand": "",
    "new_tests_smoothed": "",
    "new_tests_smoothed_per_thousand": "",
    "positive_rate": "",
    "tests_per_case": "",
    "tests_units": "",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 16.67,
    "population": 29825968,
    "population_density": 53.508,
    "median_age": 20.3,
    "aged_65_older": 2.922,
    "aged_70_older": 1.583,
    "gdp_per_capita": 1479.147,
    "extreme_poverty": 18.8,
    "cardiovasc_death_rate": 495.003,
    "diabetes_prevalence": 5.35,
    "female_smokers": 7.6,
    "male_smokers": 29.2,
    "handwashing_facilities": 49.542,
    "hospital_beds_per_thousand": 0.7,
    "life_expectancy": 66.12,
    "human_development_index": 0.452
  },
  {
    "iso_code": "ZMB",
    "continent": "Africa",
    "location": "Zambia",
    "total_cases": 20725,
    "new_cases": 263,
    "new_cases_smoothed": 213,
    "total_deaths": 388,
    "new_deaths": 2,
    "new_deaths_smoothed": 1,
    "total_cases_per_million": 1127.342,
    "new_cases_per_million": 14.306,
    "new_cases_smoothed_per_million": 11.586,
    "total_deaths_per_million": 21.105,
    "new_deaths_per_million": 0.109,
    "new_deaths_smoothed_per_million": 0.054,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 14995,
    "total_tests": 591150,
    "total_tests_per_thousand": 32.156,
    "new_tests_per_thousand": 0.816,
    "new_tests_smoothed": 6942,
    "new_tests_smoothed_per_thousand": 0.378,
    "positive_rate": 0.028,
    "tests_per_case": 36.3,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 49.07,
    "population": 18383956,
    "population_density": 22.995,
    "median_age": 17.7,
    "aged_65_older": 2.48,
    "aged_70_older": 1.542,
    "gdp_per_capita": 3689.251,
    "extreme_poverty": 57.5,
    "cardiovasc_death_rate": 234.499,
    "diabetes_prevalence": 3.94,
    "female_smokers": 3.1,
    "male_smokers": 24.7,
    "handwashing_facilities": 13.938,
    "hospital_beds_per_thousand": 2,
    "life_expectancy": 63.89,
    "human_development_index": 0.588
  },
  {
    "iso_code": "ZWE",
    "continent": "Africa",
    "location": "Zimbabwe",
    "total_cases": 13867,
    "new_cases": 242,
    "new_cases_smoothed": 154.429,
    "total_deaths": 363,
    "new_deaths": 3,
    "new_deaths_smoothed": 3.429,
    "total_cases_per_million": 932.993,
    "new_cases_per_million": 16.282,
    "new_cases_smoothed_per_million": 10.39,
    "total_deaths_per_million": 24.423,
    "new_deaths_per_million": 0.202,
    "new_deaths_smoothed_per_million": 0.231,
    "reproduction_rate": "",
    "icu_patients": "",
    "icu_patients_per_million": "",
    "hosp_patients": "",
    "hosp_patients_per_million": "",
    "weekly_icu_admissions": "",
    "weekly_icu_admissions_per_million": "",
    "weekly_hosp_admissions": "",
    "weekly_hosp_admissions_per_million": "",
    "new_tests": 2462,
    "total_tests": 214577,
    "total_tests_per_thousand": 14.437,
    "new_tests_per_thousand": 0.166,
    "new_tests_smoothed": 1651,
    "new_tests_smoothed_per_thousand": 0.111,
    "positive_rate": 0.084,
    "tests_per_case": 11.9,
    "tests_units": "tests performed",
    "total_vaccinations": "",
    "total_vaccinations_per_hundred": "",
    "stringency_index": 69.44,
    "population": 14862927,
    "population_density": 42.729,
    "median_age": 19.6,
    "aged_65_older": 2.822,
    "aged_70_older": 1.882,
    "gdp_per_capita": 1899.775,
    "extreme_poverty": 21.4,
    "cardiovasc_death_rate": 307.846,
    "diabetes_prevalence": 1.82,
    "female_smokers": 1.6,
    "male_smokers": 30.7,
    "handwashing_facilities": 36.791,
    "hospital_beds_per_thousand": 1.7,
    "life_expectancy": 61.49,
    "human_development_index": 0.535
  }
]